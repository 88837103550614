import React from 'react'
import Img from './img'
import ImgText from './imgText'
import ButtonLink from './ButtonLink'
import Text from './text'
import ModalButton from './ModalButton'
import Pannel from '../../WVS/pannel';

export default function PageBuilder({item, explain, lang}) {
  switch(item.type) {
    case 'text-html':
      return <Text props={item} lang={lang}/>
    case 'img':
      return <Img props={item}/>
    case 'img-text':
      return <ImgText props={item} />
    case 'button': 
      return <ButtonLink props={item}></ButtonLink>
    case 'button-modal': 
      return <ModalButton props={item}/>
    case 'pannel':
      return <Pannel lang={lang} />
    default:
      return <div>not</div>
  }
}