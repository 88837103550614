const en = {
    'lang': 'en',
    'buttons': {
        'next': 'next',
        'back': 'back',
        'send': 'send'
    },
    AlertSnack: 'If you rotate your phone horizontally, it will be more convenient to proceed. There are several answer options on the right. Some browsers may not support phone rotation.',
    error: {
        title: 'Something went wrong:',
        text: `We'll fix this soon, if it's a server error <br/>
        Or maybe you lost internet connection? 
        And there might be an update coming up`
    },
    "seo": {
        "title": "Find out which country's resident you resemble.",
        "description": "We trained a neural network on data from a sociological study that examines life values. It was conducted with 140,000 people from 90 countries."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "Find out which country's resident you resemble."
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>We trained a neural network on data from the international sociological <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>study</a> that examines life values. It was conducted with 140,000 people from 90 countries. We took some questions from there and created this application. After completing the test, you will see a list of countries where people answered similarly to you and an overview of our society. </div>"
        }
        ],
    'data': [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "Below is a list of qualities that parents can instill in their children. Which, if any, do you consider particularly important? Please select up to five options.",
            "question": [
                {"text": "Good manners", "id": "A027"},
                {"text": "Independence", "id": "A029"},
                {"text": "Diligence", "id": "A030"},
                {"text": "Sense of responsibility", "id": "A032"},
                {"text": "Imagination", "id": "A034"},
                {"text": "Tolerance and respect for others", "id": "A035"},
                {"text": "Thrift, saving money and things", "id": "A038"},
                {"text": "Determination, perseverance", "id": "A039"},
                {"text": "Religious faith", "id": "A040"},
                {"text": "Not being selfish (selflessness)", "id": "A041"},
                {"text": "Obedience", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "Important"}]
        },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "Below are different groups of people. <br/>Choose those you would not like to have as neighbors.",
            "question": [
                {"text": "People of a different race", "id": "A124_02"},
                {"text": "Heavy drinkers", "id": "A124_03"},
                {"text": "Immigrants/foreign workers", "id": "A124_06"},
                {"text": "Drug addicts", "id": "A124_08"},
                {"text": "Homosexuals", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "Select"}]
        },
        {
              "q": "3",
              "type": "multi",
              "globalQuestion": "Do you agree with the following statements?",
              "question": [
                {
                  "text": "Homosexual couples can be just as good parents as any other couples",
                  "id": "D081"
                },
                {
                  "text": "Having children is a responsibility to society",
                  "id": "D026_03"
                },
                {
                  "text": "It is a child's duty to take care of sick parents",
                  "id": "D026_05"
                },
                {
                  "text": "People who do not work become lazy",
                  "id": "C038"
                },
                {
                  "text": "Work is a duty to society",
                  "id": "C039"
                },
                {
                  "text": "Work should come first, even if it means less free time",
                  "id": "C041"
                }
              ],
              "answers": [
                { "id": 1, "text": "Strongly agree" },
                { "id": 2, "text": "Agree" },
                { "id": 3, "text": "Neutral" },
                { "id": 4, "text": "Disagree" },
                { "id": 5, "text": "Strongly disagree" }
              ]
        },
            {
              "q": "4",
              "type": "multi",
              "globalQuestion": "How important are the following values to you?",
              "question": [
                { "text": "Family", "id": "A001" },
                { "text": "Friends", "id": "A002" },
                { "text": "Leisure time", "id": "A003" },
                { "text": "Politics", "id": "A004" },
                { "text": "Work", "id": "A005" },
                { "text": "Religion", "id": "A006" }
              ],
              "answers": [
                { "id": 1, "text": "Very important" },
                { "id": 2, "text": "Quite important" },
                { "id": 3, "text": "Not very important" },
                { "id": 4, "text": "Not important at all" }
              ]
            },           
            {
                "q": "5",
                "type": "multi",
                "globalQuestion": "How would you respond to the following statements? <br/>Do you agree or disagree with them?",
                "question": [
                  {
                    "text": "Men should have more rights at work than women",
                    "id": "C001"
                  },
                  {
                    "text": "Employers should prefer citizens of the country over immigrants",
                    "id": "C002"
                  }
                ],
                "answers": [
                  {"id": 1, "text": "Agree"},
                  {"id": 2, "text": "Disagree"},
                  {"id": 3, "text": "None of the above"}
                ]
              },
              {
                "q": "6",
                "type": "multi",
                "globalQuestion": "Do you agree with the following statements?",
                "question": [
                  {
                    "text": "One of the main goals in life is to make parents proud of me",
                    "id": "D054"
                  },
                  {
                    "text": "Men make better political leaders than women",
                    "id": "D059"
                  },
                  {
                    "text": "University is more important for boys than for girls",
                    "id": "D060"
                  },
                  {
                    "text": "Preschool children suffer when their mothers work",
                    "id": "D061"
                  },
                  {
                    "text": "Men are better at managing businesses than women",
                    "id": "D078"
                  }
                ],
                "answers": [
                  {"id": 1, "text": "Strongly agree"},
                  {"id": 2, "text": "Agree"},
                  {"id": 3, "text": "Disagree"},
                  {"id": 4, "text": "Strongly disagree"}
                ]
              },
              {
                "q": "7",
                "type": "multi",
                "globalQuestion": "Select which actions, in your opinion, can always be justified, can never be justified, or something in between using this scale. <br/> 1 – Never justified, 10 – Always justified",
                "question": [
                  {
                    "text": "Demanding state benefits you are not entitled to",
                    "id": "F114A"
                  },
                  {
                    "text": "Avoiding payment for public transportation",
                    "id": "F115"
                  },
                  {
                    "text": "Tax fraud if you have a chance",
                    "id": "F116"
                  },
                  {
                    "text": "Accepting bribes while performing official duties",
                    "id": "F117"
                  },
                  {
                    "text": "Homosexuality",
                    "id": "F118"
                  },
                  {
                    "text": "Prostitution",
                    "id": "F119"
                  },
                  {
                    "text": "Abortion",
                    "id": "F120"
                  },
                  {
                    "text": "Divorce",
                    "id": "F121"
                  },
                  {
                    "text": "Euthanasia",
                    "id": "F122"
                  },
                  {
                    "text": "Suicide",
                    "id": "F123"
                  },
                  {
                    "text": "Casual sex",
                    "id": "F132"
                  },
                  {
                    "text": "Political violence",
                    "id": "E290"
                  },
                  {
                    "text": "Death penalty",
                    "id": "F144_02"
                  }
                ],
                "answers": [
                  {"id": 1, "text": "1"},
                  {"id": 2, "text": "2"},
                  {"id": 3, "text": "3"},
                  {"id": 4, "text": "4"},
                  {"id": 5, "text": "5"},
                  {"id": 6, "text": "6"},
                  {"id": 7, "text": "7"},
                  {"id": 8, "text": "8"},
                  {"id": 9, "text": "9"},
                  {"id": 10, "text": "10"}
                ]
              },
              {
                "q": "8",
                "type": "multi",
                "globalQuestion": "How important is God in your life?",
                "question": [
                  {
                    "text": "Please use this scale to indicate: 10 means very important, and 1 means not important at all.",
                    "id": "F063"
                  }
                ],
                "answers": [
                  {"id": 1, "text": "1"},
                  {"id": 2, "text": "2"},
                  {"id": 3, "text": "3"},
                  {"id": 4, "text": "4"},
                  {"id": 5, "text": "5"},
                  {"id": 6, "text": "6"},
                  {"id": 7, "text": "7"},
                  {"id": 8, "text": "8"},
                  {"id": 9, "text": "9"},
                  {"id": 10, "text": "10"}
                ]
              },
              {
                "q": "9",
                "type": "multi",
                "globalQuestion": "Below are different groups of people. How much do you trust each group?",
                "question": [
                  {"text": "Your family", "id": "D001_B"},
                  {"text": "Your neighborhood", "id": "G007_18_B"},
                  {"text": "People you know personally", "id": "G007_33_B"},
                  {"text": "People you meet for the first time", "id": "G007_34_B"},
                  {"text": "People of other religions", "id": "G007_35_B"},
                  {"text": "People of other nationalities", "id": "G007_36_B"}
                ],
                "answers": [
                  {"id": 1, "text": "Completely trust"},
                  {"id": 2, "text": "Trust a little"},
                  {"id": 3, "text": "Do not trust much"},
                  {"id": 4, "text": "Do not trust at all"}
                ]
              },
              
              {
                "q": "10",
                "type": "multi",
                "globalQuestion": "Below are various types of political systems. What do you think about each of them as a way of governance?",
                "question": [
                   {
                      "text": "Presence of a strong leader who does not have to deal with parliament and elections",
                      "id": "E114"
                   },
                   {
                      "text": "Presence of experts, not the government, making decisions according to what they consider best for the country",
                      "id": "E115"
                   },
                   {
                      "text": "Presence of a democratic political system",
                      "id": "E117"
                   }
                ],
                "answers": [
                   {"id": 1, "text": "Very good"},
                   {"id": 2, "text": "Quite good"},
                   {"id": 3, "text": "Quite bad"},
                   {"id": 4, "text": "Very bad"}
                ]
             },
             {
                "q": "11",
                "type": "multi",
                "globalQuestion": "How important is it for you to live in a country with democratic governance?",
                "question": [
                   {
                      "text": "On this scale, where 1 means 'not important at all' and 10 means 'absolutely important', what position would you choose?",
                      "id": "E235"
                   }
                ],
                "answers": [
                   {"id": 1, "text": "1"},
                   {"id": 2, "text": "2"},
                   {"id": 3, "text": "3"},
                   {"id": 4, "text": "4"},
                   {"id": 5, "text": "5"},
                   {"id": 6, "text": "6"},
                   {"id": 7, "text": "7"},
                   {"id": 8, "text": "8"},
                   {"id": 9, "text": "9"},
                   {"id": 10, "text": "10"}
                ]
             },
    {
        q: '12',
        type: 'multi',
        globalQuestion: 'People have different views about themselves and how they relate to the world. Using this card, would you tell me how close do you feel to…?',
        question: [
            {
                text: 'World',
                id: 'G063'
            }
        ],
        answers: [
            {
                id: 1,
                text: 'Very close'
            },
            {
                id: 2,
                text: 'Close'
            },
            {
                id: 3,
                text: 'Not very close'
            },
            {
                id: 4,
                text: 'Not close at all'
            }
        ]

    },
    {
        "q": "12",
        "type": "multi",
        "globalQuestion": "Below are several actions taken by the government. In your opinion, does the government have the right to do this?",
        "question": [
          {
            "text": "Keeping people under video surveillance in public places",
            "id": "H009"
          },
          {
            "text": "Monitoring all electronic mails and any other information exchanged on the Internet",
            "id": "H010"
          },
          {
            "text": "Collecting information about people living in the country without their knowledge",
            "id": "H011"
          }
        ],
        "answers": [
          { "id": 1, "text": "Definitely should have the right" },
          { "id": 2, "text": "Probably should have the right" },
          { "id": 3, "text": "Probably should not have the right" },
          { "id": 4, "text": "Definitely should not have the right" }
        ]
      },
      {
        "q": "14",
        "type": "multi",
        "globalQuestion": "Below are some goals that people choose for their country in the next ten years. In your view, which goal is the most important?",
        "question": [
          {
            "text": "Which goal is the most important, in your view?",
            "id": "E001"
          }
        ],
        "answers": [
          { "id": 1, "text": "High level of economic growth" },
          { "id": 2, "text": "Strong military" },
          { "id": 3, "text": "Good working and community conditions" },
          { "id": 4, "text": "Making our cities and villages more beautiful" }
        ]
      },
      {
        "q": "15",
        "type": "multi",
        "globalQuestion": "Below are examples of volunteer organizations. Select organizations you belong to.",
        "question": [
          { "text": "Religious organizations", "id": "A065" },
          {
            "text": "Organizations dedicated to education, arts, music, or cultural activities",
            "id": "A066"
          },
          { "text": "Labor unions", "id": "A067" },
          {
            "text": "Organizations dedicated to the preservation of the environment, ecology, animal rights",
            "id": "A071"
          }
        ],
        "answers": [
          { "id": 0, "text": "Do not belong" },
          { "id": 1, "text": "Belong" }
        ]
      },
      {
        "q": "16",
        "type": "multi",
        "globalQuestion": "Trust in people",
        "question": [{"text": "Which statement do you agree with?", "id": "A165"}],
        "answers": [
          {"id": 1, "text": "Most people can be trusted"},
          {"id": 2, "text": "One should be cautious"},
          {"id": -1, "text": "I don't know"}
        ]
      },
      {
        "q": "17",
        "type": "multi",
        "globalQuestion": "Here are two statements that people sometimes make about the environment and economic growth.",
        "question": [{"text": "Which one do you think is more important?", "id": "B008"}],
        "answers": [
          {"id": 1, "text": "Protecting the environment"},
          {"id": 2, "text": "Economic growth and job creation"},
          {"id": -1, "text": "I don't know"}
        ]
      },
      {
        "q": "18",
        "type": "multi",
        "globalQuestion": "Competition - good or bad?",
        "question": [{"text": "Rate this statement, where 1 - Good, 10 - Bad", "id": "E039"}],
        "answers": [
          {"id": 1, "text": "Good"},
          {"id": 2, "text": "2"},
          {"id": 3, "text": "3"},
          {"id": 4, "text": "4"},
          {"id": 5, "text": "5"},
          {"id": 6, "text": "6"},
          {"id": 7, "text": "7"},
          {"id": 8, "text": "8"},
          {"id": 9, "text": "9"},
          {"id": 10, "text": "Bad"}
        ]
      },
      {
        "q": "19",
        "type": "multi",
        "globalQuestion": "Age",
        "question": [{"text": "Your age?", "id": "X003R"}],
        "answers": [
          {"id": 1, "text": "15–24"},
          {"id": 2, "text": "25–34"},
          {"id": 3, "text": "35–44"},
          {"id": 4, "text": "45–54"},
          {"id": 5, "text": "55–64"},
          {"id": 6, "text": "65 and older"}
        ]
      }
    ]
}

export default en;