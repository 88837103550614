import React from "react";
import { styled, Typography } from '@mui/material';
import theme from "../../../theme";

const WrapperRoot = styled('div')({
    display: 'flex',
    flexWrap: "wrap",
})


const Img = styled('img')({
    width: 'auto',
    height: 'auto',
    maxHeight: 600,
    maxWidth: 700,
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
        width: '100%',
      },
})



export default function ADS({props}) {
    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        
      }, 1500);
      return () => clearInterval(interval);
    }, []);

    const TypeMonitor = document.body.clientWidth < 600 ? 'mob' : 'desk'
                    
  
    try {
            if (seconds >= props.image[TypeMonitor].length) {
                setSeconds(0)
            }
        
            return (
                <WrapperRoot>
                    <Typography sx={{marginBottom: 1}}>
                    У нашей компании есть множество других интересных проектов. Посмотрите! 
                    </Typography>
                    <a href={props.link} target="_blank">
                        <Img 
                        src={props.image[TypeMonitor][seconds]} />
                    </a>
                </WrapperRoot>
            )
    } catch (e) {
        return <div>Ads missing...</div>
    }

}