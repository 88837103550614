const hi = {
    header: {
      transale: 'हमने मशीन अनुवाद का उपयोग किया है। <br/> कुछ भागों का सही अनुवाद नहीं हो सकता है।'
    },
    footer: {
      projects: {
        title: '# हमारे परियोजनाएँ',
        data: [
        //   {
        //     text: 'अनुपालन विश्लेषण',
        //     url: 'https://awake-tools.com/hi/ca?utm_source=culture-compass'
        //   },
          {
            text: 'PWA एप्लिकेशन',
            url: 'https://evilunion.com/en/pwa?utm_source=culture-compass'
          },
        ]
      },
      us: {
        title: '# हमारे बारे में',
        data: [
          {
            text: 'evilUnion',
            url: 'https://evilunion.com/en?utm_source=culture-compass'
          },
          {
            text: 'प्रतिक्रिया',
            url: 'https://t.me/evilunion_chat'
          },
          // {
          // text: 'VC Blog',
          // url: 'https://vc.ru/u/939695-evilunion?utm_source=culture-compass'
          // },
          {
            text: 'उपयोग की शर्तें',
            url: 'https://culturacompass.com/en/terms'
          },
          {
            text: 'गोपनीयता नीति',
            url: 'https://culturacompass.com/en/cookie'
          },
        ]
      }
    }
  };
  
  export default hi;
  