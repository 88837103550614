const ja = {
    seo: {
        title: 'どのように機能しますか？',
        description: '私たちが行った主な単純化は、勾配ブースティングをニューラルネットワークと呼ぶことです。社会にはニューラルネットワークに対する明確な親しみがあるため、ブースティングとは何かを説明するのは無駄だと判断しました。',
        url: '/catboost',
        lang: 'ja'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>どのように機能しますか？</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>私たちが行った主な単純化は、勾配ブースティングをニューラルネットワークと呼ぶことです。社会にはニューラルネットワークに対する明確な親しみがあるため、ブースティングとは何かを説明するのは無駄だと判断しました。</i>
            <br/> <br/>
                したがって、私たちはヤンデックスの愛されるCatboostを使用しました。なぜなら、それは高速に動作し学習が容易だからです。
            <br/> <br/>
            
            <h2>はじめに</h2>
            勾配ブースティングは、多くの異なる特徴に基づいて正確な予測を行うことができる予測モデルを構築する機械学習の方法です。
            <br/> <br/>
            これは次のように機能します。まず、いくつかの予測を行うことができる単純なモデルを構築します。次に、このモデルがどこで誤りを comitte しているかを確認し、最初のモデルの誤りを修正する別のモデルを追加します。その後、新しいモデルの誤りを見て、これらの誤りを修正する別のモデルを構築します。
            <br/> <br/>
            私たちはモデルが十分に正確になるまでこのプロセスを続けます。勾配ブースティングの主なアイデアは、追加する各新しいモデルが前のモデルの誤りを考慮に入れ、モデル全体の正確性を向上させることです。私たちのモデルでは、このような段階を2000回経験しました。
            <br/> <br/>
            すべてのモデルが構築されたら、それらを結合して、提供するデータに基づいて正確な予測ができる最終モデルを取得します。
            <h2>タイタニックの例</h2>
            タイタニック号で人が生き残るかどうかを示す素敵なイラストがあります。
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "タイタニックの例"
        },
        {
            "type": "text-html",
            "html": `ここでは、3つのツリーが異なる順序で乗客のデータを取得し、その後アンサンブルがその人が生き残るかどうかに投票します。 <br/><br/>
            
            性別 - Gender <br/>
            pclass - チケットのクラス。3つのレベルがありました。 <br/>
            年齢 - Age <br/>
            sibsp - 船上に配偶者がいたかどうか <br/>
            parch - 船上の子供の数 <br/><br/>
            
            モデルのトレーニング後、結果に影響を与える変数を尋ねることができます。
            
            <h2>これを使って何ができるの？</h2>
            
            機械学習を使用すると、さまざまなタスクを解決することができます。私たちの会社では、売上や変換を予測するサービスを提供しています。ウェブサイトでの深いイベントトラッキングを行うことができます。すべての訪問者のアクションを記録し、これらのデータを CatBoost 勾配ブースティングに送信し、ウェブサイトでの変換を予測し、その変換に影響を与えたイベントを示します。これにより、ページや提供を最適化できます。B2Bセールスのシナリオでは、購入の確率を広告システム内の変換として扱うことができます。`
        },

    ]
}

export default ja;
