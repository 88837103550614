const pt = {
    seo: {
        title: 'Como isso funciona?',
        description: 'A principal simplificação que fizemos é chamar o Gradient Boosting de rede neural. Uma vez que há uma clara familiaridade com redes neurais na sociedade, decidimos não ser detalhistas ao explicar o que é o boosting, pois ninguém se importa.',
        url: '/catboost',
        lang: 'pt'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Como isso funciona?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>A principal simplificação que fizemos é chamar o Gradient Boosting de rede neural. Uma vez que há uma clara familiaridade com redes neurais na sociedade, decidimos não ser detalhistas ao explicar o que é o boosting, pois ninguém se importa.</i>
            <br/> <br/>
                Assim, usamos o querido Catboost da Yandex. Porque ele é rápido e fácil de aprender.
            <br/> <br/>
            
            <h2>Começando</h2>
            O Gradient Boosting é um método de aprendizado de máquina que permite construir um modelo preditivo capaz de fazer previsões precisas com base em várias características diferentes.
            <br/> <br/>
            Aqui está como funciona: primeiro, construímos um modelo simples que pode fazer algumas previsões. Em seguida, observamos onde esse modelo comete erros e adicionamos outro modelo que corrige os erros do primeiro modelo. Depois disso, observamos os erros do novo modelo e construímos mais um modelo que corrige esses erros.
            <br/> <br/>
            Continuamos esse processo até que nosso modelo seja suficientemente preciso. A ideia principal do Gradient Boosting é que cada novo modelo que adicionamos leva em consideração os erros dos modelos anteriores e melhora a precisão geral do modelo. Em nosso modelo, fizemos 2000 dessas etapas.
            <br/> <br/>
            Quando todos os modelos são construídos, os combinamos para obter o modelo final, que pode fazer previsões precisas com base nos dados que fornecemos a ele.
            <h2>Exemplo do Titanic</h2>
            Há uma bela ilustração que mostra se uma pessoa sobreviverá ao Titanic.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "exemplo do Titanic"
        },
        {
            "type": "text-html",
            "html": `Aqui, três árvores captam dados do passageiro em diferentes sequências e, em seguida, o conjunto vota para decidir se a pessoa sobreviverá ou não. <br/><br/>
            
            Sexo - Gênero <br/>
            pclass - Classe do bilhete. Havia 3 níveis. <br/>
            Idade - Age <br/>
            sibsp - Havia cônjuges a bordo <br/>
            parch - Número de filhos a bordo <br/><br/>
            
            Após o treinamento do modelo, você pode perguntar quais variáveis influenciam o resultado.
            
            <h2>O que você pode fazer com isso?</h2>
            
            A aprendizagem de máquina permite resolver muitas tarefas diferentes. Em nossa empresa, oferecemos um serviço de previsão de vendas e conversões. Podemos realizar um rastreamento profundo de eventos em seu site. Registraremos todas as ações dos visitantes e enviaremos esses dados para o Gradient Boosting CatBoost, que preverá as conversões em seu site e indicará quais eventos influenciaram essa conversão. Dessa forma, você pode otimizar as páginas e sua oferta. Em cenários de vendas B2B, você pode tratar a probabilidade de compra como uma conversão em sistemas de publicidade.`
        },

    ]
}

export default pt;
