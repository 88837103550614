const fr = {
    seo: {
        title: 'Comment ça fonctionne ?',
        description: 'La principale simplification que nous avons apportée est d\'appeler le renforcement de gradient un réseau neuronal. Étant donné qu\'il y a une familiarité claire avec les réseaux neuronaux dans la société, nous avons décidé de ne pas être pointilleux en expliquant ce qu\'est le renforcement, car personne ne s\'en soucie.',
        url: '/catboost',
        lang: 'fr'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Comment ça fonctionne ?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>La principale simplification que nous avons apportée est d\'appeler le renforcement de gradient un réseau neuronal. Étant donné qu\'il y a une familiarité claire avec les réseaux neuronaux dans la société, nous avons décidé de ne pas être pointilleux en expliquant ce qu\'est le renforcement, car personne ne s\'en soucie.</i>
            <br/> <br/>
                Ainsi, nous avons utilisé le bien-aimé Catboost de Yandex. Parce qu\'il fonctionne rapidement et est facile à apprendre.
            <br/> <br/>
            
            <h2>Commencer</h2>
            Le renforcement de gradient est une méthode d\'apprentissage automatique qui vous permet de construire un modèle prédictif capable de faire des prévisions précises en fonction d\'un grand nombre de caractéristiques différentes.
            <br/> <br/>
            Voici comment cela fonctionne : d\'abord, nous construisons un modèle simple capable de faire certaines prédictions. Ensuite, nous examinons les endroits où ce modèle commet des erreurs et ajoutons un autre modèle qui corrige les erreurs du premier modèle. Après cela, nous examinons les erreurs du nouveau modèle et construisons encore un autre modèle qui corrige ces erreurs.
            <br/> <br/>
            Nous continuons ce processus jusqu\'à ce que notre modèle devienne suffisamment précis. L\'idée principale du renforcement de gradient est que chaque nouveau modèle que nous ajoutons tient compte des erreurs des modèles précédents et améliore la précision globale du modèle. Dans notre modèle, nous avons effectué 2000 de ces étapes.
            <br/> <br/>
            Une fois que tous les modèles sont construits, nous les combinons pour obtenir le modèle final, qui peut faire des prédictions précises en fonction des données que nous lui fournissons.
            <h2>Exemple du Titanic</h2>
            Il y a une belle illustration qui montre si une personne survivra au Titanic.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "exemple du Titanic"
        },
        {
            "type": "text-html",
            "html": `Ici, trois arbres prennent des données sur le passager dans des séquences différentes, puis l\'ensemble vote pour savoir si la personne survivra ou non. <br/><br/>
            
            Sexe - Genre <br/>
            pclass - Classe du billet. Il y avait 3 niveaux. <br/>
            Âge - Age <br/>
            sibsp - Y avait-il des conjoints à bord <br/>
            parch - Nombre d\'enfants à bord <br/><br/>
            
            Après l\'entraînement du modèle, vous pouvez demander quelles variables influencent le résultat.
            
            <h2>Que pouvez-vous faire avec cela ?</h2>
            
            L\'apprentissage automatique vous permet de résoudre de nombreuses tâches différentes. Dans notre entreprise, nous proposons un service de prédiction des ventes et des conversions. Nous pouvons effectuer un suivi approfondi des événements sur votre site web. Nous enregistrerons toutes les actions des visiteurs et enverrons ces données au renforcement de gradient CatBoost, qui prédira les conversions sur votre site web et indiquera quels événements ont influencé cette conversion. De cette manière, vous pouvez optimiser les pages et votre offre. Dans les scénarios de ventes B2B, vous pouvez traiter la probabilité d\'achat comme une conversion dans les systèmes publicitaires.`
        },

    ]
}

export default fr;
