const ru = {
    seo: {
        title: 'Как же он работает?', 
        description: 'Главное упрощение которые мы сделали - это называние градиентного бустинга нейронной сеткой. Так как в обществе однозначно сложилось ознакомленность в сторону нейронных сетей, решили не быть занудами с пояснением что такое бустинг так как всем пофиг. ', 
        url: '/catboost',
        lang: 'ru'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Как же он работает?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i> Главное упрощение которые мы сделали — это называние градиентного бустинга нейронной сеткой. Так как в обществе однозначно сложилось ознакомленность в сторону термина нейронных сетей, решили не быть занудами с пояснением что такое бустинг так как всем пофиг. 
            </i>  
            <br/> <br/>
                Значит мы использовали любимый всеми Сatboost от Яндекса. Потому что он быстро работает и прост в обучении. 
            <br/> <br/>
            
            <h2>Начало</h2>
            Градиентный бустинг - это метод машинного обучения, который позволяет построить предсказательную модель, которая способна делать точные прогнозы на основе большого количества различных признаков.
            <br/> <br/>
            Он работает так: сначала мы строим простую модель, которая может делать некоторые предсказания. Затем мы смотрим, где эта модель ошибается, и добавляем еще одну модель, которая исправляет ошибки первой модели. После этого мы смотрим на ошибки новой модели и снова строим еще одну модель, исправляющую эти ошибки.
            <br/> <br/>
            Мы продолжаем этот процесс до тех пор, пока наша модель не будет достаточно точной. Главной идеей градиентного бустинга является то, что каждая новая модель, которую мы добавляем, учитывает ошибки предыдущих моделей, и улучшает общую точность модели. В нашей модели мы сделали 2000 таких этапов.
            <br/> <br/>
            Когда все модели построены, мы объединяем их, чтобы получить финальную модель, которая сможет делать точные прогнозы на основе данных, которые мы даем ей на вход.
            <h2>Пример титаник</h2>
            Есть хорошая картинка, которая показывает выживет ли человек на титанике. 
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "titanic example"
        },
        {
            "type": "text-html",
            "html": `            Тут три дерева принимают в разной последовательности данные о пассажире и потом общим ансамблем голасуют выживет ли человек или нет. <br/><br/>
            
            Sex - Пол <br/>
            pclass - Класс билета. Их было 3 уровня. <br/>
            Age - Возраст <br/>
            sibsp - Были ли супруги на борту <br/>
            parch - Кол-во детей на борту <br/><br/>
            
            После оубчение модели можно спросить, какие переменные влияют на результат. 
            
            <h2>Что можно делать с этим?</h2>
            
            Машинное обучение позволяет решать множество разных задач. В нашей компании мы предалагаем услугу предсказание продаж и конверсий. Мы можем провести глубокую разметку событий на вашем сайте. Мы будем записывать все действия посетителя и отправим эти данные градиентному бустингу catBoost, который будет предсказывать конверсии на вашем сайте и скажет, какие события повлияли на эту конверсию. Таким образом можно опитимизировать страницы и свое предложение. В случиях B2B продаж - можно отправлять вероятность покупки как конверсию в рекламные системы. `
        },

    ]
}

export default ru;


