import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import LayOut from "../../layout";

const WraperText = styled('div')({
    color: '#c2c2d1',
    marginBottom: 40,
})


export default function Heatmap() {
    return(
        <LayOut>
        <WraperText>
            <h1>Terms of Use</h1>

            <p>Last updated: 7.08.22</p>

            <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the https://culturacompass.com/ website (the "Service") operated by culturacompass ("us", "we", or "our").</p>

            <h2>1. Agreement to Terms</h2>
            <p>By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>

            <h2>2. Use License</h2>
            <p>Permission is granted to temporarily use the Service for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:</p>
            <ul>
                <li>modify or copy the materials;</li>
                <li>use the materials for any commercial purpose or for any public display;</li>
                <li>attempt to decompile or reverse engineer any software contained on the Service;</li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
            </ul>

            <h2>3. Disclaimer</h2>
            <p>The materials on the Service are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

            <h2>4. Limitations</h2>
            <p>In no event shall we be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Service, even if we or an authorized representative have been notified orally or in writing of the possibility of such damage.</p>

            <h2>5. Advertising</h2>
            <p>The Service may display advertisements, which may be targeted based on the information you provide in the survey. We are not responsible for the content of any advertisements or any products, services, or other materials relating to such advertisements.</p>

            <h2>7. Changes</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 60 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us:</p>
            <p>By telegram: https://t.me/evilunion_chat</p>
        </WraperText>
        </LayOut>
    )
}
