const ru = {
    'lang': 'ru',
    'buttons': {
        'next': 'Дальше',
        'back': 'Назад',
        'send': 'Получить ответ'
    },
    AlertSnack: 'Если вы перевернете телефон горизонтально, то будет удобнее проходить. Тут несколько вариантов ответов справа. Некоторые браузеры могут не поддерживать перевороты телефона.',
    error: {
        title: 'Что то пошло не так:',
        text: `Мы скоро это исправим, если это ошибка сервера <br/>
        Или может у вас интернет пропал? 
        А еще может у нас обновление выходит`
    },
    seo: {
        title: 'Узнайте, на жителя какой страны вы похожи.',
        description: 'Мы обучили нейронную сеть на данных из социологического исследования, которое исследует жизненные ценности. Его прошло 140 000 человек из 90 стран.'
    },
    begin: [
        {
            type: 'text-html',
            html: 'Узнайте, на жителя какой страны вы похожи.',
        },
        {
            type: 'text-html',
            html: `<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'> Мы обучили нейронную сеть на данных из международного социологического <a href="https://worldvaluessurvey.org/wvs.jsp" target="_blank">исследования</a>, которое изучает жизненные ценности. Его прошло 140 000 человек из 90 стран. Мы взяли оттуда некоторые вопросы и создали это приложение. После прохождения теста вы увидите список стран, в которых люди отвечали похоже на вас, и общую аналитику нашего общества. <br/><br/>`,
            link: `<i>Это бесплатный промо-проект от компании <a href='https://evilunion.com/?utm_source=culture-compass' target="_blank" rel="noreferrer">evilUnion</a></i></div>`
        },
    ],
    'data': [
        {'q': '1',
    'type': 'one',
    'globalQuestion': 'Ниже приведен список качеств, которые родители могут воспитать у своих детей. Какие, если таковые имеются, вы считаете особенно важными? Пожалуйста, выберите не более пяти вариантов. ',
    'question': [{'text': 'Хорошие манеры', 'id': 'A027'},
     {'text': 'Независимость', 'id': 'A029'},
     {'text': 'Трудолюбие', 'id': 'A030'},
     {'text': 'Чувство ответственности', 'id': 'A032'},
     {'text': 'Воображение', 'id': 'A034'},
     {'text': 'Толерантность и уважение к другим людям', 'id': 'A035'},
     {'text': 'Бережливость, экономия денег и вещей', 'id': 'A038'},
     {'text': 'Решительность, настойчивость', 'id': 'A039'},
     {'text': 'Религиозная вера', 'id': 'A040'},
     {'text': 'Не быть эгоистом (бескорыстие)', 'id': 'A041'},
     {'text': 'Послушание', 'id': 'A042'}],
    'answers': [{'id': 1, 'text': 'Важный'}]},
   {'q': '2',
    'type': 'one',
    'globalQuestion': 'Ниже приведены разные группы людей. <br/>Выберите тех, кого вы бы не хотели иметь в качестве соседей.',
    'question': [{'text': 'Люди другой расы', 'id': 'A124_02'},
     {'text': 'Сильно пьющие', 'id': 'A124_03'},
     {'text': 'Иммигранты/иностранные рабочие', 'id': 'A124_06'},
     {'text': 'Наркоманы', 'id': 'A124_08'},
     {'text': 'Гомосексуалы', 'id': 'A124_09'}],
    'answers': [{'id': 1, 'text': 'Выбрать'}]},
   {'q': '3',
    'type': 'multi',
    'globalQuestion': 'Согласны ли вы со следующими утверждениями?',
    'question': 
        [{'text': 'Гомосексуальные пары могут быть такими же хорошими родителями, как и любые другие пары',
      'id': 'D081'},
     {'text': 'Иметь детей — долг перед обществом', 'id': 'D026_03'},
     {'text': 'Обязанность ребенка — заботиться о больном родителе',
      'id': 'D026_05'},
     {'text': 'Люди, которые не работают, становятся ленивыми', 'id': 'C038'},
     {'text': 'Работа — это долг перед обществом', 'id': 'C039'},
     {'text': 'Работа должна быть на первом месте, даже если это означает меньше свободного времени',
      'id': 'C041'}],
    'answers': [{'id': 1, 'text': 'Полностью согласен'},
     {'id': 2, 'text': 'Согласен'},
     {'id': 3, 'text': 'Где–то посередине'},
     {'id': 4, 'text': 'Не согласен'},
     {'id': 5, 'text': 'Категорически не согласен'},
    ]},
   {'q': '4',
    'type': 'multi',
    'globalQuestion': 'Насколько для вас важны приведенные ниже ценности? ',
    'question': [{'text': 'Семья', 'id': 'A001'},
     {'text': 'Друзья', 'id': 'A002'},
     {'text': 'Свободное время', 'id': 'A003'},
     {'text': 'Политика', 'id': 'A004'},
     {'text': 'Работа', 'id': 'A005'},
     {'text': 'Религия', 'id': 'A006'}],
    'answers': [{'id': 1, 'text': 'Очень важно'},
     {'id': 2, 'text': 'Довольно важно'},
     {'id': 3, 'text': 'Не очень важно'},
     {'id': 4, 'text': 'Совсем не важно'}]},
     {
        'q': 233,
        'type': 'ads',
        'link': 'https://evilunion.com/?utm_source=culture-compass',
        'image': {
          mob: ['/images/ads/task_mob.png'],
          desk: ['/images/ads/task.png']
        }
    },
   {'q': '5',
    'type': 'multi',
    'globalQuestion': 'Как бы вы отнеслись к следующим утверждениям? <br/>Вы согласны или не согласны с ними?',
    'question': [{'text': 'Мужчины должны иметь больше прав на работу, чем женщины',
      'id': 'C001'},
     {'text': 'Работодатели должны отдавать предпочтение гражданам страны, а не иммигрантам',
      'id': 'C002'}],
    'answers': [{'id': 1, 'text': 'Согласен'},
     {'id': 2, 'text': 'Не согласен'},
     {'id': 3, 'text': 'Ни один не подходит'}]},
   {'q': '6',
    'type': 'multi',
    'globalQuestion': 'Согласны ли вы со следующими утверждениями?',
    'question': [{'text': 'Одна из главных целей в жизни — сделать так, чтобы родители мной гордились',
      'id': 'D054'},
     {'text': 'Мужчины становятся лучшими политическими лидерами, чем женщины',
      'id': 'D059'},
     {'text': 'Университет важнее для мальчика, чем для девочки', 'id': 'D060'},
     {'text': 'Дошкольник страдает от работающей матери', 'id': 'D061'},
     {'text': 'Мужчины лучше управляют бизнесом, чем женщины', 'id': 'D078'}],
    'answers': [{'id': 1, 'text': 'Полностью согласен'},
     {'id': 2, 'text': 'Согласен'},
     {'id': 3, 'text': 'Не согласен'},
     {'id': 4, 'text': 'Категорически не согласен'}]},
   {'q': '7',
    'type': 'multi',
    'globalQuestion': 'Выберите, какие действия, на ваш взгляд, всегда могут быть оправданы, никогда не могут быть оправданы или что-то среднее, используя эту шкалу. <br/> 1 – Никогда не оправдывается, 10 – Всегда оправдывается',
    'question': [{'text': 'Требование государственных пособий, на которые вы не имеете права',
      'id': 'F114A'},
     {'text': 'Отказ от платы за проезд в общественном транспорте',
      'id': 'F115'},
     {'text': 'Мошенничество с налогами, если у вас есть на это шанс', 'id': 'F116'},
     {'text': 'Получение взятки при исполнении служебных обязанностей',
      'id': 'F117'},
     {'text': 'Гомосексуальность', 'id': 'F118'},
     {'text': 'Проституция', 'id': 'F119'},
     {'text': 'Аборт', 'id': 'F120'},
     {'text': 'Развод', 'id': 'F121'},
     {'text': 'Эвтаназия', 'id': 'F122'},
     {'text': 'Самоубийство', 'id': 'F123'},
     {'text': 'Случайный секс', 'id': 'F132'},
     {'text': 'Политическое насилие', 'id': 'E290'},
     {'text': 'Смертный приговор', 'id': 'F144_02'}],
    'answers': [{'id': 1, 'text': '1'},
     {'id': 2, 'text': '2'},
     {'id': 3, 'text': '3'},
     {'id': 4, 'text': '4'},
     {'id': 5, 'text': '5'},
     {'id': 6, 'text': '6'},
     {'id': 7, 'text': '7'},
     {'id': 8, 'text': '8'},
     {'id': 9, 'text': '9'},
     {'id': 10, 'text': '10'}]},
   {'q': '8',
    'type': 'multi',
    'globalQuestion': 'Насколько важен Бог в вашей жизни?',
    'question': [{'text': 'Пожалуйста, используйте эту шкалу, чтобы указать: 10 означает очень важно, а 1 означает совсем не важно.',
      'id': 'F063'}],
    'answers': [{'id': 1, 'text': '1'},
     {'id': 2, 'text': '2'},
     {'id': 3, 'text': '3'},
     {'id': 4, 'text': '4'},
     {'id': 5, 'text': '5'},
     {'id': 6, 'text': '6'},
     {'id': 7, 'text': '7'},
     {'id': 8, 'text': '8'},
     {'id': 9, 'text': '9'},
     {'id': 10, 'text': '10'}]},
   {'q': '9',
    'type': 'multi',
    'globalQuestion': 'Ниже приведены разные группы людей. Насколько вы доверяете каждой из групп?',
    'question': [{'text': 'Ваша семья', 'id': 'D001_B'},
     {'text': 'Ваш район', 'id': 'G007_18_B'},
     {'text': 'Люди, которых вы знаете лично', 'id': 'G007_33_B'},
     {'text': 'Люди, которых вы встречаете впервые', 'id': 'G007_34_B'},
     {'text': 'Люди другой религии', 'id': 'G007_35_B'},
     {'text': 'Люди другой национальности', 'id': 'G007_36_B'}],
    'answers': [{'id': 1, 'text': 'Доверяю полностью'},
     {'id': 2, 'text': 'Доверяю немного'},
     {'id': 3, 'text': 'Не очень верю'},
     {'id': 4, 'text': 'Не верю вообще'}]},
   {'q': '10',
    'type': 'multi',
    'globalQuestion': 'Ниже описаны различные типы политических систем, что вы думаете о каждой из них как о способе управления?',
    'question': [{'text': 'Наличие сильного лидера, которому не надо заморачиваться с парламентом и выборами',
      'id': 'E114'},
     {'text': 'Наличие экспертов, а не правительства, принимающих решения в соответствии с тем, что они считают лучшим для страны',
      'id': 'E115'},
     {'text': 'Наличие демократической политической системы', 'id': 'E117'}],
    'answers': [{'id': 1, 'text': 'Очень хороший'},
     {'id': 2, 'text': 'Довольно хороший'},
     {'id': 3, 'text': 'Довольно плохой'},
     {'id': 4, 'text': 'Очень плохой'}]},
   {'q': '11',
    'type': 'multi',
    'globalQuestion': 'Насколько важно для вас жить в стране с демократическим управлением?',
    'question': [{'text': 'По этой шкале, где 1 означает «совсем не важно», а 10 означает «абсолютно важно», какую позицию вы бы выбрали?',
      'id': 'E235'}],
    'answers': [{'id': 1, 'text': '1'},
     {'id': 2, 'text': '2'},
     {'id': 3, 'text': '3'},
     {'id': 4, 'text': '4'},
     {'id': 5, 'text': '5'},
     {'id': 6, 'text': '6'},
     {'id': 7, 'text': '7'},
     {'id': 8, 'text': '8'},
     {'id': 9, 'text': '9'},
     {'id': 10, 'text': '10'}]},
    // {
    //     'q': 233,
    //     'type': 'ads',
    //     'link': 'https://evilunion.com/gatsby-js?utm_source=culture-compass',
    //     'image': {
    //       mob: ['/images/ads/gatsby-mob-1.png', '/images/ads/gatsby-mob-2.png'],
    //       desk: ['/images/ads/gatsby-1.png', '/images/ads/gatsby-2.png', '/images/ads/gatsby-1.png', '/images/ads/gatsby-2.png']
    //     }
    // },
   {'q': '12',
    'type': 'multi',
    'globalQuestion': 'Ниже приведены несколько действий, которые совершает правительство. По вашему мнению, имеет ли правительство на это право?',
    'question': [
        {'text': 'Держать людей под видеонаблюдением в общественных местах',
      'id': 'H009'},
        {'text': 'Отслеживать все электронные письма и любую другую информацию, которой обмениваются в Интернете.',
      'id': 'H010'},
      {'text': 'Собирать информацию о людях, проживающих в стране, без их ведома.',
    'id': 'H011'}
        ],
    'answers': [{'id': 1, 'text': 'Обязательно должно иметь право'},
     {'id': 2, 'text': 'Наверное, должно иметь права'},
     {'id': 3, 'text': 'Наверное, не должно иметь права'},
     {'id': 4, 'text': 'Определенно не должно иметь право'}]},
   {'q': '14',
    'type': 'multi',
    'globalQuestion': 'Ниже приведены некоторые цели, которые люди выбирают для своей страны на ближайшие десять лет. Какая цель, на ваш взгляд, самая важная?',
    'question': [{'text': 'Какая цель, на ваш взгляд, самая важная?',
      'id': 'E001'}],
    'answers': [{'id': 1, 'text': 'Высокий уровень экономического роста'},
     {'id': 2,
      'text': 'Cильная армия'},
     {'id': 3,
      'text': 'Хорошие условия на работе и в сообществах'},
     {'id': 4, 'text': 'Сделать наши города и села красивее'}]},
   {'q': '15',
    'type': 'multi',
    'globalQuestion': 'Ниже приведены примеры волонтерских организаций. Выберите организации, в которых вы состоите.',
    'question': 
        [{'text': 'Религиозные организации', 'id': 'A065'},
        {'text': 'Организации, посвященные образованию, искусству, музыке или культурной деятельности',
      'id': 'A066'},
        {'text': 'Организации профсоюзов', 'id': 'A067'},
        {'text': 'Организации, посвященные сохранению, окружающей среды, экологии, правам животных',
      'id': 'A071'}],
    'answers': 
        [{'id': 0, 'text': 'Не состою'},
        {'id': 1, 'text': 'Состою'},
    ]},
   {'q': '16',
    'type': 'multi',
    'globalQuestion': 'Доверие к людям',
    'question': [{'text': 'С каким утверждением вы согласны?',
      'id': 'A165'}],
    'answers': [{'id': 1, 'text': 'Большинству людей можно доверять'},
     {'id': 2, 'text': 'Нужно быть осторожным'},
     {'id': -1, 'text': 'Не знаю'}]},
   {'q': '17',
    'type': 'multi',
    'globalQuestion': 'Вот два утверждения, которые люди иногда делают, обсуждая окружающую среду и экономический рост.',
    'question': [{'text': 'Какое из них вам кажется важнее?',
      'id': 'B008'}],
    'answers': [{'id': 1, 'text': 'Защита окружающей среды'},
     {'id': 2, 'text': 'Рост экономики и создание рабочих мест'},
     {'id': -1, 'text': 'Не знаю'}]},
   {'q': '18',
    'type': 'multi',
    'globalQuestion': 'Конкуренция — хорошо или вредно?',
    'question': [{'text': 'Оцените это утверждение, где 1 – Хорошо, 10 – Вредно',
      'id': 'E039'}],
    'answers': [{'id': 1, 'text': 'Хорошо'},
     {'id': 2, 'text': '2'},
     {'id': 3, 'text': '3'},
     {'id': 4, 'text': '4'},
     {'id': 5, 'text': '5'},
     {'id': 6, 'text': '6'},
     {'id': 7, 'text': '7'},
     {'id': 8, 'text': '8'},
     {'id': 9, 'text': '9'},
     {'id': 10, 'text': 'Вредно'},
     ]},
     {
      'q': 23343,
      'type': 'ads',
      'link': 'https://evilunion.com/base-it-mini-kurs?utm_source=culture-compass',
      'image': {
        mob: ['/images/ads/kurs_it.png'],
        desk: ['/images/ads/kurs_it.png']
      }
    },
   {'q': '19',
    'type': 'multi',
    'globalQuestion': 'Возраст',
    'question': [{'text': 'Ваш возраст?', 'id': 'X003R'}],
    'answers': [{'id': 1, 'text': '15–24'},
     {'id': 2, 'text': '25–34'},
     {'id': 3, 'text': '35–44'},
     {'id': 4, 'text': '45–54'},
     {'id': 5, 'text': '55–64'},
     {'id': 6, 'text': '65 и более лет'}]}
    ]
}

export default ru;