import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green } from '@mui/material/colors';


let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1986EC',
    },
    secondary: {
      main: '#f44336',
    },
    success: {
      main: green[400]
    },
    info: {
      main: '#27155C'
    }
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: 72,
      color: '#d5d5de',
      fontFamily: 'Ubuntu-Bold',
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      // color: '#37373D',
      fontFamily: 'Ubuntu-Medium',
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      color: '#177DDC',
      fontFamily: 'Ubuntu-Medium',
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: 'Ubuntu-Light',
      color: '#c2c2d1',
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: '#c2c2d1',
    },
    body2: {
      fontStyle: 'italic',
      fontSize: 14,
      fontWeight: 400,
      color: '#c2c2d1',
    },
    body1: {
      fontStyle: 'regular',
      fontSize: 12,
      fontWeight: 400,
      opacity: 0.7,
      color: '#c2c2d1',
    }
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0px !important'
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(39, 21, 92, 0.1)',
          borderRadius: '14px',
          boxShadow: 'none'
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          // Some CSS
          fontSize: 1000,
          height: 1000
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'special' },
          style: {
            textTransform: 'none',
            border: '1px solid rgba(39, 21, 92, 0.1)',
            marginRight: 10,
            marginTop: 10,
            color: 'white',
            background: 'linear-gradient(180deg, #177DDC 0%, #1766DC 100%)',
            boxShadow: '0px 10px 30px rgba(0, 102, 255, 0.15)',
            borderRadius: '10px',
            wordWrap: 'break-word',
            // whiteSpace: 'nowrap',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            borderRadius: '10px',
            marginRight: 10,
            marginTop: 10,
            wordWrap: 'break-word',
            // whiteSpace: 'nowrap',
            border: '1px solid rgb(231 228 239 / 50%)',
            color: '#e1dfe8',
            maxWidth: 400
            // color: '#1986EC',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            // color: '#40474d',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
          },
        },
      ]
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          backdropFilter: "blur(4px)",
        },
      },
    }
  },
  lib: {
    padding: 5,
    margin: 5,
  }
});

theme = responsiveFontSizes(theme);
// console.log('theme', theme)
export default theme