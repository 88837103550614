const zh = {
    "data": [
        {
            "type": "text-html",
            "html": "<h1>分析</h1>"
        },
        {
            "type": "text-html",
            "html": "接下来，我想分享这些数据的有趣发现。<br/><br/> 您的回答通过一种神经网络进行了分析，该网络研究了社会学调查<a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>World Values Survey</a>中的140,000个受访者的回答。这种方法的优雅和独特之处在于神经网络缺乏意识和任何心理偏见。它没有上学，没有看过TikTok或电视。它可以掌握很多变量，使其发现非常诚实。<br/><br/> <i>当然，这个测试中的问题不能揭示各个国家的所有文化特点，但它们揭示了我们共同的地球上的基本生活价值观。</i> <br/><br/> 通过使用某些数学方法，我们可以构建一个国家地图，显示国家之间的聚类情况。在这种情况下，我们得到了6个主要的聚类簇。"
        },
        {
            "type": "img",
            "href": "/images/country_table.png",
            "alt": "World Values Survey"
        },
        {
            "type": "text-html",
            "html": "西方国家，亚洲，后苏联国家，拉丁美洲和穆斯林国家。点越靠近，这些国家的回答越相似。坐标轴是抽象的，因为它们同时考虑所有的回答。很可能，您的回答所属的国家属于这些簇之一。国家排列的原因在下面解释。<br/><br/> 我们显示您的回答来自不同国家的概率。我们取前5个国家。如果您的结果没有明确与特定国家对齐，这意味着您的回答是独特的。明确对齐的概率应该大于~50%。目前，我们得到多样的回答，有些情况下，一个国家可能有96%的概率。"
        },
        {
            "type": "pannel"
        },
        {
            "type": "text-html",
            "html": "您还可以点击测试结果中的国家，加载该国家的平均回答。它们将出现在您对问题的回答旁边。<br><br/><i>请注意，回答选项从1开始编号。此外，不同国家的人们可能对问题有不同的回答。例如，一半的受访者可能选择一个答案，而另一组选择相反的选项。在这些情况下，计算平均值将不合适。我们的神经网络考虑了这些情况，因为它是基于随机森林方法。</i><br/><br/>"
        },
        {
            "type": "text-html",
            "html": "<h2>关键问题</h2> 在训练过程中，我们将数据分为两部分：用于测试和训练的部分。算法从训练数据中学习，并在未参与训练的测试数据上评估其性能。这种优秀的方法是由上个世纪的数学家发明的，它构成了机器学习的基础。进行了大约2000次训练迭代，直到我们找到描述数据的最有效模型。如果您对更多的专业细节感兴趣，可以访问<a href='/zh/ml/' target='_blank'>这个页面</a>。有关基本解释，您可以查看<a href='/zh/catboost/' target='_blank'>这个链接</a>。<br/><br/><h3>在我们的算法学习之后，我们问它 - 哪些问题最重要？</h3><br/>以下是前15个关键问题：<ul><li>可接受：同性恋</li><li>上帝在你的生活中有多重要？</li><li>同性伴侣可以成为与其他伴侣一样好的父母</li><li>子女的责任是照顾生病的父母</li><li>政治体制：有强有力的领导</li><li>生育子女是对社会的责任</li><li>可接受：死刑</li><li>重要的儿童品质：勤奋</li><li>可接受：堕胎</li><li>邻居：同性恋</li><li>辩护：随便性行为</li><li>可接受：拒绝支付公共交通费用</li><li>工作应该是首要任务，即使意味着少了休闲时间</li><li>政治体制：由专家做决策</li><li>隶属于宗教组织</li></ul><br/><h3>可以说，这些问题有助于划分当今社会。通过这个项目，我们希望了解人们是谁，他们有何不同，以及我们如何更好地理解他们。</h3>"
        }
    ],
    "pannel": {
        "SubTitile": "连接您与这些国家居民的前5个答案",
        "TextButtom": "<i>您对这些问题的回答组合使您类似于该群体。<br/> 绿点表示该问题和您对其的回答的重要性。<br/> 您的回答可能是积极的或消极的。</i>",
        "ShareButton": "了解您与哪个国家的居民相似。",
        "call": "与朋友分享！"
    },
    "final": {
        "SubTitile": "您的回答类似于以下国家的人的回答 ",
        "whyAnswer": "为什么是这个答案？",
        "ShareButton": ["了解您与哪个国家的居民相似。\n我与", "% 的居民相似"],
        "call": "与朋友分享！"
    }
}

export default zh;