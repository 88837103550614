import React from 'react'
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row-reverse',
        justifyContent: 'center'
    },
    text: {
        width: 852,
    }
}))

export default function ButtonLink({props}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        props.url.includes('https') ?
          <div className={classes.text}>
            <a href={props.url} key={props.text} target='_blank'>
              <Button 
                variant="outlined"
                color={'info'}
                id='ButtonLink'
              >
                  {props.text}
              </Button>
            </a>
          </div>
        :
          <div className={classes.text}>
            <Link to={props.url} key={props.text}>
              <Button 
                variant="outlined"
                color={'info'}
                id='ButtonLink'
              >
                  {props.text}
              </Button>
            </Link>
          </div>
      }
    </div>
  )
}