import React from 'react';
import './App.css';
import Routes from './routes'
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PFHXK73'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes/>
      </div>
    </ThemeProvider>
  );
}

export default App;
