import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import PageBuilder from "../../lib/pageBuilder";
import CMS from '../lang/catboost'
import { useHistory } from "react-router-dom";
import LayOut from "../../layout";

const WraperText = styled('div')({
    color: '#c2c2d1',
    marginBottom: 40,
    // maxWidth: 600
})


export default function Heatmap() {
    const history = useHistory()

    const lang = window.location.href.split('/')[3]
    const langList = ['ru', 'en', 'es', 'zh', 'ja', 'fr', 'de', 'hi', 'pt', 'ar']

    if ( !langList.includes(window.location.href.split('/')[3] )) {
        history.push('/404')
    }

    const text = CMS[lang]
    
    return(
        <LayOut>
            <Seo props={{
                    title: text.seo.title, 
                    description: text.seo.description, 
                    url: text.seo.url,
                    lang: text.seo.lang,
                    langList: langList
                }}/>
            <WraperText>
                {
                    text.data.map((item, i) => (
                        <PageBuilder key={i} item={item} lang={lang}/>
                    ))
                }
            </WraperText>
        </LayOut>
    )
}