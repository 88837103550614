import React from 'react';
import Header from './header'
import Footer from './footer'
import CMS from './lang'
import { useHistory } from "react-router-dom";

const LayOut = ({children}) => {
  var langauge = window.location.href.split('/')[3]
  const history = useHistory()

  const [lang, setLang] = React.useState(langauge);
  const text = CMS[`${lang}`]
  

  React.useEffect(() => 
    setLang(langauge)
  );

  try {
    return(
      <>
        <Header text={text} lang={lang} setLang={setLang}/>
          <main>{children}</main>    
        <Footer text={text} lang={lang} />
      </>
    )
  } catch (e) {
    console.log(e)
    window.location.href = '/en/'
  }
}

export default LayOut
