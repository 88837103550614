import React from "react"
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 22px',
  },
  wrapper: {
    marginTop: 44,
    marginBottom: 44,
    maxWidth: 600
  },
  sub_contener: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
    },
    '@media (max-width: 767px)': {
    },
  },
  sub_text: {
    maxWidth: 600,
    '& a': {
      transition: '.3s ease all',
      textDecoration: 'none',
      color: 'white',
      // '&:hover': {
      //   color: theme.palette.primary.accent,
      // },
    },
    '& p': {
      fontSize: 20,
      lineHeight: '160%',
      color: '#A5A5A5',
      margin: 0,
      marginTop: 22,
      marginBottom: 22,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  img: {
    borderRadius: props =>
        (props.add_swadow === true)
        ? '22px'
        : 'none'
  },
}))

export default function BigImage({props}) {
  const add_swadow = true
  const isfullwidth = true
  const classes = useStyles({add_swadow, isfullwidth})


  return(
    <div className={classes.root}>
      <div className={classes.wrapper}>

        <img
          className={classes.img}
          src={props.href}
          alt={props.alt}/>

        <div className={classes.sub_contener}>
          <Typography
              className={classes.sub_text}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: props.html }}
          />
        </div>
      </div>
    </div>
  )
}
