const ja =     {
    "lang": "ja",
    "buttons": {
        "next": "下一页",
        "back": "返回",
        "send": "获取答案"
    },
    "AlertSnack": "如果您将手机水平旋转，将更方便继续操作。右侧有几个答案选项。有些浏览器可能不支持手机旋转。",
    error: {
        title: '何かがうまくいかない:',
        text: `すぐに修正します、もしサーバーエラーなら <br/>
        それかインターネット接続が切れたかもしれません？ 
        それともアップデートが近づいています`
    },
    "seo": {
        "title": "自分がどの国の住民に似ているかを見つける。",
        "description": "私たちは社会学の調査データを使ってニューラルネットワークを訓練しました。この調査は生活価値観を調査しており、90か国から14万人が参加しました。"
        },
        "begin": [
        {
            "type": "text-html",
            "html": "自分がどの国の住民に似ているかを見つける。"
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>私たちは国際社会学<a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>調査</a>のデータを使ってニューラルネットワークを訓練しました。この調査は生活価値観を調査しており、90か国から14万人が参加しました。私たちはその中からいくつかの質問を取り出してこのアプリケーションを作成しました。テストを完了すると、自分と似たように回答した国のリストと社会の概要が表示されます。</div>"
        }
        ],
    "data": [
        {
            'q': '1',
            'type': 'one',
            'globalQuestion': '以下は親が子供たちに育てることができる品質のリストです。もしあれば、特に重要だと考える項目を5つまで選んでください。',
            'question': [
              {'text': '良いマナー', 'id': 'A027'},
              {'text': '独立心', 'id': 'A029'},
              {'text': '勤勉さ', 'id': 'A030'},
              {'text': '責任感', 'id': 'A032'},
              {'text': '想像力', 'id': 'A034'},
              {'text': '寛容さと他人への尊重', 'id': 'A035'},
              {'text': '倹約、お金と物を大切にすること', 'id': 'A038'},
              {'text': '決断力と粘り強さ', 'id': 'A039'},
              {'text': '宗教的信念', 'id': 'A040'},
              {'text': 'エゴイストではないこと（利他主義）', 'id': 'A041'},
              {'text': '従順さ', 'id': 'A042'}
            ],
            'answers': [{'id': 1, 'text': '重要'}]
          },
          {
            'q': '2',
            'type': 'one',
            'globalQuestion': '以下は異なる人々のグループです。<br/>隣人として持ちたくない人々を選んでください。',
            'question': [
              {'text': '他の人種の人々', 'id': 'A124_02'},
              {'text': '大量に飲酒する人々', 'id': 'A124_03'},
              {'text': '移民/外国労働者', 'id': 'A124_06'},
              {'text': '麻薬中毒者', 'id': 'A124_08'},
              {'text': '同性愛者', 'id': 'A124_09'}
            ],
            'answers': [{'id': 1, 'text': '選択する'}]
          },
          {
            'q': '3',
            'type': 'multi',
            'globalQuestion': '以下の意見に同意しますか？',
            'question': [
              {'text': '同性のカップルは他のカップルと同じように良い親になれる', 'id': 'D081'},
              {'text': '子供を持つことは社会への義務', 'id': 'D026_03'},
              {'text': '子供は病気の親の世話をする責任がある', 'id': 'D026_05'},
              {'text': '仕事をしていない人々は怠け者になる', 'id': 'C038'},
              {'text': '仕事は社会への責任', 'id': 'C039'},
              {'text': '仕事は優先されるべきで、それによって自由な時間が少なくなるのであればよい', 'id': 'C041'}
            ],
            'answers': [
              {'id': 1, 'text': '完全に同意'},
              {'id': 2, 'text': '同意'},
              {'id': 3, 'text': 'どちらともいえない'},
              {'id': 4, 'text': '不同意'},
              {'id': 5, 'text': '断固として不同意'}
            ]
          },
          {
            'q': '4',
            'type': 'multi',
            'globalQuestion': '以下の価値観がどれだけ重要かを評価してください。',
            'question': [
              {'text': '家族', 'id': 'A001'},
              {'text': '友人', 'id': 'A002'},
              {'text': '自由な時間', 'id': 'A003'},
              {'text': '政治', 'id': 'A004'},
              {'text': '仕事', 'id': 'A005'},
              {'text': '宗教', 'id': 'A006'}
            ],
            'answers': [
              {'id': 1, 'text': '非常に重要'},
              {'id': 2, 'text': 'かなり重要'},
              {'id': 3, 'text': 'あまり重要ではない'},
              {'id': 4, 'text': '全く重要でない'}
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "以下の文にどのように答えますか？<br/>それらに同意しますか、それとも同意しませんか？",
            "question": [
              {
                "text": "男性は女性よりも職場でより多くの権利を持つべきです",
                "id": "C001"
              },
              {
                "text": "雇用主は移民よりも国の市民を優先すべきです",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "同意します"},
              {"id": 2, "text": "同意しません"},
              {"id": 3, "text": "どちらでもない"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "以下の文にどのように答えますか？",
            "question": [
              {
                "text": "人生の主な目標の1つは、両親に誇りを持たせることです",
                "id": "D054"
              },
              {
                "text": "男性は女性よりも政治的なリーダーになりやすいです",
                "id": "D059"
              },
              {
                "text": "大学は男の子にとって女の子よりも重要です",
                "id": "D060"
              },
              {
                "text": "母親が働くと、幼児期の子供たちに影響があります",
                "id": "D061"
              },
              {
                "text": "男性は女性よりもビジネスを運営するのが上手です",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "完全に同意します"},
              {"id": 2, "text": "同意します"},
              {"id": 3, "text": "同意しません"},
              {"id": 4, "text": "完全に同意しません"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "次の行動について、常に正当化できると考える、決して正当化できないと考える、またはその中間の何かを選択してください。 <br/> 1 – 決して正当化できない、10 – 常に正当化できる",
            "question": [
              {
                "text": "権利のない国家の援助を要求する",
                "id": "F114A"
              },
              {
                "text": "公共交通機関の料金を逃れる",
                "id": "F115"
              },
              {
                "text": "チャンスがあれば税金を逃れる",
                "id": "F116"
              },
              {
                "text": "公務執行中に賄賂を受け取る",
                "id": "F117"
              },
              {
                "text": "同性愛",
                "id": "F118"
              },
              {
                "text": "売春",
                "id": "F119"
              },
              {
                "text": "中絶",
                "id": "F120"
              },
              {
                "text": "離婚",
                "id": "F121"
              },
              {
                "text": "安楽死",
                "id": "F122"
              },
              {
                "text": "自殺",
                "id": "F123"
              },
              {
                "text": "カジュアルなセックス",
                "id": "F132"
              },
              {
                "text": "政治的暴力",
                "id": "E290"
              },
              {
                "text": "死刑",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "あなたの人生において神はどれくらい重要ですか？",
            "question": [
              {
                "text": "このスケールを使用してください：10は非常に重要を意味し、1は全く重要ではないことを意味します。",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "以下は異なる人々のグループです。それぞれのグループに対してどれだけ信頼していますか？",
            "question": [
              {"text": "あなたの家族", "id": "D001_B"},
              {"text": "あなたの地域", "id": "G007_18_B"},
              {"text": "個人的に知っている人々", "id": "G007_33_B"},
              {"text": "初対面の人々", "id": "G007_34_B"},
              {"text": "他の宗教の人々", "id": "G007_35_B"},
              {"text": "他の国籍の人々", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "完全に信頼している"},
              {"id": 2, "text": "少し信頼している"},
              {"id": 3, "text": "あまり信頼していない"},
              {"id": 4, "text": "全く信頼していない"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "以下はさまざまな政治制度の種類が記載されています。それぞれを国の統治方法としてどう考えますか？",
            "question": [
               {
                  "text": "議会や選挙と関わる必要のない強力なリーダーの存在",
                  "id": "E114"
               },
               {
                  "text": "政府ではなく専門家が国のために最善だと考えることに基づいて決定を下す存在",
                  "id": "E115"
               },
               {
                  "text": "民主的な政治制度の存在",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "非常に良い"},
               {"id": 2, "text": "かなり良い"},
               {"id": 3, "text": "かなり悪い"},
               {"id": 4, "text": "非常に悪い"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "民主的な統治が行われる国に住むことはあなたにとってどれだけ重要ですか？",
            "question": [
               {
                  "text": "このスケールでは、1は「全く重要でない」を意味し、10は「非常に重要」を意味します。あなたはどの位置を選びますか？",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "以下は政府が取るいくつかの行動です。あなたの意見では、政府はこれを行う権利がありますか？",
            "question": [
              {
                "text": "公共の場所での人々のビデオ監視",
                "id": "H009"
              },
              {
                "text": "インターネット上で交換されるすべての電子メールや他の情報の監視",
                "id": "H010"
              },
              {
                "text": "国内に住む人々の知識がないまま情報を収集する",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "絶対に権利を持つべき" },
              { "id": 2, "text": "多分権利を持つべき" },
              { "id": 3, "text": "多分権利を持つべきでない" },
              { "id": 4, "text": "絶対に権利を持つべきでない" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "以下は人々が自国のために選択するいくつかの目標です。あなたの意見では、最も重要な目標は何ですか？",
            "question": [
              { "text": "あなたの意見では、最も重要な目標は何ですか？", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "高い経済成長率" },
              { "id": 2, "text": "強力な軍隊" },
              { "id": 3, "text": "良い労働条件と社会条件" },
              { "id": 4, "text": "都市や村を美化すること" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "以下はボランティア団体の例です。所属している団体を選択してください。",
            "question": [
              { "text": "宗教団体", "id": "A065" },
              {
                "text": "教育、芸術、音楽、文化活動に捧げられた団体",
                "id": "A066"
              },
              { "text": "労働組合", "id": "A067" },
              {
                "text": "環境保護、生態学、動物の権利に捧げられた団体",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "所属していない" },
              { "id": 1, "text": "所属している" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "人間信頼",
            "question": [{"text": "あなたはどの主張に同意しますか？", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "ほとんどの人は信頼できる"},
              {"id": 2, "text": "注意が必要"},
              {"id": -1, "text": "わかりません"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "環境と経済成長について人々が時折述べる2つの声明があります。",
            "question": [{"text": "あなたにとってどちらが重要だと思いますか？", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "環境保護"},
              {"id": 2, "text": "経済成長と雇用創出"},
              {"id": -1, "text": "わかりません"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "競争 - 良いのか悪いのか？",
            "question": [{"text": "この主張に対して評価してください。1 - 良い、10 - 悪い", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "良い"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "悪い"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "年齢",
            "question": [{"text": "あなたの年齢は？", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15～24歳"},
              {"id": 2, "text": "25～34歳"},
              {"id": 3, "text": "35～44歳"},
              {"id": 4, "text": "45～54歳"},
              {"id": 5, "text": "55～64歳"},
              {"id": 6, "text": "65歳以上"}
            ]
          }
    ]
}

export default ja;