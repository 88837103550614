import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const MessageCode = ({message}) => {
  switch (message) {
    case "array must not contain infs or NaNs":
      return <Typography variant={'body2'}>The table must not contain 0</Typography>
  }
}



export default function ErrorSnack({data, lang, text}) {
  const [open, setOpen] = React.useState(true);
  // console.log(data)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    // document.location.reload();
  };

  return (
    <div>
      <Snackbar 
        dir={lang !== 'ar' ? "ltl" : "rtl"}
        open={open} 
        id='Error message'
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {text.title}
            <Typography variant={'body2'}>{data.message}</Typography>
            <MessageCode message={data.message}/>
            <div dangerouslySetInnerHTML={{__html: text.text}}/>
          </Alert>
      </Snackbar>
    </div>
  )
}