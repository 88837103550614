import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import LayOut from "../../layout";


const Title = styled('H1')({
    color: '#c2c2d1',
})

const WraperFrame = styled('div')({
    background: 'white',
    padding: 40,
    borderRadius: 40,
    "@media(max-width: 767px)": {
        padding: 5,
    }
})

const WraperText = styled('div')({
    color: '#c2c2d1',
    marginBottom: 40,
})



export default function Heatmap() {
    
    return(
        <LayOut>
            <Seo props={{title: 'Heatmap стран World Values Survey', description: 'World Values Survey (WVS) - это международный исследовательский проект, который проводит опросы общественного мнения в разных странах мира. Цель WVS - изучение социальных и культурных изменений, происходящих в обществе, и оценка взглядов на различные аспекты жизни, такие как политика, религия, семья, работа, доверие, моральные ценности и т.д.', url: '/heatmap/', lang: 'ru'}}/>
            <Title>Heatmap стран World Values Survey</Title>
            <WraperText>
                    World Values Survey (WVS) — это международный исследовательский проект, который проводит опросы общественного мнения в разных странах мира. Цель WVS — изучение социальных и культурных изменений, происходящих в обществе, и оценка взглядов на различные аспекты жизни, такие как политика, религия, семья, работа, доверие, моральные ценности и т.д.
                <br/><br/>       
                    WVS проводит опросы с помощью стандартизированных вопросников, которые задаются респондентам в разных странах мира. Вопросы отражают взгляды на различные аспекты жизни и могут включать в себя такие темы, как вера в Бога, отношение к гомосексуализму, национализм, доверие к правительству, ожидания от брачной жизни и т.д. Вопросы используются для определения мировых трендов и изменений в социально-культурной сфере, а также для сравнения различных стран мира.
                <br/><br/>
                    На этой странице можно построить heatmap средних ответов на вопросы из <a href="/ru">Cultura Compass</a>. Внимание - ответы на вопросы пронамированны слева направо и начинаються с 1. И лучше параллельно смотреть на сам вопрос в тесте, перед тем как изучать его на графике. Вот <a href="https://worldvaluessurvey.org/WVSOnline.jsp">оригинальная страница </a>от организации на английском. 
                <br/><br/>
            </WraperText>

            {
                document.body.clientWidth < 600 ? 
                    <WraperText>
                        Рекомендуем перевернуть телефон и обновить страницу или смотреть на большом экране
                    </WraperText>
                :
                    <div></div>
            }
            <WraperFrame >
                <iframe 
                    title='heatmap WVS'
                    width={document.body.clientWidth > 600 ? 730 : document.body.clientWidth-50}
                    height={document.body.clientWidth > 600 ? 900 : 1700}
                    frameBorder="0"
                    scrolling="no"
                    src="https://dash.evilunion.com/wvs-heatmap">
                </iframe>
            </WraperFrame>
        </LayOut>
    )
}