import React from 'react';
import { CircularProgress } from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 500,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  }
}))


export default function Progress() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={120}/>
    </div>
  )
}
