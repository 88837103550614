import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});







export default function AlertSnack({text, lang}) {
  const [open, setOpen] = React.useState(true);
  // console.log(data)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    // document.location.reload();
  };

  return (
    <div>
      <Snackbar 
        dir={lang !== 'ar' ? "ltl" : "rtl"}
        open={open} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
          <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
            {text}
          </Alert>
      </Snackbar>
    </div>
  )
}