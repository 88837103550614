const hi =     {
    "lang": "hi",
    "buttons": {
        "next": "आगे",
        "back": "पीछे",
        "send": "उत्तर प्राप्त करें"
    },
    "AlertSnack": "अगर आप अपना फोन सिद्धांतवादी ढंग से घुमाएंगे तो आगे बढ़ना आसान होगा। दाईं ओर कई उत्तर विकल्प हैं। कुछ ब्राउज़र फोन की घुमाई का समर्थन नहीं कर सकते।",
    error: {
        title: 'कुछ गड़बड़ हो गई:',
        text: `हम जल्द ही इसे ठीक कर देंगे, अगर यह सर्वर त्रुटि है <br/>
        या शायद आपने इंटरनेट कनेक्शन खो दिया है? 
        और हो सकता है कि एक अपडेट आ रहा हो`
    },
    "seo": {
        "title": "पता लगाएं कि आप किस देश के निवासी के समान दिखते हैं।",
        "description": "हमने जीवन मूल्यों का परीक्षण करने वाले एक समाजशास्त्रीय अध्ययन से प्राथमिक डेटा के साथ एक न्यूरल नेटवर्क प्रशिक्षित किया। इसमें 90 देशों से 140,000 लोगों के साथ अध्ययन हुआ था।"
        },
        "begin": [
        {
            "type": "text-html",
            "html": "पता लगाएं कि आप किस देश के निवासी के समान दिखते हैं।"
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>हमने जीवन मूल्यों का परीक्षण करने वाले <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>अंतरराष्ट्रीय</a> समाजशास्त्रीय अध्ययन के डेटा के साथ एक न्यूरल नेटवर्क प्रशिक्षित किया। इसमें 90 देशों से 140,000 लोगों के साथ अध्ययन हुआ था। हमने वहां से कुछ प्रश्न लिए और इस एप्लिकेशन को बनाया। परीक्षण पूरा करने के बाद, आप उन देशों की सूची देखेंगे जहां लोग आपके समान जवाब दिए हैं और हमारे समाज की एक अवलोकन। </div>"
        }
        ],
    "data": [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "नीचे उन गुणों की सूची दी गई है जिन्हें माता-पिता अपने बच्चों में सिखा सकते हैं। आप कौन से गुण को विशेष रूप से महत्वपूर्ण मानते हैं? कृपया पाँच विकल्प तक चुनें।",
            "question": [
                {"text": "अच्छा संस्कार", "id": "A027"},
                {"text": "स्वतंत्रता", "id": "A029"},
                {"text": "मेहनती", "id": "A030"},
                {"text": "जिम्मेदारी का भाव", "id": "A032"},
                {"text": "कल्पना-शक्ति", "id": "A034"},
                {"text": "सहिष्णुता और दूसरों का सम्मान", "id": "A035"},
                {"text": "अर्थप्रबंधन, पैसे और सामान की बचत", "id": "A038"},
                {"text": "निर्धारित, सहनशीलता", "id": "A039"},
                {"text": "धार्मिक आस्था", "id": "A040"},
                {"text": "स्वार्थी न होना (उदारता)", "id": "A041"},
                {"text": "आज्ञाकारी", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "महत्वपूर्ण"}]
        },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "नीचे विभिन्न लोगों के समूह हैं। <br/> कृपया चुनें किसे आप पड़ोसी के रूप में नहीं चाहते हैं।",
            "question": [
                {"text": "दूसरे जाति के लोग", "id": "A124_02"},
                {"text": "अधिक पियक्कड़ लोग", "id": "A124_03"},
                {"text": "आप्रवासी / विदेशी श्रमिक", "id": "A124_06"},
                {"text": "नशेड़ी", "id": "A124_08"},
                {"text": "मार्गेशील", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "चुनें"}]
        },
        {
            "q": "3",
            "type": "multi",
            "globalQuestion": "क्या आप निम्नलिखित कथनों से सहमत हैं?",
            "question": [
              {
                "text": "होमोसेक्सुअल कपल अन्य कपलों के तरह अच्छे माता-पिता बन सकते हैं",
                "id": "D081"
              },
              {
                "text": "बच्चों को पालना समाज के प्रति दायित्व है",
                "id": "D026_03"
              },
              {
                "text": "बीमार माता-पिता की देखभाल करना बच्चे का कर्तव्य है",
                "id": "D026_05"
              },
              {
                "text": "जो लोग काम नहीं करते, वे आलसी हो जाते हैं",
                "id": "C038"
              },
              {
                "text": "काम समाज के प्रति एक कर्तव्य है",
                "id": "C039"
              },
              {
                "text": "काम पहले आना चाहिए, चाहे इसका मतलब कम फ्री टाइम होना हो",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "पूरी तरह से सहमत" },
              { "id": 2, "text": "सहमत" },
              { "id": 3, "text": "तटस्थ" },
              { "id": 4, "text": "असहमत" },
              { "id": 5, "text": "पूरी तरह से असहमत" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "निम्नलिखित मूल्यों के लिए आपके लिए कितने महत्वपूर्ण हैं?",
            "question": [
              { "text": "परिवार", "id": "A001" },
              { "text": "दोस्त", "id": "A002" },
              { "text": "समय बिताने का समय", "id": "A003" },
              { "text": "राजनीति", "id": "A004" },
              { "text": "काम", "id": "A005" },
              { "text": "धर्म", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "बहुत महत्वपूर्ण" },
              { "id": 2, "text": "काफी महत्वपूर्ण" },
              { "id": 3, "text": "बहुत कम महत्वपूर्ण" },
              { "id": 4, "text": "बिल्कुल भी महत्वपूर्ण नहीं" }
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "निम्नलिखित वाक्यों के प्रति आप कैसे प्रतिक्रिया देंगे? <br/> उनसे सहमत हैं या उनका खिलाफ हैं?",
            "question": [
              {
                "text": "पुरुषों को महिलाओं से अधिक अधिकार होने चाहिए।",
                "id": "C001"
              },
              {
                "text": "नियोक्ता अपराधियों की बजाय देश के नागरिकों को चुनेंगे।",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "सहमत हूं"},
              {"id": 2, "text": "असहमत हूं"},
              {"id": 3, "text": "ऊपर दिए गए में से कोई नहीं"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "निम्नलिखित वाक्यों के साथ आप सहमत हैं?",
            "question": [
              {
                "text": "जीवन में प्रमुख उद्देश्यों में से एक है कि मैं अपने माता-पिता को गर्व महसूस कराऊं",
                "id": "D054"
              },
              {
                "text": "पुरुषों के मुकाबले महिलाओं के लिए राजनीतिक नेता होने की गुणवत्ता ज्यादा है",
                "id": "D059"
              },
              {
                "text": "छोटे बच्चों के लिए उच्च शिक्षा लड़कों के लिए लड़कियों के लिए से अधिक महत्वपूर्ण है",
                "id": "D060"
              },
              {
                "text": "जब माँ काम करती है, तो प्राथमिक शिक्षा आयु के बच्चे प्रभावित होते हैं",
                "id": "D061"
              },
              {
                "text": "पुरुषों को महिलाओं से अधिक बिजनेस प्रबंधन करने का बेहतर हैं",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "पूरी तरह सहमत हूं"},
              {"id": 2, "text": "सहमत हूं"},
              {"id": 3, "text": "असहमत हूं"},
              {"id": 4, "text": "पूरी तरह असहमत हूं"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "कृपया इस स्केल का उपयोग करके उन कार्रवाईयों का चयन करें जिन्हें आपके अनुसार हमेशा उचित बताया जा सकता है, कभी भी उचित नहीं बताया जा सकता है, या इसके बीच कुछ है।<br/> 1 – कभी भी उचित नहीं, 10 – हमेशा उचित",
            "question": [
              {
                "text": "जिन्हें आपका कोई अधिकार नहीं है, उन सरकारी सहायताओं की मांग करें",
                "id": "F114A"
              },
              {
                "text": "सार्वजनिक परिवहन के लिए भुगतान करने से बचें",
                "id": "F115"
              },
              {
                "text": "यदि आपको मौका मिले, तो करोड़पति बनाना",
                "id": "F116"
              },
              {
                "text": "अधिकारियों के कर्तव्यों को निपटाने के दौरान रिश्वत लेना",
                "id": "F117"
              },
              {
                "text": "यौनविज्ञान",
                "id": "F118"
              },
              {
                "text": "वेश्यावृत्ति",
                "id": "F119"
              },
              {
                "text": "गर्भपात",
                "id": "F120"
              },
              {
                "text": "तलाक",
                "id": "F121"
              },
              {
                "text": "इच्छामृत्यु",
                "id": "F122"
              },
              {
                "text": "आत्महत्या",
                "id": "F123"
              },
              {
                "text": "आकस्मिक संबंध",
                "id": "F132"
              },
              {
                "text": "राजनीतिक हिंसा",
                "id": "E290"
              },
              {
                "text": "मृत्युदंड",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "आपके जीवन में भगवान कितने महत्वपूर्ण हैं?",
            "question": [
              {
                "text": "कृपया इस स्केल का उपयोग करके दर्शाएं: 10 बहुत महत्वपूर्ण होता है, और 1 बिल्कुल महत्वपूर्ण नहीं होता है।",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "नीचे विभिन्न लोगों के समूह दिए गए हैं। आप हर समूह पर कितना भरोसा करते हैं?",
            "question": [
              {"text": "आपका परिवार", "id": "D001_B"},
              {"text": "आपका आस-पास का क्षेत्र", "id": "G007_18_B"},
              {"text": "आप व्यक्तिगत रूप से जानते हैं", "id": "G007_33_B"},
              {"text": "आप पहली बार मिलते हैं", "id": "G007_34_B"},
              {"text": "अन्य धर्म के लोग", "id": "G007_35_B"},
              {"text": "अन्य राष्ट्रीयता के लोग", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "पूरी तरह भरोसा करते हैं"},
              {"id": 2, "text": "थोड़ा भरोसा करते हैं"},
              {"id": 3, "text": "बहुत भरोसा नहीं करते हैं"},
              {"id": 4, "text": "पूरी तरह भरोसा नहीं करते हैं"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "नीचे विभिन्न राजनीतिक प्रणालियों का वर्णन है। इन्हें शासन के एक तरीके के रूप में आप इन्हें कैसे देखते हैं?",
            "question": [
               {
                  "text": "संसद और चुनावों से नहीं झुझने वाले एक मजबूत नेता की उपस्थिति",
                  "id": "E114"
               },
               {
                  "text": "सरकार के बजाय विशेषज्ञों की उपस्थिति, जो देश के लिए सर्वोत्तम मानते हैं और निर्णय लेते हैं",
                  "id": "E115"
               },
               {
                  "text": "लोकतांत्रिक राजनीतिक प्रणालि की उपस्थिति",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "बहुत अच्छा"},
               {"id": 2, "text": "काफी अच्छा"},
               {"id": 3, "text": "काफी खराब"},
               {"id": 4, "text": "बहुत खराब"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "आपके लिए लोकतांत्रिक शासन वाले देश में रहना कितना महत्वपूर्ण है?",
            "question": [
               {
                  "text": "इस स्केल पर, जहां 1 'पूरी तरह से महत्वहीन' को दर्शाता है और 10 'पूरी तरह से महत्वपूर्ण' को दर्शाता है, आप कौनसी स्थिति चुनेंगे?",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "नीचे सरकार द्वारा कई कार्रवाईयां दी गई हैं। आपकी राय में, क्या सरकार को इसका अधिकार है?",
            "question": [
              {
                "text": "सार्वजनिक स्थानों में लोगों को वीडियो सतर्कता के अंतर्गत रखना",
                "id": "H009"
              },
              {
                "text": "इंटरनेट पर भेजे गए सभी इलेक्ट्रॉनिक मेल और किसी भी अन्य जानकारी का निगरानी करना",
                "id": "H010"
              },
              {
                "text": "लोगों के बिना उनके ज्ञान के बिना रहने वाले व्यक्तियों के बारे में जानकारी इकट्ठा करना",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "निश्चित रूप से हक़दार होना चाहिए" },
              { "id": 2, "text": "शायद हक़दार होना चाहिए" },
              { "id": 3, "text": "शायद हक़दार न होना चाहिए" },
              { "id": 4, "text": "निश्चित रूप से हक़दार न होना चाहिए" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "नीचे कुछ लक्ष्य हैं जिन्हें लोग अपने देश के लिए अगले दस वर्षों में चुनते हैं। आपके विचार में, कौन सा लक्ष्य सबसे महत्वपूर्ण है?",
            "question": [
              { "text": "आपके विचार में, कौन सा लक्ष्य सबसे महत्वपूर्ण है?", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "उच्च आर्थिक विकास दर" },
              { "id": 2, "text": "मजबूत सैन्य बल" },
              { "id": 3, "text": "अच्छी रोजगार और समुदाय शर्तें" },
              { "id": 4, "text": "हमारे शहरों और गांवों को सुंदर बनाना" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "नीचे दैयवी संस्थानों के उदाहरण हैं। कृपया उन संस्थानों का चयन करें जिनमें आप सदस्य हैं।",
            "question": [
              { "text": "धार्मिक संस्थान", "id": "A065" },
              {
                "text": "शिक्षा, कला, संगीत या सांस्कृतिक गतिविधियों को समर्पित संस्थान",
                "id": "A066"
              },
              { "text": "कामगार संघ", "id": "A067" },
              {
                "text": "पर्यावरण, पारिस्थितिकी, पशु अधिकारों को संरक्षित रखने को समर्पित संस्थान",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "किसी संस्थान का सदस्य नहीं हूँ" },
              { "id": 1, "text": "कुछ संस्थानों का सदस्य हूँ" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "लोगों पर विश्वास",
            "question": [{"text": "किस वक्तव्य से आप सहमत हैं?", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "आम तौर पर लोग विश्वास योग्य होते हैं"},
              {"id": 2, "text": "सतर्क रहना चाहिए"},
              {"id": -1, "text": "मुझे नहीं पता"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "यहाँ पर्यावरण और आर्थिक विकास के बारे में लोग कभी-कभी दो बयान करते हैं।",
            "question": [{"text": "आपके लिए कौन सा अधिक महत्वपूर्ण लगता है?", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "पर्यावरण की रक्षा"},
              {"id": 2, "text": "आर्थिक विकास और रोजगार की सृजन"},
              {"id": -1, "text": "मुझे नहीं पता"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "प्रतियोगिता - अच्छी या बुरी?",
            "question": [{"text": "इस वक्तव्य का मूल्यांकन कीजिए, जहां 1 - अच्छी, 10 - बुरी", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "अच्छी"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "बुरी"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "आयु",
            "question": [{"text": "आपकी आयु क्या है?", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15-24 वर्ष"},
              {"id": 2, "text": "25-34 वर्ष"},
              {"id": 3, "text": "35-44 वर्ष"},
              {"id": 4, "text": "45-54 वर्ष"},
              {"id": 5, "text": "55-64 वर्ष"},
              {"id": 6, "text": "65 वर्ष और उससे अधिक"}
            ]
          }
    ]
}

export default hi