const zh =     {
    "lang": "zh",
    "buttons": {
        "next": "下一页",
        "back": "返回",
        "send": "获取答案"
    },
    "AlertSnack": "如果您将手机水平旋转，将更方便继续操作。右侧有几个答案选项。有些浏览器可能不支持手机旋转。",
    error: {
        title: '出了点问题：',
        text: `我们会尽快解决，如果是服务器错误的话 <br/>
        或者可能是您断网了？ 
        还有可能是我们即将推出更新`
    },
    "seo": {
        "title": "了解你像哪个国家的居民。",
        "description": "我们使用来自社会学研究的数据对神经网络进行了训练，该研究调查了生活价值观。该研究涵盖了来自90个国家的140,000人。"
        },
        "begin": [
        {
            "type": "text-html",
            "html": "了解你像哪个国家的居民。"
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>我们使用来自国际社会学<a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>研究</a>的数据对神经网络进行了训练，该研究调查了生活价值观。该研究涵盖了来自90个国家的140,000人。我们从中选取了一些问题并创建了这个应用。完成测试后，您将看到一个国家列表，其中人们的回答与您相似，并且我们社会的概述。</div>"
        }
        ],
    "data": [
        {
            'q': '1',
            'type': 'one',
            'globalQuestion': '以下是父母可能培养子女的品质列表。如果有，请选择您认为特别重要的五个选项。',
            'question': [
              {'text': '良好的举止', 'id': 'A027'},
              {'text': '独立性', 'id': 'A029'},
              {'text': '勤劳', 'id': 'A030'},
              {'text': '责任感', 'id': 'A032'},
              {'text': '想象力', 'id': 'A034'},
              {'text': '容忍和尊重他人', 'id': 'A035'},
              {'text': '节俭，节约金钱和物品', 'id': 'A038'},
              {'text': '果断和坚持', 'id': 'A039'},
              {'text': '宗教信仰', 'id': 'A040'},
              {'text': '不自私（无私）', 'id': 'A041'},
              {'text': '听从', 'id': 'A042'}
            ],
            'answers': [{'id': 1, 'text': '重要'}]
          },
          {
            'q': '2',
            'type': 'one',
            'globalQuestion': '以下是不同群体的人。<br/>请选择您不想要作为邻居的人。',
            'question': [
              {'text': '不同种族的人', 'id': 'A124_02'},
              {'text': '酗酒者', 'id': 'A124_03'},
              {'text': '移民/外国工人', 'id': 'A124_06'},
              {'text': '吸毒者', 'id': 'A124_08'},
              {'text': '同性恋者', 'id': 'A124_09'}
            ],
            'answers': [{'id': 1, 'text': '选择'}]
          },
          {
            'q': '3',
            'type': 'multi',
            'globalQuestion': '您是否同意以下观点？',
            'question': [
              {'text': '同性伴侣可以成为和其他夫妇一样好的父母', 'id': 'D081'},
              {'text': '拥有孩子是对社会的责任', 'id': 'D026_03'},
              {'text': '子女有责任照顾生病的父母', 'id': 'D026_05'},
              {'text': '不工作的人会变得懒惰', 'id': 'C038'},
              {'text': '工作是对社会的责任', 'id': 'C039'},
              {'text': '工作应该排在第一位，即使这意味着有较少的空闲时间', 'id': 'C041'}
            ],
            'answers': [
              {'id': 1, 'text': '完全同意'},
              {'id': 2, 'text': '同意'},
              {'id': 3, 'text': '中立'},
              {'id': 4, 'text': '不同意'},
              {'id': 5, 'text': '坚决不同意'}
            ]
          },
          {
            'q': '4',
            'type': 'multi',
            'globalQuestion': '以下价值观对您有多重要？',
            'question': [
              {'text': '家庭', 'id': 'A001'},
              {'text': '朋友', 'id': 'A002'},
              {'text': '空闲时间', 'id': 'A003'},
              {'text': '政治', 'id': 'A004'},
              {'text': '工作', 'id': 'A005'},
              {'text': '宗教', 'id': 'A006'}
            ],
            'answers': [
              {'id': 1, 'text': '非常重要'},
              {'id': 2, 'text': '相当重要'},
              {'id': 3, 'text': '不太重要'},
              {'id': 4, 'text': '完全不重要'}
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "对以下陈述你将如何回答？<br/>你同意还是不同意这些陈述？",
            "question": [
              {
                "text": "男性在工作中应该享有比女性更多的权利",
                "id": "C001"
              },
              {
                "text": "雇主应该优先选择本国公民而不是移民",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "同意"},
              {"id": 2, "text": "不同意"},
              {"id": 3, "text": "无法选择"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "你是否同意以下陈述？",
            "question": [
              {
                "text": "生活中的一个主要目标是让父母为我感到骄傲",
                "id": "D054"
              },
              {
                "text": "男性比女性更适合担任政治领导人",
                "id": "D059"
              },
              {
                "text": "对男孩来说，大学比对女孩来说更重要",
                "id": "D060"
              },
              {
                "text": "当母亲工作时，学龄前儿童会受到影响",
                "id": "D061"
              },
              {
                "text": "男性比女性更擅长管理企业",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "完全同意"},
              {"id": 2, "text": "同意"},
              {"id": 3, "text": "不同意"},
              {"id": 4, "text": "完全不同意"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "请根据这个评分标准选择在你看来总是可以理解的、永远不能理解的，或者介于其中的行为。<br/> 1 – 从不可以理解，10 – 总是可以理解",
            "question": [
              {
                "text": "要求你没有权利获得的国家救济",
                "id": "F114A"
              },
              {
                "text": "逃避公共交通费用",
                "id": "F115"
              },
              {
                "text": "如果你有机会，逃税",
                "id": "F116"
              },
              {
                "text": "在执行公务时接受贿赂",
                "id": "F117"
              },
              {
                "text": "同性恋",
                "id": "F118"
              },
              {
                "text": "卖淫",
                "id": "F119"
              },
              {
                "text": "堕胎",
                "id": "F120"
              },
              {
                "text": "离婚",
                "id": "F121"
              },
              {
                "text": "安乐死",
                "id": "F122"
              },
              {
                "text": "自杀",
                "id": "F123"
              },
              {
                "text": "随意性行为",
                "id": "F132"
              },
              {
                "text": "政治暴力",
                "id": "E290"
              },
              {
                "text": "死刑",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "上帝在你的生活中有多重要？",
            "question": [
              {
                "text": "请使用这个标度来表示：10表示非常重要，而1表示一点都不重要。",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "以下是不同的人群。您对每个人群有多少信任？",
            "question": [
              {"text": "您的家人", "id": "D001_B"},
              {"text": "您所在的社区", "id": "G007_18_B"},
              {"text": "您个人认识的人", "id": "G007_33_B"},
              {"text": "您第一次见面的人", "id": "G007_34_B"},
              {"text": "其他宗教的人", "id": "G007_35_B"},
              {"text": "其他国籍的人", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "完全信任"},
              {"id": 2, "text": "有点信任"},
              {"id": 3, "text": "不太信任"},
              {"id": 4, "text": "完全不信任"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "下面列出了各种类型的政治制度。您认为它们作为治理方式的看法如何？",
            "question": [
               {
                  "text": "存在不必与议会和选举打交道的强大领导人",
                  "id": "E114"
               },
               {
                  "text": "存在专家而不是政府，根据他们认为对国家最有利的方式做出决策",
                  "id": "E115"
               },
               {
                  "text": "存在民主政治体制",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "非常好"},
               {"id": 2, "text": "相当好"},
               {"id": 3, "text": "相当差"},
               {"id": 4, "text": "非常差"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "对于您来说，生活在一个民主治理的国家有多重要？",
            "question": [
               {
                  "text": "在这个范围内，1表示“一点也不重要”，10表示“非常重要”，您会选择哪个位置？",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "以下是政府采取的几项行动。您认为政府有权这样做吗？",
            "question": [
              {
                "text": "在公共场所对人进行视频监控",
                "id": "H009"
              },
              {
                "text": "监视所有电子邮件和在互联网上交换的任何其他信息",
                "id": "H010"
              },
              {
                "text": "在未经他们知情的情况下收集居住在该国的人的信息",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "肯定应该有权" },
              { "id": 2, "text": "可能应该有权" },
              { "id": 3, "text": "可能不应该有权" },
              { "id": 4, "text": "肯定不应该有权" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "以下是人们为本国在未来十年选择的一些目标。在您看来，哪个目标最重要？",
            "question": [
              { "text": "在您看来，哪个目标最重要？", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "高水平经济增长" },
              { "id": 2, "text": "强大的军队" },
              { "id": 3, "text": "良好的工作和社区条件" },
              { "id": 4, "text": "美化我们的城市和乡村" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "以下是志愿组织的例子。请选择您所属的组织。",
            "question": [
              { "text": "宗教组织", "id": "A065" },
              {
                "text": "致力于教育、艺术、音乐或文化活动的组织",
                "id": "A066"
              },
              { "text": "工会组织", "id": "A067" },
              {
                "text": "致力于环境保护、生态学、动物权益的组织",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "不属于任何组织" },
              { "id": 1, "text": "属于" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "对人的信任",
            "question": [{"text": "你同意哪个陈述？", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "大多数人是值得信任的"},
              {"id": 2, "text": "应该谨慎"},
              {"id": -1, "text": "我不知道"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "以下是人们有时在讨论环境和经济增长时提出的两个观点。",
            "question": [{"text": "你认为哪个更重要？", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "保护环境"},
              {"id": 2, "text": "经济增长和创造就业机会"},
              {"id": -1, "text": "我不知道"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "竞争-是好还是坏？",
            "question": [{"text": "对这个陈述进行评分，其中1-好，10-坏", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "好"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "坏"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "年龄",
            "question": [{"text": "你的年龄是？", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15–24岁"},
              {"id": 2, "text": "25–34岁"},
              {"id": 3, "text": "35–44岁"},
              {"id": 4, "text": "45–54岁"},
              {"id": 5, "text": "55–64岁"},
              {"id": 6, "text": "65岁及以上"}
            ]
          }
    ]
}


export default zh;