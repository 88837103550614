import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Modal, Slide } from '@mui/material';
import RowCards from '../lib/rowCards';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: 500,
    height: '100vh',
    boxShadow: 24,
    overflowY: 'scroll',
    background: 'white',
    p: 4,
    padding: 20,
    borderRadius: '0px 40px 0px 40px',
    [theme.breakpoints.down('md')]: {
      width: '70vw',
    },
    paddingBottom: 200,
  },
}));

export default function ModalButton({ text, name, items = [] }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button id={'modal-button ' + name} variant={'outlined'} onClick={() => setOpen(true)}>
        {name}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="right" in={open} mountOnEnter unmountOnExit>
          <div className={classes.modal}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {items.length !== 0 ? <RowCards items={items} sm={12} /> : <div />}
          </div>
        </Slide>
      </Modal>
    </>
  );
}
