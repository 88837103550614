const ja = {
    header: {
      transale: '機械翻訳を使用しました。<br/>一部が正しく翻訳されていない場合があります。'
    },
    footer: {
      projects: {
        title: '# 私たちのプロジェクト',
        data: [
        //   {
        //     text: 'コンプライアンス分析',
        //     url: 'https://awake-tools.com/ja/ca?utm_source=culture-compass'
        //   },
          {
            text: 'PWAアプリケーション',
            url: 'https://evilunion.com/en/pwa?utm_source=culture-compass'
          },
        ]
      },
      us: {
        title: '# 私たちについて',
        data: [
          {
            text: 'evilUnion',
            url: 'https://evilunion.com/en?utm_source=culture-compass'
          },
          {
            text: 'フィードバック',
            url: 'https://t.me/evilunion_chat'
          },
          // {
          // text: 'VC Blog',
          // url: 'https://vc.ru/u/939695-evilunion?utm_source=culture-compass'
          // },
          {
            text: '利用規約',
            url: 'https://culturacompass.com/en/terms'
          },
          {
            text: 'プライバシーポリシー',
            url: 'https://culturacompass.com/en/cookie'
          },
        ]
      }
    }
  };
  
  export default ja;
  