const fr =     {
    "lang": "fr",
    "buttons": {
        "next": "Suivant",
        "back": "Retour",
        "send": "Obtenir la réponse"
    },
    "AlertSnack": "Si vous tournez votre téléphone horizontalement, il sera plus pratique de continuer. Il y a plusieurs options de réponse sur la droite. Certains navigateurs peuvent ne pas prendre en charge la rotation du téléphone.",
    error: {
        title: 'Quelque chose s\'est mal passé :',
        text: `Nous réglerons cela rapidement, s'il s'agit d'une erreur de serveur <br/>
        Ou peut-être avez-vous perdu votre connexion internet ? 
        Et il pourrait y avoir une mise à jour à venir`
    },
    "seo": {
        "title": "Découvrez à quel pays vous ressemblez en tant que résident.",
        "description": "Nous avons entraîné un réseau neuronal sur des données issues d'une étude sociologique qui examine les valeurs de vie. Elle a été réalisée auprès de 140 000 personnes issues de 90 pays."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "Découvrez à quel pays vous ressemblez en tant que résident."
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>Nous avons entraîné un réseau neuronal sur des données issues d'une étude sociologique <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>internationale</a> qui examine les valeurs de vie. Elle a été réalisée auprès de 140 000 personnes issues de 90 pays. Nous avons sélectionné certaines questions de cette étude pour créer cette application. Après avoir terminé le test, vous verrez une liste de pays où les gens ont répondu de manière similaire à vous, ainsi qu'une vue d'ensemble de notre société.</div>"
        }
        ],
    "data": [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "Ci-dessous se trouve une liste de qualités que les parents peuvent inculquer à leurs enfants. Lesquelles, le cas échéant, considérez-vous comme particulièrement importantes ? Veuillez sélectionner jusqu'à cinq options.",
            "question": [
                {"text": "Bonnes manières", "id": "A027"},
                {"text": "Indépendance", "id": "A029"},
                {"text": "Diligence", "id": "A030"},
                {"text": "Sens des responsabilités", "id": "A032"},
                {"text": "Imagination", "id": "A034"},
                {"text": "Tolérance et respect envers les autres", "id": "A035"},
                {"text": "Économie, épargner de l'argent et des biens", "id": "A038"},
                {"text": "Détermination, persévérance", "id": "A039"},
                {"text": "Foi religieuse", "id": "A040"},
                {"text": "Ne pas être égoïste (altruisme)", "id": "A041"},
                {"text": "Obéissance", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "Important"}]
        },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "Ci-dessous se trouvent différentes catégories de personnes. <br/>Choisissez celles que vous n'aimeriez pas avoir comme voisins.",
            "question": [
                {"text": "Personnes d'une autre race", "id": "A124_02"},
                {"text": "Gros buveurs", "id": "A124_03"},
                {"text": "Immigrants/travailleurs étrangers", "id": "A124_06"},
                {"text": "Toxicomanes", "id": "A124_08"},
                {"text": "Personnes homosexuelles", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "Sélectionner"}]
        },
        {
            "q": "3",
            "type": "multi",
            "globalQuestion": "Êtes-vous d'accord avec les déclarations suivantes ?",
            "question": [
              {
                "text": "Les couples homosexuels peuvent être aussi bons parents que n'importe quel autre couple",
                "id": "D081"
              },
              {
                "text": "Avoir des enfants est un devoir envers la société",
                "id": "D026_03"
              },
              {
                "text": "Il est du devoir d'un enfant de s'occuper de parents malades",
                "id": "D026_05"
              },
              {
                "text": "Les personnes qui ne travaillent pas deviennent paresseuses",
                "id": "C038"
              },
              {
                "text": "Le travail est un devoir envers la société",
                "id": "C039"
              },
              {
                "text": "Le travail devrait passer en premier, même si cela signifie moins de temps libre",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "Tout à fait d'accord" },
              { "id": 2, "text": "D'accord" },
              { "id": 3, "text": "Neutre" },
              { "id": 4, "text": "En désaccord" },
              { "id": 5, "text": "Tout à fait en désaccord" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "À quel point les valeurs suivantes sont-elles importantes pour vous ?",
            "question": [
              { "text": "Famille", "id": "A001" },
              { "text": "Amis", "id": "A002" },
              { "text": "Temps libre", "id": "A003" },
              { "text": "Politique", "id": "A004" },
              { "text": "Travail", "id": "A005" },
              { "text": "Religion", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "Très important" },
              { "id": 2, "text": "Assez important" },
              { "id": 3, "text": "Pas très important" },
              { "id": 4, "text": "Pas du tout important" }
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "Comment réagiriez-vous aux déclarations suivantes ? <br/>Êtes-vous d'accord ou en désaccord avec elles ?",
            "question": [
              {
                "text": "Les hommes devraient avoir plus de droits au travail que les femmes",
                "id": "C001"
              },
              {
                "text": "Les employeurs devraient préférer les citoyens du pays aux immigrants",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "D'accord"},
              {"id": 2, "text": "En désaccord"},
              {"id": 3, "text": "Aucun des choix ci-dessus"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "Êtes-vous d'accord avec les déclarations suivantes ?",
            "question": [
              {
                "text": "L'un des principaux objectifs dans la vie est de rendre ses parents fiers de moi",
                "id": "D054"
              },
              {
                "text": "Les hommes font de meilleurs leaders politiques que les femmes",
                "id": "D059"
              },
              {
                "text": "L'université est plus importante pour les garçons que pour les filles",
                "id": "D060"
              },
              {
                "text": "Les enfants en bas âge souffrent lorsque leur mère travaille",
                "id": "D061"
              },
              {
                "text": "Les hommes sont meilleurs pour gérer les entreprises que les femmes",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "Tout à fait d'accord"},
              {"id": 2, "text": "D'accord"},
              {"id": 3, "text": "En désaccord"},
              {"id": 4, "text": "Tout à fait en désaccord"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "Sélectionnez quelles actions, selon vous, peuvent toujours être justifiées, ne peuvent jamais être justifiées, ou quelque chose entre les deux en utilisant cette échelle. <br/> 1 – Jamais justifié, 10 – Toujours justifié",
            "question": [
              {
                "text": "Exiger des prestations sociales auxquelles vous n'avez pas droit",
                "id": "F114A"
              },
              {
                "text": "Éviter de payer les transports en commun",
                "id": "F115"
              },
              {
                "text": "Frauder les impôts si vous en avez l'occasion",
                "id": "F116"
              },
              {
                "text": "Accepter des pots-de-vin dans le cadre de fonctions officielles",
                "id": "F117"
              },
              {
                "text": "L'homosexualité",
                "id": "F118"
              },
              {
                "text": "La prostitution",
                "id": "F119"
              },
              {
                "text": "L'avortement",
                "id": "F120"
              },
              {
                "text": "Le divorce",
                "id": "F121"
              },
              {
                "text": "L'euthanasie",
                "id": "F122"
              },
              {
                "text": "Le suicide",
                "id": "F123"
              },
              {
                "text": "Les relations sexuelles occasionnelles",
                "id": "F132"
              },
              {
                "text": "La violence politique",
                "id": "E290"
              },
              {
                "text": "La peine de mort",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "À quel point Dieu est-il important dans votre vie ?",
            "question": [
              {
                "text": "Veuillez utiliser cette échelle pour indiquer : 10 signifie très important et 1 signifie pas du tout important.",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "Voici différentes groupes de personnes. À quel point faites-vous confiance à chaque groupe ?",
            "question": [
              {"text": "Votre famille", "id": "D001_B"},
              {"text": "Votre quartier", "id": "G007_18_B"},
              {"text": "Les gens que vous connaissez personnellement", "id": "G007_33_B"},
              {"text": "Les gens que vous rencontrez pour la première fois", "id": "G007_34_B"},
              {"text": "Les gens d'autres religions", "id": "G007_35_B"},
              {"text": "Les gens d'autres nationalités", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "Je fais entièrement confiance"},
              {"id": 2, "text": "Je fais un peu confiance"},
              {"id": 3, "text": "Je ne fais pas beaucoup confiance"},
              {"id": 4, "text": "Je ne fais pas du tout confiance"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "Ci-dessous, différents types de systèmes politiques sont décrits. Qu'en pensez-vous en tant que moyen de gouvernance ?",
            "question": [
               {
                  "text": "Présence d'un leader fort qui n'a pas besoin de se soucier du parlement et des élections",
                  "id": "E114"
               },
               {
                  "text": "Présence d'experts, plutôt que du gouvernement, prenant des décisions selon ce qu'ils estiment être le meilleur pour le pays",
                  "id": "E115"
               },
               {
                  "text": "Présence d'un système politique démocratique",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "Très bon"},
               {"id": 2, "text": "Plutôt bon"},
               {"id": 3, "text": "Plutôt mauvais"},
               {"id": 4, "text": "Très mauvais"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "À quel point est-il important pour vous de vivre dans un pays avec une gouvernance démocratique ?",
            "question": [
               {
                  "text": "Sur cette échelle, où 1 signifie « pas du tout important » et 10 signifie « absolument important », quelle position choisiriez-vous ?",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "Voici plusieurs actions entreprises par le gouvernement. Selon vous, le gouvernement a-t-il le droit de faire cela ?",
            "question": [
              {
                "text": "Maintenir les gens sous surveillance vidéo dans les lieux publics",
                "id": "H009"
              },
              {
                "text": "Surveiller tous les courriers électroniques et toutes les autres informations échangées sur Internet",
                "id": "H010"
              },
              {
                "text": "Collecter des informations sur les personnes vivant dans le pays sans qu'elles le sachent",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "Devrait certainement avoir le droit" },
              { "id": 2, "text": "Devrait probablement avoir le droit" },
              { "id": 3, "text": "Devrait probablement ne pas avoir le droit" },
              { "id": 4, "text": "Ne devrait certainement pas avoir le droit" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "Voici quelques objectifs que les gens choisissent pour leur pays au cours des dix prochaines années. Selon vous, quel objectif est le plus important ?",
            "question": [
              { "text": "Selon vous, quel objectif est le plus important ?", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "Un niveau élevé de croissance économique" },
              { "id": 2, "text": "Une armée forte" },
              { "id": 3, "text": "De bonnes conditions de travail et de communauté" },
              { "id": 4, "text": "Embelleir nos villes et nos villages" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "Voici des exemples d'organisations de bénévoles. Sélectionnez les organisations auxquelles vous appartenez.",
            "question": [
              { "text": "Organisations religieuses", "id": "A065" },
              {
                "text": "Organisations dédiées à l'éducation, aux arts, à la musique ou aux activités culturelles",
                "id": "A066"
              },
              { "text": "Syndicats", "id": "A067" },
              {
                "text": "Organisations dédiées à la préservation de l'environnement, à l'écologie et aux droits des animaux",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "Je n'appartiens à aucune organisation" },
              { "id": 1, "text": "J'appartiens à des organisations" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "Confiance envers les gens",
            "question": [{"text": "Avec quelle déclaration êtes-vous d'accord ?", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "On peut faire confiance à la plupart des gens"},
              {"id": 2, "text": "Il faut être prudent"},
              {"id": -1, "text": "Je ne sais pas"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "Voici deux déclarations que les gens font parfois sur l'environnement et la croissance économique.",
            "question": [{"text": "Laquelle vous semble la plus importante ?", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "Protéger l'environnement"},
              {"id": 2, "text": "Croissance économique et création d'emplois"},
              {"id": -1, "text": "Je ne sais pas"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "La concurrence - est-ce bon ou mauvais ?",
            "question": [{"text": "Évaluez cette déclaration, où 1 - Bon, 10 - Mauvais", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "Bon"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "Mauvais"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "Âge",
            "question": [{"text": "Votre âge ?", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15-24 ans"},
              {"id": 2, "text": "25-34 ans"},
              {"id": 3, "text": "35-44 ans"},
              {"id": 4, "text": "45-54 ans"},
              {"id": 5, "text": "55-64 ans"},
              {"id": 6, "text": "65 ans et plus"}
            ]
          }
    ]
}

export default fr;