import React from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import WVS from '../components/WVS'
import Container from '@mui/material/Container';
import LayOut from '../components/layout'
import Plot from '../components/WVS/pages/plot';
import Heatmap from '../components/WVS/pages/heatmap'
import ML from '../components/WVS/pages/ml'
import Index from '../components/WVS/pages/index'
import PageNotFound from '../components/WVS/pages/404'
import Catboost from '../components/WVS/pages/catboost'
import CookiePolicy from '../components/WVS/pages/cookiePolicy'
import Terms from '../components/WVS/pages/term'

export function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Routes() {
  return (
    <Container
      maxWidth={'md'}>
      <BrowserRouter >

      {/* <LayOut> */}
      <ScrollToTop />
        <Switch> 
          <Route exact path="/" component={Index}/>
          <Route exact path="/:lang/" component={WVS}/>
          <Route exact path="/:lang/plot" component={Plot}/>
          <Route exact path="/:lang/heatmap" component={Heatmap}/>
          <Route exact path="/:lang/ml" component={ML}/>
          <Route exact path="/:lang/catboost" component={Catboost}/>
          <Route exact path='/en/cookie' component={CookiePolicy} />
          <Route exact path='/en/terms' component={Terms} />
          <Route exact path='/en/404' component={PageNotFound} status={404}/>
          <Route exact path='*' component={PageNotFound} status={404}/>
          <Route exact path='/:lang/*' component={PageNotFound} status={404}/>
          
        </Switch>
        {/* </LayOut> */}
      </BrowserRouter>
    </Container>
  )
}