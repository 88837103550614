const zh = {
    header: {
      transale: '我们使用机器翻译。<br/>某些部分可能翻译不正确。'
    },
    footer: {
      projects: {
        title: '# 我们的项目',
        data: [
        //   {
        //     text: '合规性分析',
        //     url: 'https://awake-tools.com/zh/ca?utm_source=culture-compass'
        //   },
          {
            text: 'PWA 应用',
            url: 'https://evilunion.com/en/pwa?utm_source=culture-compass'
          },
        ]
      },
      us: {
        title: '# 关于我们',
        data: [
          {
            text: 'evilUnion',
            url: 'https://evilunion.com/en?utm_source=culture-compass'
          },
          {
            text: '反馈',
            url: 'https://t.me/evilunion_chat'
          },
          // {
          // text: 'VC Blog',
          // url: 'https://vc.ru/u/939695-evilunion?utm_source=culture-compass'
          // },
          {
            text: '使用条款',
            url: 'https://culturacompass.com/en/terms'
          },
          {
            text: '隐私政策',
            url: 'https://culturacompass.com/en/cookie'
          },
        ]
      }
    }
  };
  
  export default zh;
  