import * as types from './types';
import axios from 'axios';


export const postWVS = (state, utm) => async (dispatch) => {
  dispatch({
      type: types.POST_WVS_WAIT,
  });
  try {
      const {data} = await axios.post(`/api/v1/wvs/`, {
        state: state,
        utm: utm,
        time: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      dispatch({
          type: types.POST_WVS_SUCCESS,
          payload: data,
      })
  } catch (e) {
      dispatch({
          type: types.POST_WVS_ERROR,
          payload: e,
      })
  }
};


export const postWVSExplain = (state) => async (dispatch) => {
  dispatch({
      type: types.POST_WVS_EXPLAIN_WAIT,
  });
  try {
      const {data} = await axios.post(`/api/v1/wvs-explain/`, state );
      dispatch({
          type: types.POST_WVS_EXPLAIN_SUCCESS,
          payload: data,
      })
  } catch (e) {
      dispatch({
          type: types.POST_WVS_EXPLAIN_ERROR,
          payload: e,
      })
  }
};