import React from "react";
import { styled, Button } from '@mui/material';
import Questions from './q'
import TransaleCountry from './translateCountry.json'
import ShareButton from './shareButton'
import { ReactComponent as Logo } from '../layout/svg/Name.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CMS from './cms'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import * as actions from '../../actions/vws';


const WraperRoot = styled('div')({
    marginTop: 40,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
})


const WraperAnswer = styled('div')({
    width: '100%',
    "@media(max-width: 767px)": {
        width: '100%'
    },
    border: '1px solid rgba(39, 21, 92, 0.1)',
    padding: 40,
    borderRadius: 40,
    marginBottom: 40,
    backgroundColor: '#1D1D1D',
})


const MenuCountry = styled('div')({
    display: 'flex',
    flexDirection: 'row',   
})

const Columns = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
    alignItems: 'flex-start'
})

const ItemWraper = styled('div')({
    marginTop: 10
})

const Answer = styled('div')({
    margin: 10,
    color: '#c2c2d1',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 600
})


const SubTitile = styled('div')({
    fontSize: 24,
    marginBottom: 10,
    color: '#f0f0f5',
})


const ResultButtom = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    "@media(max-width: 767px)": {
        flexWrap: 'wrap',
    }
})

const TextButtom = styled('div')({
    color: '#c2c2d1',
    fontSize: 12,
    marginBottom: 20,
    lineHeight: '140%'
})

const WraperLogo = styled(Logo)({
    marginTop: 20
})

const BI = styled(FiberManualRecordIcon)({
    color: '#10a343e8',
    width: 8,
    height: 8
})

const PointRow = styled('span')({
    marginLeft: 5
})


function get_questions_text(data) {
    // console.log(data)
    var questions = {}
    data.forEach(item => {   
            item.question.forEach(q => {
                    if(item.question.length > 1) {
                        questions[q.id] = q.text
                    } else {
                        questions[q.id] = item.globalQuestion
                    }
                }
            )

    }
    )

    return questions
}


function PointScore({score}) {
    switch (true) {
        case (score > 0.8): return <PointRow><BI/><BI/><BI/><BI/><BI/></PointRow>
        case (score > 0.6): return <PointRow><BI/><BI/><BI/><BI/></PointRow>
        case (score > 0.5): return <PointRow><BI/><BI/><BI/></PointRow>
        case (score > 0.4): return <PointRow><BI/><BI/></PointRow>
        case (score > 0.2): return <PointRow><BI/></PointRow>
        default: return <PointRow><BI/></PointRow>
}}

const buttonLang = {
  "ru": "Получить топ 5 ответов, которые объединяют вас с жителями этих стран",
  "en": "Get the top 5 answers that connect you with the residents of these countries",
  "zh": "获取与这些国家居民连接的前5个答案",
  "pt": "Obtenha as 5 melhores respostas que o conectam aos residentes destes países",
  "ja": "これらの国の住民と結びつくトップ5の回答を取得します",
  "ar": "احصل على أفضل 5 إجابات تربطك بسكان هذه البلدان",
  "hi": "उन देशों के निवासियों से जुड़ने वाले शीर्ष 5 उत्तर प्राप्त करें",
  "fr": "Obtenez les 5 meilleures réponses qui vous relient aux habitants de ces pays",
  "de": "Holen Sie sich die 5 besten Antworten, die Sie mit den Bewohnern dieser Länder verbinden",
  "es": "Obtén las 5 mejores respuestas que te conectan con los residentes de estos países"
}

const CardPannel = ({explain, lang, CMS}) => {
  try {
    const explain_keys = Object.keys(explain)
    const qestions = Questions.filter(item => item.lang === lang)[0]['data'].filter(item => item.type !== 'ads').filter(item => item.type !== 'adsGoogle')
    const questions_text = get_questions_text(qestions)

    const [country, setCountry] = React.useState(explain_keys[0]);
    const explain_data = JSON.parse(explain[country])
    const pannel = CMS[`${lang}`].pannel

    return(
        <>
        {JSON.parse(explain[country]) !== undefined ? 
                <WraperRoot>
                    <WraperAnswer>
                        <SubTitile dir={lang !== 'ar' ? "ltl" : "rtl"}>{pannel.SubTitile}</SubTitile>
                        <TextButtom dir={lang !== 'ar' ? "ltl" : "rtl"} dangerouslySetInnerHTML={{__html: pannel.TextButtom}}>
                            
                        </TextButtom>
                        <Columns>
                                {
                                    explain_keys.map(item => 
                                        <MenuCountry key={item} id='MenuCountry' onClick={() => setCountry(item)}>
                                            <Button variant={country === item ? 'special' : 'outlined'} >{TransaleCountry[lang][item]}</Button>

                                        </MenuCountry>
                                    )
                                }
                            
                        </Columns>     

                            <ItemWraper>
                                    {Object.keys(explain_data).map(id => 
                                        <Answer key={id} dir={lang !== 'ar' ? "ltl" : "rtl"}>
                                            <div>• {questions_text[id]} <PointScore score={explain_data[id]}/> </div>
                                            
                                            
                                        </Answer>
                                    )}
                            </ItemWraper>           
                        
                        
                        <ResultButtom>
                                <ShareButton 
                                    shareUrl={`https://culturacompass.com/${lang}`}
                                    title = {pannel.ShareButton}
                                    call={pannel.call}
                                />
                                <a href="https://evilunion.com/en?utm_source=culture-compass" target="_blank">
                                    <WraperLogo/>
                                </a>
                                
                        </ResultButtom>

                    </WraperAnswer>
                </WraperRoot>
            :
                <div/>
        }
        </>
    )
} catch (e) {
    console.log(e)
    return <div></div>
  }
}



function Pannel({explain, lang, postWVSExplain, data, explainWaint}) {
  const onClick = () => {
    postWVSExplain({
      'state': data['data']['state'],
      'country_numbers': Object.values(JSON.parse(data['result'])['cntry'])
    })
  } 

  if (!explain) {
    return (
      <WraperRoot dir={lang !== 'ar' ? "ltl" : "rtl"}>
        <WraperAnswer>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button variant='outlined' onClick={() => onClick()}>
                {buttonLang[lang]}
            </Button>
          </div>
        </WraperAnswer>
      </WraperRoot>
      // <div/>
    )
  }

  if (explainWaint) {
    return <div>wait...</div>
  }

  return <CardPannel explain={explain} lang={lang} CMS={CMS} />
}

const mapStateToProps = state => state.wvs;
const mapDispatchToProps = dispatch => bindActionCreators({
    ...actions,
    push,
}, dispatch);

export default connect(
    mapStateToProps, mapDispatchToProps,
)(Pannel)