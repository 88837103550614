import React from 'react';
import Card from '../card';
import Container from '../contaner';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  container: {
    overflowY: 'scroll',
  },
  width100: { width: '100%' },
}));

export default function RowCards({ items, sm = 4, title = 'More from evilUnion' }) {
  const classes = useStyles();
  return (
    <Container>
      <Typography className={classes.title} variant={'h4'}>
        {title}
      </Typography>
      <Grid container spacing={2} className={classes.container}>
        {items.map((item, i) => (
          <Grid key={i} item sm={sm} className={classes.width100}>
            <Card props={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
