import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import LayOut from "../../layout";


const WraperText = styled('div')({
    color: '#c2c2d1',
    marginBottom: 40,
})


export default function Heatmap() {
    return(
        <LayOut>
        <WraperText>
            <h1>Cookie Policy</h1>
            <p>Last updated: 7.08.23</p>
            <p>https://culturacompass.com/ ("us", "we", or "our") uses cookies on https://culturacompass.com/ (the "Service"). By using the Service, you consent to the use of cookies.</p>

            <h2>What are cookies?</h2>
            <p>Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</p>

            <h2>How we use cookies</h2>
            <p>When you use and access the Service, we may place several cookies files in your web browser.</p>
            <p>We use cookies for the following purposes:</p>
            <ul>
                <li>To enable certain functions of the Service</li>
                <li>To provide analytics</li>
                <li>To store your preferences</li>
                <li>To enable advertisements delivery, including behavioral advertising</li>
                <li>To comply with legal obligations</li>
            </ul>

            <h2>Third-party cookies</h2>
            <p>In addition to our cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</p>

            <h2>What are your choices regarding cookies?</h2>
            <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>
            <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Cookie Policy, please contact us:</p>
            <p>By telegram: https://t.me/evilunion_chat</p>
        </WraperText>
        </LayOut>
    )
}







