const zh = {
    seo: {
        title: '技术细节',
        description: '我们做出的主要简化是将梯度提升称为神经网络。由于社会上对神经网络有明确的熟悉度，我们决定不浪费时间解释提升是什么，因为没有人关心。',
        url: '/ml',
        lang: 'zh'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>技术细节</h1>"
        },
        {
            "type": "text-html",
            "html": `
            我们做出的主要简化是将梯度提升称为神经网络。由于社会上对神经网络有明确的熟悉度，我们决定不浪费时间解释提升是什么，因为没有人关心。
            <br/> <br/>
                因此，我们使用了大家喜爱的 Yandex CatBoost。因为它工作速度快，易于训练。
            <br/> <br/>
                loss_function - MultiClass <br/>
                eval_metric - TotalF1 <br/>
                test_size - 40% <br/>
            <br/> <br/>
                最终的 Total F1 得分在测试集上为 64%。请注意，这是多类别！我们预测的不是布尔变量，而是类别，总共有 80 个类别。我们决定从数据中删除了 10 个国家，因为它们的调查对象少于 1000 人。土耳其在问题上进行了重大变更，所以我们只是将其从数据中删除了。
            <br/> <br/>
                虽然可能进一步提高 Total F1，但这将需要更多时间。原则上，如果我们提供了所有问题，我们会立即达到 92% 的准确率！某些问题可能对模型来说是提示，但我认为没有继续改进的必要。此外，不希望问卷过长。无论如何，人们根据生活价值观进行分组。
            <br/> <br/>
            <h2>混淆矩阵</h2>
                这里有一个有趣的东西。我们可以看到模型最常混淆的国家是哪些。但您还可以重新提问，问哪些国家彼此最相似，因为模型混淆了他们的公民。
            <br/> <br/>
                这里是一些国家的示例。每个表表示按国家筛选数据。以及模型对测试集中所有国家的总体评估。例如，第一个表表示测试集中来自俄罗斯的调查对象以及模型根据他们的回答得出的答案。
            <br/> <br/>
            `
        },
        {
            "type": "img",
            "href": "/images/error_country.png",
            "alt": "错误国家示例"
        },
    ]
}

export default zh;
