const es = {
    "data": [
        {
            "type": "text-html",
            "html": "<h1>Análisis</h1>"
        },
        {
            "type": "text-html",
            "html": "A continuación, me gustaría compartir hallazgos interesantes de estos datos. <br/><br/> Su respuesta fue analizada por una red neuronal que estudió 140,000 respuestas de encuestados en una encuesta sociológica llamada <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>World Values Survey</a>. La elegancia y singularidad de este enfoque radica en que la red neuronal carece de conciencia y sesgos psicológicos. No asistió a la escuela, no miró TikToks ni televisión. Puede contener muchas variables en su mente, lo que hace que sus hallazgos sean muy honestos.  <br/><br/> <i>Por supuesto, las preguntas en esta prueba no pueden revelar todas las peculiaridades culturales de los países, pero descubren los valores fundamentales de la vida en nuestro planeta compartido.</i> <br/><br/> Utilizando ciertos métodos matemáticos, podemos construir un mapa de países, indicando cómo se agrupan entre sí. En este caso, obtuvimos 6 grupos principales."
        },
        {
            "type": "img",
            "href": "/images/country_table.png",
            "alt": "World Values Survey"
        },
        {
            "type": "text-html",
            "html": "Países occidentales, Asia, países postsoviéticos, América Latina y países musulmanes. Cuanto más cercanos estén los puntos entre sí, más similares serán las respuestas de estos países. Los ejes de coordenadas son abstractos ya que consideran todas las respuestas a la vez. Es probable que su país de la respuesta pertenezca a uno de estos grupos. La razón de la disposición de los países se explica a continuación. <br/><br/> Mostramos las probabilidades de que sus respuestas provengan de diferentes países. Tomamos los 5 países principales. Si su resultado no se alinea claramente con un país específico, significa que sus respuestas son únicas. Una probabilidad fuertemente alineada debería ser mayor que ~50%. En este momento, recibimos respuestas diversas, y hay casos en los que un país puede tener una probabilidad del 96%."
        },
        {
            "type": "pannel"
        },
        {
            "type": "text-html",
            "html": "También puede hacer clic en un país en los resultados de la prueba para cargar las respuestas promedio de ese país. Aparecerán junto a sus respuestas a las preguntas. <br><br/><i>Tenga en cuenta que las opciones de respuesta están numeradas a partir de 1. Además, las personas en los países pueden responder de manera diferente a las preguntas. Por ejemplo, la mitad de los encuestados pueden elegir una respuesta, mientras que el otro grupo selecciona la opción opuesta. En estos casos, calcular el valor promedio no sería apropiado. Nuestra red neuronal tiene en cuenta estas situaciones porque se basa en el método de bosques aleatorios.</i><br/><br/>"
        },
        {
            "type": "text-html",
            "html": "<h2>Preguntas Clave</h2> Durante el proceso de entrenamiento, dividimos los datos en dos partes: una para pruebas y otra para entrenamiento. El algoritmo aprendió de los datos de entrenamiento y evaluó su rendimiento en los datos de prueba, que no formaron parte del entrenamiento. Este brillante método, desarrollado por matemáticos en el siglo pasado, forma la base del aprendizaje automático. Hubo aproximadamente 2000 iteraciones de entrenamiento hasta que encontramos el modelo más efectivo para describir los datos. Si está interesado en más detalles profesionales, puede visitar <a href='/es/ml/' target='_blank'>esta página</a>. Para una explicación básica, puede consultar <a href='/es/catboost/' target='_blank'>este enlace</a>.<br/><br/><h3>Después de que nuestro algoritmo aprendió, le preguntamos cuáles fueron las preguntas más significativas.</h3><br/>Aquí están las 15 preguntas principales:<ul><li>Justificable: Homosexualidad</li><li>¿Qué tan importante es Dios en tu vida?</li><li>Las parejas del mismo sexo pueden ser tan buenos padres como otras parejas</li><li>Deber de un hijo de cuidar a un padre enfermo</li><li>Sistema político: Presencia de un líder fuerte</li><li>Tener hijos, un deber hacia la sociedad</li><li>Justificable: Pena de muerte</li><li>Cualidades infantiles importantes: Diligencia</li><li>Justificable: Aborto</li><li>Vecinos: Homosexuales</li><li>Justificación: Sexo casual</li><li>Justificable: Rechazar pagar el transporte público</li><li>El trabajo debe ser una prioridad, incluso si significa menos tiempo libre</li><li>Sistema político: Decisiones tomadas por expertos</li><li>Pertenencia a una organización religiosa</li></ul><br/><h3>Se puede decir que estas preguntas contribuyen a dividir las sociedades en la actualidad. A través de este proyecto, buscamos aprender quiénes son las personas, cómo difieren y cómo podemos comprenderlas mejor.</h3>"
        }
    ],
    "pannel": {
        "SubTitile": "Top 5 respuestas que lo conectan con los habitantes de estos países",
        "TextButtom": "<i>Su combinación de respuestas a estas preguntas lo hace similar a este grupo. <br/> Los puntos verdes indican el peso de esta pregunta y su respuesta a ella. <br/> Su respuesta puede ser positiva o negativa.</i>",
        "ShareButton": "Descubra a qué habitantes de qué país se parece usted.",
        "call": "¡Comparta con amigos!"
    },
    "final": {
        "SubTitile": "Su respuesta es similar a las respuestas de las personas de los siguientes países ",
        "whyAnswer": "¿Por qué esta respuesta?",
        "ShareButton": ["Descubra a qué habitantes de qué país se parece usted.\nSoy ", "% similar a los habitantes de"],
        "call": "¡Comparta con amigos!"
    }
}

export default es;