import * as React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Modal, Slide, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import TranslateIcon from '@mui/icons-material/Translate';
import { useLocation } from 'react-router-dom';
import CMS from '../lang'
import { ReactComponent as Logo } from '../svg/Name.svg';


const useStyles = makeStyles((theme) => ({
  modal: {
    marginLeft: 'calc(100vw - 580px)',
    width: 500,
    height: '100vh',
    overflowY: 'scroll',
    background: '#131313',
    padding: 39,
    borderRadius: '40px 0px 40px 0px',
    [theme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      marginLeft: 0,
      width: '80%',
      borderRadius: '0px 40px 40px 0px',
    },
  },
  itemMenu: {
    marginRight: 10,
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
  },
  appButton: {
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  plot: {
    marginTop: 0

  },
  logo: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '72px',
    color: 'white',
  },
  head: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  apps: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    alignContent: 'flex-start'
  },
  CloseIcon: {
    padding: 5,
    border: '1px solid rgba(255,255,255, 0.9)',
    borderRadius: '50px',
    color: 'white'
  },
  translate: {
    marginTop: 33
  },
  body: {
    display: 'flex',
    height: '75%',
    flexWrap: 'nowrap',
    alignContent: 'space-between',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      // height: '84%',
    },
  },
  logo2: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 30
    }
  }
}));

export default function SuperMenu({ menuData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const lang = location.pathname.split('/')[1]
  const text = CMS[`${lang}`]

  return (
    <>
      <Button 
        onClick={() => setOpen(!open)} 
        className={classes.plot} 
        variant='outlined'
        style={{marginTop: 0}} >
        <TranslateIcon />
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Slide direction={document.body.clientWidth < 600 ? 'right' : 'left' } in={open} mountOnEnter unmountOnExit>
          <div className={classes.modal} dir={lang !== 'ar' ? "ltl" : "rtl"}>
            <div className={classes.head}>
              <div>
                <div className={classes.logo}>{menuData.filter(item => item.url === lang)[0].languages}</div>
                <Typography variant="body1">Languages</Typography>
              </div>
              {/* <CloseIcon className={classes.CloseIcon} onClick={() => setOpen(false)} /> */}
            </div>
            <div className={classes.body}>
              <div>
                <div className={classes.apps}>
                  {menuData.map((item) =>
                    item.url.includes('https') ? (
                      <a key={item.text} href={item.url} target="_blank" rel="noreferrer">
                        <Button
                          onClick={() => setOpen(false)}
                          variant="outlined"
                          color={'info'}
                          className={classes.itemMenu}
                        >
                          {item.text}
                        </Button>
                      </a>
                    ) : (
                      <Link key={item.text} to={'/' + item.url + '/'} >
                        <Button
                          onClick={() => setOpen(false)}
                          variant={lang !== item.url ? "outlined" : 'special'}
                          color={'info'}
                          className={classes.itemMenu}
                        >
                          {item.text}
                        </Button>
                      </Link>
                    ),
                  )}
                </div>
                <div className={classes.translate}>
                  <Typography 
                    variant="body2"
                    dir={lang !== 'ar' ? "ltl" : "rtl"}
                    dangerouslySetInnerHTML={{__html: `${text.header.transale}`}}
                  >         
                  </Typography>
                </div>
              </div>
              <a href='https://evilunion.com/en' target="_blank" rel="noreferrer" className={classes.logo2}>
                <Typography variant='body1'>made by</Typography>
                <Logo/>
              </a>
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  );
}
