import React from 'react';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    overflow: 'hidden',
    borderRadius: 44,
    WebkitBackfaceVisibility: 'hidden',
    MozBackfaceVisibility: 'hidden',
    WebkitTransform: 'translate3d(0, 0, 0)',
    MozTransform: 'translate3d(0, 0, 0)',
    cursor: "pointer",
    boxSizing: "border-box",
    width: '100%',
    height: "37.5vw",
    padding: "1.71vw",
    "@media(min-width: 1280px)": {
      width: '100%;',
      height: "350px",
      padding: "22px",
    },
    "@media(max-width: 1024px)": {
      width: "80vw",
      height: "48.87vw",
      padding: "2.14vw",
    },
    "@media(max-width: 767px)": {
      width: '80vw',
      height: '50vh',
      padding: "5.31vw",
      borderRadius: '10vw',
    },
    '&:hover': {
      '& .img--project': {
        transform: 'scale(1.1)',
      },
    },
    '& .img--project': {
      transition: '.2s ease all',
      transform: 'scale(1)',
    },

    
    },
    wrapperImg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      objectFit: "cover",
      "& img": {
        objectFit: "cover",
      },
    },


}))


export default function Card({props}) {
  const classes = useStyles();
  return(
    (
      <Grid
        container
        justify="flex-start"
        alignContent="flex-end"
        className={classes.wrapper}
      >
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          <img
            src={props.img}
            alt={props.text}
            className={`${classes.wrapperImg} img--project`}
            loading="lazy"
          />
          <div 
            style={{background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%)'}}
            className={`${classes.wrapperImg} img--project`}
          ></div>
          <Typography
            variant={'h6'}
            className={classes.description}>
              {props.text}
          </Typography>
        </a>
      </Grid>
    )
  



    // <div className={classes.root}>
    //     <img className={classes.wrapperImg} src='/images/cat.jpeg'/>


    //     <Typography>{props.text}</Typography>
    // </div>
  )
}