const ru = {
    data: [
        {
            type: 'text-html',
            html: '<h1>Анализ</h1>',
        },
        {
            type: 'text-html',
            html: `Далее хотелось бы поделиться интересными выводами из этих данных. <br/><br/>

            Ваш ответ проанализировала нейронная сеть, которая изучила 140 000 ответов респондентов из социологического исследования <a href="https://worldvaluessurvey.org/wvs.jsp" target="_blank"> World Values Survey</a>. Изящность и отличие данного подхода от других заключается в том, что у нейронной сети нет сознания и она лишена каких-либо психологических искажений. Она не училась в школе, не смотрела тик-токи и телевизор. Она может держать в свой голове очень много переменных, поэтому ее выводы могут быть очень честными.  <br/><br/>

            <i>Конечно, вопросы в данном тесте не могут раскрыть все культурные особенности стран. Но они выявляют основные жизненные ценности на нашей общей планете.</i> <br/><br/>

            Используя определенные математические методы, мы можем построить карту стран, которая покажет, как страны группируются между собой. В данном случае, у нас получилось 6 больших группировок.  

            `,
        },
        {
            type: 'img',
            href: '/images/country_table.png',
            alt: 'World Values Survey'
        },
        {
            type: 'text-html',
            html: `
                Западные страны, Азия, постсоветские страны, Латинская Америка, мусульманские страны. Чем ближе друг другу находятся точки, тем больше ответы из этих стран похожи. Оси координат абстрактны, так как учитывают все ответы сразу. Скорее всего, ваши страны из ответа находятся в каком-то из этих кластеров. Почему страны так расположились, ответ чуть ниже. 
                <br/><br/>
                Мы показываем вероятность, из какой страны ваши ответы на вопросы. Мы берем топ 5 стран. Если в вашем результате нет ярко выраженной страны, это означает, что ваши ответы на вопросы уникальны. Ярко выраженная вероятность должна быть больше ~50 %.<br/> 
                На данный момент мы получаем много разных ответов и попадаются результаты, когда одна страна может набрать 96%. 
            `
        },
        {
            type: 'pannel'
        },
        // {
        //     type: 'text-html',
        //     html: `
        //             Мы также создали таблицу heatmap и график plot, при помощи которых можно самостоятельно посмотреть средние ответы на вопросы по странам. <br/> <a href="/ru/heatmap" target="_blank"> Heatmap</a>, <a href="/ru/plot" target="_blank"> Scatter Plot </a>
        //     `
        // },
        // {
        //     type: 'img',
        //     href: '/images/heatmap.png',
        // },
        {
            type: 'text-html',
            html: `
                Вы так же можете нажать на страну в результатах теста и подгрузяться средние ответы по этой стране. Они появиться рядом с ответами на вопросы. <br><br>
                
                <i>Обращаем внимание, что варианты ответов пронумерованы от 1. А также, что люди в странах могут отвечать по разному на вопросы. Например, половина респондентов может выбрать один ответ, другая группа противоположный вариант. Среднее значениe в таком случаи будет не правильно изучать. Наша нейроная сеть, учитывает такие ситуайции, потому что в основе ее лежит метод случайных лесов. </i>
            
                <br/><br/>
            `
        },
        {
            type: 'text-html',
            html: `
                <h2>Ключевые вопросы</h2>
                При обучении мы разделили данные на две части. Одна часть - тестовая, другая часть - тренировочная. Алгоритм изучал тренировочную часть, а проверял свои ответы на тестовой части, которая не участвовала в обучении. Этот гениальный метод придумали математики в прошлом веке и на нем базируется машинное обучение. Было около 2000 тренировок, пока не была найдена самая эффективная модель описывающая данные. Если вам интересно больше профессиональных потребностей то, вам <a href="/ru/ml/" target="_blank">сюда</a>. А если вам нужно базовое обьяснение, то оно <a href="/ru/catboost/" target="_blank">тут</a>.
                <br/><br/>

                <h3>После того как наш алгоритм обучился, мы спросили его – а какие вопросы были самыми значимыми? </h3><br/>
                Вот топ 15 вопросов

                <ul>
                    <li>Оправданно: гомосексуальность</li>
                    <li>Насколько важен Бог в вашей жизни</li>
                    <li>Гомосексуальные пары могут быть такими же хорошими родителями, как и любые другие пары</li>
                    <li>Обязанность ребенка заботиться о больном родителе</li>
                    <li>Политическая система: Наличие сильного лидера</li>
                    <li>Иметь детей - долг перед обществом</li>
                    <li>Оправданно: Смертная казнь</li>
                    <li>Важные детские качества: трудолюбие</li>
                    <li>Оправданно: аборт</li>
                    <li>Соседи: гомосексуалы</li>
                    <li>Оправдание: случайный секс</li>
                    <li>Обоснованно: отказ от платы за проезд в общественном транспорте</li>
                    <li>Работа должна быть на первом месте, даже если это означает меньше свободного времени</li>
                    <li>Политическая система: решения принимаются экспертами</li>
                    <li>Принадлежность к религиозной организации</li>
                </ul> <br/>
               

                <h2>
                    Можно сказать, что вот такие вопросы позволяют разделять общества сегодня. <br/> Данным проектом мы стремимся узнать какие люди есть, чем они отличаются и как можно их понять лучше. 
                </h2>
            `
        },
        {
            type: 'text-html',
            html: `
                <h2>Проекты нашей компании evilUnion</h2> 
                Мы имеем много необычных решений и предложений для IT и Маркетинга. Посмотрите их! 

                <ul>
                    <a href="https://evilunion.com/pwa/?utm_source=culture-compass" target="_blank"><li>PWA-приложения</li></a>
                    <a href="https://evilunion.com/base-it-mini-kurs/?utm_source=culture-compass" target="_blank"><li>База IT за час для бизнеса. Мини-курс</li></a>
                    <a href="https://evilunion.com/gatsby-js/?utm_source=culture-compass" target="_blank"><li>Реактивные сайты
                    на Gatsby</li></a>
                    <a href="https://awake-tools.com/ru/ca/?utm_source=culture-compass" target="_blank"><li>Анализ соответствий</li></a>
                    <a href="https://awake-tools.com/ru/ads-funnel/?utm_source=culture-compass" target="_blank"><li>Сценарная воронка продаж</li></a>
                    <a href="https://evilunion.com/%D1%81%D1%80%D0%B0%D0%B2%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D1%81%D0%BA%D0%BE%D1%80%D0%BE%D1%81%D1%82%D0%B8-%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B8-%D1%81%D0%B0%D0%B8%D1%82%D0%BE%D0%B2-%D1%81%D0%B4%D0%B5%D0%BB%D0%B0%D0%BD%D0%BD%D1%8B%D1%85-%D0%BD%D0%B0-bitrix-wordpress-gatsby-tilda/?utm_source=culture-compass" target="_blank"><li> Как меняются интересы женщин со временем. Узнаем при помощи данных из социальных сетей</li></a>
                    <a href="https://evilunion.com/marketing-services/?utm_source=culture-compass" target="_blank"><li>Аутсорс отдела маркетинга </li></a>
                    <a href="https://vc.ru/u/939695-evilunion" target="_blank"><li>Сложный блог на VC про маркетинг и IT</li></a>
                    <a href="https://stepik.org/course/144523/info" target="_blank"><li>Практический курс по digital-дизайну</li></a>
                    <a href="https://evilunion.com/marketing-services/?utm_source=culture-compass" target="_blank"><li>Предсказание конверсий</li></a>
                    <a href="https://t.me/evil_union" target="_blank"><li>Телеграм-канал</li></a>
                    <a href="https://vk.com/evil.union" target="_blank"><li>VK</li></a>
                </ul>
            `
        }
    ],
    pannel: {
        SubTitile: 'Топ 5 ответов, которые объединяют вас с жителями этих стран',
        TextButtom: '<i>Ваша комбинация ответов на эти вопросы, делает вас похожими на эту группу. <br/> Зеленые точки показывают вес этого вопроса и вашего ответа на него. <br/> Ваш ответ мог быть отрицательным и положительным.</i>',
        ShareButton: 'Узнайте, на жителя какой страны, вы похожи.',
        call: 'Поделитесь с друзьями!'
    },
    final: {
        SubTitile: 'Ваш ответ похож на ответы людей из следующих стран',
        whyAnswer: 'Почему такой ответ?',
        ShareButton: ['Узнайте, на жителя какой страны, вы похожи. \n Я на ', '% похож на жителя'],
        call: 'Поделитесь с друзьями!'

    },
}

export default ru;