import React from 'react';
import { styled, Button } from '@mui/material';
import CMS from './cms'
import PageBuilder from '../lib/pageBuilder';
import ShareButton from './shareButton'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ReactComponent as Logo } from '../layout/svg/logo.svg'
import TransaleCountry from './translateCountry.json'


const WraperRoot = styled('div')({
    marginTop: 40,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
})


const WraperRow = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    "@media(max-width: 767px)": {
        justifyContent: 'space-between',
    }
})

const WraperItem = styled('div')({
    display: 'flex',
    paddingRight: 20,
    fontSize: 42,
    cursor: 'pointer',
    color:'#1986EC',
    // marginBottom: 10,
    "@media(max-width: 767px)": {
        fontSize: 32,
    }
})

const WraperAnswer = styled('div')({
    width: '100%',
    "@media(max-width: 767px)": {
        width: '100%'
    },
    border: '1px solid rgba(39, 21, 92, 0.1)',
    padding: 40,
    borderRadius: 40,
    marginBottom: 20,
    backgroundColor: '#1D1D1D'
})

const SubTitile = styled('div')({
    fontSize: 24,
    marginBottom: 20,
    color: '#c2c2d1',
})

const ResultButtom = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    "@media(max-width: 767px)": {
        flexWrap: 'wrap',
    }
})

const WraperLogo = styled(Logo)({
    // marginTop: 10,
    "@media(max-width: 767px)": {
        display: 'none'
    }
})


export default function Final({result, setCountryAnswer, explain, lang}) {
    const country = Object.keys(result['predict_proba'])
    const final = CMS[`${lang}`].final

    try {
      window.dataLayer.push({ "event": "result-show" })
    } catch (e) {}


    return(
        <WraperRoot dir={lang !== 'ar' ? "ltl" : "rtl"}>
            <WraperAnswer>
                <SubTitile>{final.SubTitile} &#128540;</SubTitile>         
                {country.map(item =>
                    <WraperRow
                        key={item}
                    >
                        <AnchorLink href='#test'  offset='50'>
                            <WraperItem  onClick={() => setCountryAnswer(item)} >{TransaleCountry[lang][item]}</WraperItem>
                        </AnchorLink>
                        <WraperItem>{Math.round(result['predict_proba'][item]*100, 2)}%</WraperItem>
                        {/* <WraperItem>&{result['flag'][item]};</WraperItem> */}
                    </WraperRow>  
                )}

                {/* <AnchorLink href='#analytics'>
                    <Button id='analytics' variant='outlined' size="large">{final.whyAnswer}</Button>
                </AnchorLink> */}

                

                <ResultButtom>
                    <ShareButton 
                        lang={lang}
                        call={final.call}
                        shareUrl='https://culturacompass.com/'
                        title = {`${final.whyAnswer[0]} ${Math.round(result['predict_proba'][country[0]]*100, 2)} ${final.whyAnswer[1]} ${TransaleCountry[lang][country[0]]}.`}
                    />
                    <WraperLogo/>
                </ResultButtom>

            </WraperAnswer>


            <div id='after result' style={{width: '100%'}}>
            <section id='analytics'></section>
                {CMS[`${lang}`].data.map((item, i) => (
                        <PageBuilder key={i} item={item} explain={explain} lang={lang}/>
                    ))}
            </div>
        </WraperRoot>
    )
}