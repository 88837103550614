import React from 'react';
import {Checkbox, styled, Typography, Button, LinearProgress, Box} from '@mui/material';
import RadioGroup from './RadioGroup'
import theme from "../../theme";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ADS from '../lib/ads';
import ADSGoogle from '../lib/adsGoogle';

const Card = styled('div')({
    marginTop: 20, 
    marginBottom: 20, 
    // border: '1px solid rgb(242 242 242 / 0%);',
    padding: 40,
    borderRadius: 40,
    backgroundColor: '#1D1D1D'
})

const Row = styled('div')({
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: 10,
    gridTemplateColumns: '250px auto',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '140px auto',
    }
})

const Body = styled('div')({
    overflow: 'auto',
})

const Check = styled('div')({
    width: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const GlobalQuestion = styled(Typography)({
    width: 600,
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20,
    fontSize: '20px !important',
    lineHeight: '140%',
    [theme.breakpoints.down('md')]: {
        width: '100%'
      },
})


const Question = styled('p')({
    width: 250,
    fontSize: 14,
    color: '#c2c2d1',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
        width: 250,
        marginRight: 10
      },
})

const ColumnHead = styled('p')({
    width: 50,
    display: 'flex',
    fontSize: 12,
    paddingLeft: 10,
    color: '#c2c2d1',
})

const RadioGroupWraper = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: 500,
})

const HeadDivner = styled('div')({
    paddingLeft: 150
})


const AnswerWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center'
})

const AnswerFromCountry = styled('div')({
    fontSize: 10,
    color: '#6e6e73',
})

const ButtonsWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-end'
})


export default function Form({item, sendDataState, state, answers, buttons, progress, setProgress, lenQ, PrepareData, lang}) {
    const sendCheckdBox = (question, answer) => {
        if (state[question.id] === 1) {
            sendDataState(2, question.id )
        } else {
            sendDataState(answer.id, question.id )
        }
    }

    const AnswerVariant = ({question, item, }) => {
        switch(item.type) {
            case 'one':
                return <>
                            {
                                item.answers.map(answer =>
                                    <Check key={answer.text}>
                                        <Checkbox
                                            checked={state[question.id] === 1}
                                            onClick={() => sendCheckdBox(question, answer )}/>
                                    </Check>
                                )
                            }
                        </>
    
            case 'multi':
                return <RadioGroup item={item} sendDataState={sendDataState} question={question} state={state}/>
            default: 
                return <div>error</div>
        } 
    }

    const ButtonBox = () => {
        return (
            <ButtonsWrapper>
                {
                    progress !== 0 ? 
                        <AnchorLink href='#card'>
                            <Button 
                            variant='outlined' 
                            dir={lang !== 'ar' ? "ltl" : "rtl"}
                            onClick={() => setProgress(progress-1)}>
                                {buttons.back}
                            </Button>
                        </AnchorLink>
                    :
                        <div/>
                }
                {
                    lenQ - 1 !== progress ?
                    <>
                        <AnchorLink href='#card'>
                            <Button
                                variant='outlined' 
                                onClick={() => setProgress(progress+1)}
                                dir={lang !== 'ar' ? "ltl" : "rtl"}
                            >
                                {buttons.next}
                            </Button>
                        </AnchorLink>
                    </>
                    :
                    <AnchorLink href='#answer'>
                        <Button
                            id='get_result' 
                            variant='special'
                            dir={lang !== 'ar' ? "ltl" : "rtl"}
                            onClick={() => PrepareData()}
                        >
                            {buttons.send}
                        </Button>
                    </AnchorLink>
                }
            </ButtonsWrapper>
        )
    }

    try {
        if (item.type === 'ads') {
          return <Card>
                      <Box sx={{ width: '100%', transform: 'translate(-0px, -1000%)', borderRadius: 100}}>
                              <LinearProgress variant="determinate" value={100/(lenQ - 1) * progress} valueBuffer={lenQ} />
                      </Box>
                          <ADS props={item}/>
                      <ButtonBox/>
              </Card>
      }
    } catch (e) {
      console.log(e)
    }


    if (item.type === 'adsGoogle') {
        return <Card>
                      <Box sx={{ width: '100%', transform: 'translate(-0px, -1000%)', borderRadius: 100}}>
                                <LinearProgress variant="determinate" value={100/(lenQ - 1) * progress} valueBuffer={lenQ} />
                        </Box>
                            <ADSGoogle/>
                      <div style={{height: 10}}/>
                      <ButtonBox/>
                </Card>
    }

    return(
        
        <Card dir={lang !== 'ar' ? "ltl" : "rtl"}>  
                <Box sx={{ width: '100%', transform: 'translate(-0px, -1000%)', borderRadius: 100}}>
                    <LinearProgress variant="determinate" value={100/(lenQ - 1) * progress} valueBuffer={lenQ} />
                </Box>
                <GlobalQuestion 
                    variant='h4' 
                    dangerouslySetInnerHTML={{__html: item['globalQuestion']}}
                >
                </GlobalQuestion>
                <Body>
                <Row>
                    <HeadDivner></HeadDivner>
                    <RadioGroupWraper row style={{
                        justifyContent: item.type === 'one' ? 'block' : 'space-evenly',
                        width: item.type === 'one' ? 100 : 500                 
                    }}>
                        {
                            item.answers.map(answer=>
                                <ColumnHead key={answer.text} dir={lang !== 'ar' ? "ltl" : "rtl"}>{answer.text}</ColumnHead>
                            )
                        }
                    </RadioGroupWraper>
                </Row>
                {
                    item.question.map(question=>
                        <Row key={question.text}>
                            <AnswerWrapper>
                                <Question dir={lang !== 'ar' ? "ltl" : "rtl"} >{question.text}</Question>
                                {answers !== undefined ?
                                    <AnswerFromCountry>{(answers[question['id']]).toFixed(2)}</AnswerFromCountry>
                                :
                                    null
                                }
                            </AnswerWrapper>
                            <AnswerVariant 
                                            question={question}
                                            item={item}
                            />              
                        </Row>    
                    )
                }
                </Body>
                <ButtonBox/>
        </Card>

    )
}