import React from "react";
import { ReactComponent as Logo } from '../../layout/svg/logo.svg'
import { useHistory } from "react-router-dom";
import Seo from '../../lib/seo/index'

export default function Index() {
    const history = useHistory()

    React.useEffect(() => {
      const timeout = setTimeout(() => {
        history.push('/en/')
      }, 1);
  
      return () => clearTimeout(timeout);
    }, []);

  
    return <div
              style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
                <Seo 
                  props={{
                    title: 'The Cultura Compas', 
                    description: 'We trained a neural network on data from a sociological study that examines life values. It was conducted with 140,000 people from 90 countries.', 
                    url: 'en',
                    }}/>
                {/* <Logo/> */}

            </div>
  }