import * as types from '../actions/types';


const initialState = {
  wait: false,
  error: false,
  data: {
    'data': {},
    'result': false
  },
  explain: false,
  explainWaint: false
};


export default function Red(state=initialState, {type, payload}) {
  switch (type) {
    // GET FIRST
    case types.POST_WVS_WAIT:
      return {
          ...state,
          wait: true,
      };
    case types.POST_WVS_SUCCESS:
        return {
            ...state,
            wait: false,
            data: payload,
            update: false,
            explain: false

        };
    case types.POST_WVS_ERROR:
        return {
            ...state,
            error: payload,
            wait: false,
        };

    // explain
    case types.POST_WVS_EXPLAIN_WAIT:
      return {
          ...state,
          explainWaint: true,
      };
    case types.POST_WVS_EXPLAIN_SUCCESS:
        return {
            ...state,
            explainWaint: false,
            explain: payload,
            explainWaint: false
        };
    case types.POST_WVS_EXPLAIN_ERROR:
        return {
            ...state,
            error: payload,
            wait: false,
        };


    default:
      return state;
    
  }
}