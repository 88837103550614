const en = {
    seo: {
        title: 'How Does It Work?',
        description: 'The main simplification we made is calling gradient boosting a neural network. Since there is a clear familiarity with neural networks in society, we decided not to be pedantic in explaining what boosting is, as no one cares.',
        url: '/catboost',
        lang: 'en'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>How Does It Work?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>The main simplification we made is calling gradient boosting a neural network. Since there is a clear familiarity with neural networks in society, we decided not to be pedantic in explaining what boosting is, as no one cares.</i>
            <br/> <br/>
                So, we used the beloved Catboost from Yandex. Because it works fast and is easy to learn.
            <br/> <br/>
            
            <h2>Getting Started</h2>
            Gradient boosting is a machine learning method that allows you to build a predictive model capable of making accurate forecasts based on a large number of different features.
            <br/> <br/>
            It works like this: first, we build a simple model that can make some predictions. Then we look at where this model makes mistakes and add another model that corrects the errors of the first model. After that, we look at the errors of the new model and build yet another model that corrects these errors.
            <br/> <br/>
            We continue this process until our model becomes accurate enough. The main idea of gradient boosting is that each new model we add takes into account the errors of the previous models and improves the overall accuracy of the model. In our model, we went through 2000 such stages.
            <br/> <br/>
            Once all models are built, we combine them to get the final model, which can make accurate predictions based on the data we feed it.
            <h2>Titanic Example</h2>
            There's a nice illustration that shows whether a person will survive on the Titanic.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "titanic example"
        },
        {
            "type": "text-html",
            "html": `Here, three trees take data about the passenger in different sequences and then the ensemble votes on whether the person will survive or not. <br/><br/>
            
            Sex - Gender <br/>
            pclass - Ticket Class. There were 3 levels. <br/>
            Age - Age <br/>
            sibsp - Were there spouses aboard <br/>
            parch - Number of children aboard <br/><br/>
            
            After training the model, you can ask which variables influence the result.
            
            <h2>What Can You Do with This?</h2>
            
            Machine learning allows you to solve many different tasks. In our company, we offer a service for predicting sales and conversions. We can perform deep event tracking on your website. We will record all visitor actions and send these data to the CatBoost gradient boosting, which will predict conversions on your website and indicate which events influenced this conversion. This way, you can optimize pages and your offer. In B2B sales scenarios, you can treat the probability of purchase as a conversion in advertising systems.`
        },

    ]
}

export default en;
