const de =     {
    "lang": "de",
    "buttons": {
        "next": "Weiter",
        "back": "Zurück",
        "send": "Antwort erhalten"
    },
    "AlertSnack": "Wenn Sie Ihr Telefon horizontal drehen, können Sie bequemer fortfahren. Es gibt mehrere Antwortmöglichkeiten auf der rechten Seite. Einige Browser unterstützen möglicherweise keine Telefonrotation.",
    error: {
        title: 'Etwas ist schief gelaufen:',
        text: `Wir werden das bald beheben, wenn es ein Serverfehler ist <br/>
        Oder vielleicht haben Sie die Internetverbindung verloren? 
        Und es könnte ein Update geben`
    },
    "seo": {
        "title": "Finden Sie heraus, welchem Land Sie als Bewohner ähneln.",
        "description": "Wir haben ein neuronales Netzwerk mit Daten aus einer soziologischen Studie trainiert, die Werte des Lebens untersucht. Die Studie wurde mit 140.000 Menschen aus 90 Ländern durchgeführt."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "Finden Sie heraus, welchem Land Sie als Bewohner ähneln."
        },
        {
            "type": "text-html",
            "html": `
                <div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>
                    Wir haben ein neuronales Netzwerk mit Daten aus einer 
                        <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>internationalen soziologischen</a> 
                    soziologischen Studie trainiert, die Werte des Lebens untersucht. Die Studie wurde mit 140.000 Menschen aus 90 Ländern durchgeführt. Wir haben einige Fragen daraus übernommen und diese Anwendung erstellt. Nach Abschluss des Tests sehen Sie eine Liste der Länder, in denen die Menschen ähnlich geantwortet haben, sowie einen Überblick über unsere Gesellschaft<br/><br/></div>`
        }
        ],
    "data": [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "Unten ist eine Liste von Eigenschaften, die Eltern ihren Kindern vermitteln können. Welche halten Sie für besonders wichtig? Bitte wählen Sie bis zu fünf Optionen aus.",
            "question": [
                {"text": "Gute Manieren", "id": "A027"},
                {"text": "Unabhängigkeit", "id": "A029"},
                {"text": "Fleiß", "id": "A030"},
                {"text": "Verantwortungsbewusstsein", "id": "A032"},
                {"text": "Vorstellungskraft", "id": "A034"},
                {"text": "Toleranz und Respekt gegenüber anderen", "id": "A035"},
                {"text": "Sparsamkeit, Geld und Dinge sparen", "id": "A038"},
                {"text": "Entschlossenheit, Ausdauer", "id": "A039"},
                {"text": "Religiöser Glaube", "id": "A040"},
                {"text": "Nicht egoistisch sein (Selbstlosigkeit)", "id": "A041"},
                {"text": "Gehorsam", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "Wichtig"}]
        },
        // {
        //   'type': 'adsGoogle'
        // },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "Unten sind verschiedene Gruppen von Menschen aufgeführt. <br/>Wählen Sie diejenigen aus, die Sie nicht als Nachbarn haben möchten.",
            "question": [
                {"text": "Menschen einer anderen Rasse", "id": "A124_02"},
                {"text": "Starke Trinker", "id": "A124_03"},
                {"text": "Einwanderer/Ausländische Arbeiter", "id": "A124_06"},
                {"text": "Drogenabhängige", "id": "A124_08"},
                {"text": "Homosexuelle", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "Auswählen"}]
        },
        {
            "q": "3",
            "type": "multi",
            "globalQuestion": "Stimmen Sie den folgenden Aussagen zu?",
            "question": [
              {
                "text": "Homosexuelle Paare können genauso gute Eltern sein wie jedes andere Paar",
                "id": "D081"
              },
              {
                "text": "Kinder zu haben ist eine Verantwortung gegenüber der Gesellschaft",
                "id": "D026_03"
              },
              {
                "text": "Es ist die Pflicht eines Kindes, sich um kranke Eltern zu kümmern",
                "id": "D026_05"
              },
              {
                "text": "Menschen, die nicht arbeiten, werden faul",
                "id": "C038"
              },
              {
                "text": "Arbeit ist eine Verpflichtung gegenüber der Gesellschaft",
                "id": "C039"
              },
              {
                "text": "Arbeit sollte an erster Stelle stehen, auch wenn dies weniger Freizeit bedeutet",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "Stimme vollkommen zu" },
              { "id": 2, "text": "Stimme zu" },
              { "id": 3, "text": "Neutral" },
              { "id": 4, "text": "Stimme nicht zu" },
              { "id": 5, "text": "Stimme überhaupt nicht zu" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "Wie wichtig sind Ihnen die folgenden Werte?",
            "question": [
              { "text": "Familie", "id": "A001" },
              { "text": "Freunde", "id": "A002" },
              { "text": "Freizeit", "id": "A003" },
              { "text": "Politik", "id": "A004" },
              { "text": "Arbeit", "id": "A005" },
              { "text": "Religion", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "Sehr wichtig" },
              { "id": 2, "text": "Ziemlich wichtig" },
              { "id": 3, "text": "Nicht sehr wichtig" },
              { "id": 4, "text": "Überhaupt nicht wichtig" }
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "Wie würden Sie auf die folgenden Aussagen reagieren? <br/>Stimmen Sie ihnen zu oder lehnen Sie sie ab?",
            "question": [
              {
                "text": "Männer sollten am Arbeitsplatz mehr Rechte haben als Frauen",
                "id": "C001"
              },
              {
                "text": "Arbeitgeber sollten Staatsbürger des Landes bevorzugen anstatt Immigranten",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "Zustimmen"},
              {"id": 2, "text": "Nicht zustimmen"},
              {"id": 3, "text": "Keine der oben genannten"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "Stimmen Sie den folgenden Aussagen zu?",
            "question": [
              {
                "text": "Eines der Hauptziele im Leben ist es, seine Eltern stolz auf mich zu machen",
                "id": "D054"
              },
              {
                "text": "Männer sind bessere politische Führer als Frauen",
                "id": "D059"
              },
              {
                "text": "Die Universität ist für Jungen wichtiger als für Mädchen",
                "id": "D060"
              },
              {
                "text": "Kleinkinder leiden, wenn ihre Mütter arbeiten",
                "id": "D061"
              },
              {
                "text": "Männer sind besser darin, Geschäfte zu führen als Frauen",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "Stimme vollkommen zu"},
              {"id": 2, "text": "Stimme zu"},
              {"id": 3, "text": "Nicht zustimmen"},
              {"id": 4, "text": "Stimme überhaupt nicht zu"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "Wählen Sie aus, welche Handlungen Ihrer Meinung nach immer gerechtfertigt sein können, niemals gerechtfertigt sein können oder etwas dazwischen, indem Sie diese Skala verwenden. <br/> 1 – Niemals gerechtfertigt, 10 – Immer gerechtfertigt",
            "question": [
              {
                "text": "Forderung staatlicher Leistungen, auf die Sie keinen Anspruch haben",
                "id": "F114A"
              },
              {
                "text": "Vermeidung der Bezahlung für öffentliche Verkehrsmittel",
                "id": "F115"
              },
              {
                "text": "Steuerschwindel, wenn Sie die Möglichkeit dazu haben",
                "id": "F116"
              },
              {
                "text": "Annahme von Bestechungsgeldern bei der Ausübung von offiziellen Pflichten",
                "id": "F117"
              },
              {
                "text": "Homosexualität",
                "id": "F118"
              },
              {
                "text": "Prostitution",
                "id": "F119"
              },
              {
                "text": "Abtreibung",
                "id": "F120"
              },
              {
                "text": "Scheidung",
                "id": "F121"
              },
              {
                "text": "Sterbehilfe",
                "id": "F122"
              },
              {
                "text": "Selbstmord",
                "id": "F123"
              },
              {
                "text": "Gelegenheitssex",
                "id": "F132"
              },
              {
                "text": "Politische Gewalt",
                "id": "E290"
              },
              {
                "text": "Todesstrafe",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "Wie wichtig ist Gott in Ihrem Leben?",
            "question": [
              {
                "text": "Bitte verwenden Sie diese Skala, um anzuzeigen: 10 bedeutet sehr wichtig, und 1 bedeutet überhaupt nicht wichtig.",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "Hier sind verschiedene Gruppen von Menschen. Wie sehr vertrauen Sie jeder Gruppe?",
            "question": [
              {"text": "Ihre Familie", "id": "D001_B"},
              {"text": "Ihr Viertel", "id": "G007_18_B"},
              {"text": "Menschen, die Sie persönlich kennen", "id": "G007_33_B"},
              {"text": "Menschen, die Sie zum ersten Mal treffen", "id": "G007_34_B"},
              {"text": "Menschen anderer Religionen", "id": "G007_35_B"},
              {"text": "Menschen anderer Nationalitäten", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "Vollstes Vertrauen"},
              {"id": 2, "text": "Ein wenig Vertrauen"},
              {"id": 3, "text": "Nicht sehr vertrauenswürdig"},
              {"id": 4, "text": "Gar kein Vertrauen"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "Im Folgenden werden verschiedene Arten von politischen Systemen beschrieben. Was halten Sie von ihnen als Form der Regierungsführung?",
            "question": [
               {
                  "text": "Präsenz eines starken Führers, der sich nicht mit dem Parlament und den Wahlen befassen muss",
                  "id": "E114"
               },
               {
                  "text": "Präsenz von Experten anstelle der Regierung, die Entscheidungen treffen, die sie für das Beste für das Land halten",
                  "id": "E115"
               },
               {
                  "text": "Präsenz eines demokratischen politischen Systems",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "Sehr gut"},
               {"id": 2, "text": "Ziemlich gut"},
               {"id": 3, "text": "Ziemlich schlecht"},
               {"id": 4, "text": "Sehr schlecht"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "Wie wichtig ist es für Sie, in einem Land mit demokratischer Regierungsführung zu leben?",
            "question": [
               {
                  "text": "Auf dieser Skala bedeutet 1 'überhaupt nicht wichtig' und 10 'absolut wichtig', welche Position würden Sie wählen?",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "Hier sind mehrere Maßnahmen der Regierung aufgeführt. Sind Sie der Meinung, dass die Regierung das Recht hat, dies zu tun?",
            "question": [
              {
                "text": "Menschen in öffentlichen Räumen per Videoüberwachung überwachen",
                "id": "H009"
              },
              {
                "text": "Alle E-Mails und sonstige Informationen, die im Internet ausgetauscht werden, überwachen",
                "id": "H010"
              },
              {
                "text": "Informationen über Menschen, die im Land leben, ohne deren Wissen sammeln",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "Sollte definitiv das Recht haben" },
              { "id": 2, "text": "Sollte wahrscheinlich das Recht haben" },
              { "id": 3, "text": "Sollte eher nicht das Recht haben" },
              { "id": 4, "text": "Sollte definitiv nicht das Recht haben" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "Hier sind einige Ziele, die die Menschen für ihr Land in den nächsten zehn Jahren wählen. Welches Ziel halten Sie Ihrer Meinung nach für das Wichtigste?",
            "question": [
              { "text": "Welches Ziel halten Sie Ihrer Meinung nach für das Wichtigste?", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "Hohe wirtschaftliche Wachstumsrate" },
              { "id": 2, "text": "Starke Armee" },
              { "id": 3, "text": "Gute Arbeits- und Gemeinschaftsbedingungen" },
              { "id": 4, "text": "Unsere Städte und Dörfer verschönern" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "Hier sind Beispiele für Freiwilligenorganisationen. Wählen Sie die Organisationen aus, denen Sie angehören.",
            "question": [
              { "text": "Religiöse Organisationen", "id": "A065" },
              {
                "text": "Organisationen, die der Bildung, Kunst, Musik oder kulturellen Aktivitäten gewidmet sind",
                "id": "A066"
              },
              { "text": "Gewerkschaften", "id": "A067" },
              {
                "text": "Organisationen, die der Erhaltung der Umwelt, Ökologie und den Rechten der Tiere gewidmet sind",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "Ich gehöre keiner Organisation an" },
              { "id": 1, "text": "Ich gehöre diesen Organisationen an" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "Vertrauen in Menschen",
            "question": [{"text": "Mit welcher Aussage stimmen Sie überein?", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "Den meisten Menschen kann man vertrauen"},
              {"id": 2, "text": "Man sollte vorsichtig sein"},
              {"id": -1, "text": "Ich weiß es nicht"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "Hier sind zwei Aussagen, die Menschen manchmal über die Umwelt und das Wirtschaftswachstum machen.",
            "question": [{"text": "Welche ist Ihrer Meinung nach wichtiger?", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "Den Schutz der Umwelt"},
              {"id": 2, "text": "Wirtschaftswachstum und Schaffung von Arbeitsplätzen"},
              {"id": -1, "text": "Ich weiß es nicht"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "Wettbewerb - gut oder schlecht?",
            "question": [{"text": "Bewerten Sie diese Aussage, wobei 1 - Gut, 10 - Schlecht", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "Gut"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "Schlecht"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "Alter",
            "question": [{"text": "Ihr Alter?", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15-24 Jahre"},
              {"id": 2, "text": "25-34 Jahre"},
              {"id": 3, "text": "35-44 Jahre"},
              {"id": 4, "text": "45-54 Jahre"},
              {"id": 5, "text": "55-64 Jahre"},
              {"id": 6, "text": "65 Jahre und älter"}
            ]
          }
    ]
}

export default de;