const ru = {
    header: {
        transale: 'Мы использовали машинный перевод. <br/> Возможно некоторые части переведенны не совсем корректно. '
    },
    footer: {
        projects: {
            title: '# Наши проекты',
            data: [
                    {
                    text: 'Анализ cоответствий',
                    url: 'https://awake-tools.com/ru/ca?utm_source=culture-compass'
                  },
                  {
                    text: 'PWA приложения',
                    url: 'https://evilunion.com/pwa?utm_source=culture-compass'
                  },
                  {
                    text: 'Быстрые сайты на Gatsby js',
                    url: 'https://evilunion.com/gatsby-js?utm_source=culture-compass'
                  }, 
                  {
                    text: 'Отдел маркетинга аутсорс',
                    url: 'https://evilunion.com/marketing-services?utm_source=culture-compass'
                  }, 
                  {
                    text: 'База IT за час для бизнеса. Мини-курс',
                    url: 'https://evilunion.com/base-it-mini-kurs?utm_source=culture-compass'
                  },  
                  {
                    text: 'Plot',
                    url: 'https://culturacompass.com/ru/heatmap'
                  },
                  {
                    text: 'Heatmap',
                    url: 'https://culturacompass.com/ru/plot'
                  },  
            ]
        },
        us: {
            title: '# Наши нас',
            data: [
                {
                text: 'evilUnion',
                url: 'https://evilunion.com/en?utm_source=culture-compass'
                },
                {
                text: 'Обратная связь',
                url: 'https://t.me/evilunion_chat'
                },
                // {
                // text: 'Блог VC',
                // url: 'https://vc.ru/u/939695-evilunion?utm_source=culture-compass'
                // },
                {
                    text: 'Terms of use ',
                    url: 'https://culturacompass.com/en/terms'
                },
                {
                    text: 'Privacy policy',
                    url: 'https://culturacompass.com/en/cookie'
                },
            ]
        }
    }
}

export default ru;