const hi = {
    seo: {
        title: 'यह कैसे काम करता है?',
        description: 'हमने जो मुख्य सरलीकरण किया है, उसमें ग्रेडिएंट बूस्टिंग को न्यूरल नेटवर्क के रूप में पुनः नामकरण किया है। क्योंकि समाज में न्यूरल नेटवर्क के प्रति स्पष्ट परिचय है, हमने बूस्टिंग क्या है यह व्याख्या करने में बहुत परेशान नहीं किया, क्योंकि किसी को परवाह नहीं है।',
        url: '/catboost',
        lang: 'hi'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>यह कैसे काम करता है?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>हमने जो मुख्य सरलीकरण किया है, उसमें ग्रेडिएंट बूस्टिंग को न्यूरल नेटवर्क के रूप में पुनः नामकरण किया है। क्योंकि समाज में न्यूरल नेटवर्क के प्रति स्पष्ट परिचय है, हमने बूस्टिंग क्या है यह व्याख्या करने में बहुत परेशान नहीं किया, क्योंकि किसी को परवाह नहीं है।</i>
            <br/> <br/>
                इसलिए, हमने यांडेक्स के पसंदीदा Catboost का उपयोग किया है। क्योंकि यह तेजी से काम करता है और सीखने में आसान है।
            <br/> <br/>
            
            <h2>आरंभ करना</h2>
            ग्रेडिएंट बूस्टिंग एक मशीन लर्निंग की विधि है जो आपको बहुत सारी विभिन्न विशेषताओं के आधार पर सटीक पूर्वानुमान बनाने की क्षमता देती है।
            <br/> <br/>
            यह इस प्रकार काम करता है: पहले, हम एक सरल मॉडल बनाते हैं, जो कुछ पूर्वानुमान बना सकता है। फिर हम देखते हैं कि यह मॉडल कहां गलतियां करता है, और पहले मॉडल की गलतियों को सही करने वाला एक और मॉडल जोड़ते हैं। इसके बाद, हम नए मॉडल की गलतियों को देखते हैं और फिर एक और मॉडल बनाते हैं जो इन गलतियों को सही करता है।
            <br/> <br/>
            हम इस प्रक्रिया को तब तक जारी रखते हैं जब तक हमारा मॉडल पर्याप्त रूप से सटीक नहीं हो जाता। ग्रेडिएंट बूस्टिंग की मुख्य विचारणा यह है कि हमारे द्वारा जो नए मॉडल जोड़ा जाता है, वह पिछले मॉडलों की गलतियों का ध्यान रखता है और मॉडल की कुल सटीकता को सुधारता है। हमारे मॉडल में, हमने 2000 ऐसे स्टेप किए हैं।
            <br/> <br/>
            जब सभी मॉडल बन गए होते हैं, हम उन्हें मिलाकर हमें दी गई डेटा के आधार पर सटीक पूर्वानुमान बना सकते हैं, जो हमें प्रदान करते हैं।
            <h2>टाइटैनिक का उदाहरण</h2>
            वहां एक खूबसूरत चित्र है जिससे पता चलता है कि क्या कोई व्यक्ति टाइटैनिक पर बचेगा या नहीं।
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "टाइटैनिक का उदाहरण"
        },
        {
            "type": "text-html",
            "html": `यहाँ, तीन पेड़ यात्री के डेटा को विभिन्न क्रम में लेते हैं और फिर संगठन समूह वोट करता है कि व्यक्ति क्या टाइटैनिक पर बचेगा या नहीं। <br/><br/>
            
            लिंग - Gender <br/>
            प्रकार - टिकट का प्रकार। 3 स्तर थे। <br/>
            आयु - Age <br/>
            सहभागी - क्या बोर्ड पर पतियों के साथी थे <br/>
            पार्च - बोर्ड पर बच्चों की संख्या <br/><br/>
            
            मॉडल की प्रशिक्षण के बाद, आप पूछ सकते हैं कि परिणाम को किस चरण प्रभावित करते हैं।
            
            <h2>आप इसका क्या उपयोग कर सकते हैं?</h2>
            
            मशीन सीखने से आपको कई विभिन्न कार्य करने की क्षमता होती है। हमारी कंपनी में हम बिक्री और परिवर्तनों की पूर्वानुमान सेवा प्रदान करते हैं। हम आपकी वेबसाइट पर घाघाघट घटनाओं का गहरा परवर्तन कर सकते हैं। हम सभी आगंतुकों की क्रियाओं को लॉग करेंगे और इन डेटा को ग्रेडिएंट बूस्टिंग CatBoost को भेजेंगे, जो आपकी वेबसाइट पर परिवर्तनों की पूर्वानुमान करेगा और दिखाएगा कि कौन से घटनाएं इस परिवर्तन को प्रभावित करती हैं। इस तरह से, आप पृष्ठों और अपनी पेशेवरी को अनुकूलित कर सकते हैं। B2B बेचने के परिदृश्यों में, आप खरीदारी की संभावना को विज्ञापन सिस्टमों में एक परिवर्तन के रूप में देख सकते हैं।`
        },

    ]
}

export default hi;
