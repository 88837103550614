const en = {
        "data": [
            {
                "type": "text-html",
                "html": "<h1>Analysis</h1>"
            },
            {
                "type": "text-html",
                "html": "Next, I would like to share interesting findings from this data. <br/><br/> Your response was analyzed by a neural network that studied 140,000 responses from respondents in a sociological survey called <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>World Values Survey</a>. The elegance and uniqueness of this approach lies in the fact that the neural network lacks consciousness and any psychological biases. It did not attend school, watch TikToks, or television. It can hold a lot of variables in its mind, making its findings highly honest.  <br/><br/> <i>Of course, the questions in this test cannot reveal all cultural peculiarities of countries, but they uncover the fundamental life values on our shared planet.</i> <br/><br/> Using certain mathematical methods, we can construct a map of countries, indicating how they cluster together. In this case, we obtained 6 major clusters."
            },
            {
                "type": "img",
                "href": "/images/country_table.png",
                "alt": "World Values Survey"
            },
            {
                "type": "text-html",
                "html": "Western countries, Asia, post-Soviet countries, Latin America, and Muslim countries. The closer the points are to each other, the more similar the responses from these countries. The coordinate axes are abstract since they consider all responses at once. Most likely, your country from the response belongs to one of these clusters. The reason for the countries' arrangement is explained below. <br/><br/> We show the probabilities of your answers coming from different countries. We take the top 5 countries. If your result does not strongly align with any specific country, it means your responses are unique. A strongly aligned probability should be greater than ~50%. At the moment, we receive diverse answers, and there are cases where one country may have a probability of 96%."
            },
            {
                "type": "pannel"
            },
            {
                "type": "text-html",
                "html": "You can also click on a country in the test results to load the average answers from that country. They will appear alongside your answers to the questions. <br><br/><i>Please note that the answer options are numbered from 1. Additionally, people in countries may respond differently to the questions. For example, half of the respondents may choose one answer, while the other group selects the opposite option. In such cases, calculating the average value would not be appropriate. Our neural network takes these situations into account because it is based on the method of random forests.</i><br/><br/>"
            },
            {
                "type": "text-html",
                "html": "<h2>Key Questions</h2> During the training process, we divided the data into two parts: one for testing and the other for training. The algorithm learned from the training data and evaluated its performance on the testing data, which was not part of the training. This brilliant method, developed by mathematicians in the last century, forms the foundation of machine learning. There were about 2000 training iterations until we found the most effective model to describe the data. If you are interested in more professional details, you can visit <a href='/en/ml/' target='_blank'>this page</a>. For a basic explanation, you can check <a href='/en/catboost/' target='_blank'>this link</a>.<br/><br/><h3>After our algorithm learned, we asked it - which questions were the most significant?</h3><br/>Here are the top 15 questions:<ul><li>Justifiable: Homosexuality</li><li>How important is God in your life?</li><li>Same-sex couples can be as good parents as other couples</li><li>Duty of a child to care for a sick parent</li><li>Political system: Presence of a strong leader</li><li>Having children - a duty to society</li><li>Justifiable: Capital punishment</li><li>Important child qualities: Industriousness</li><li>Justifiable: Abortion</li><li>Neighbors: Homosexuals</li><li>Justification: Casual sex</li><li>Justifiable: Refusing to pay for public transportation</li><li>Work should be a top priority, even if it means less leisure time</li><li>Political system: Decisions made by experts</li><li>Belonging to a religious organization</li></ul><br/><h3>It can be said that these questions contribute to dividing societies today. Through this project, we aim to learn who people are, how they differ, and how we can better understand them.</h3>"
            }
        ],
        "pannel": {
            "SubTitile": "Top 5 answers that connect you with residents of these countries",
            "TextButtom": "<i>Your combination of answers to these questions makes you similar to this group. <br/> Green dots indicate the weight of this question and your response to it. <br/> Your answer could be positive or negative.</i>",
            "ShareButton": "Find out which country's residents you are similar to.",
            "call": "Share with friends!"
        },
        "final": {
            "SubTitile": `Your response is similar to the answers of people from the following countries`,
            "whyAnswer": "Why this answer?",
            "ShareButton": ["Find out which country's residents you are similar to.\nI am ", "% similar to the residents of"],
            "call": "Share with friends!"
        }
    }    


export default en;