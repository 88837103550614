const zh_CN = {
    seo: {
        title: '它是如何工作的？',
        description: '我们做出的主要简化是将梯度提升称为神经网络。由于社会上对神经网络有明确的熟悉度，我们决定在解释什么是提升时不要过于 pedantic，因为没人在意。',
        url: '/catboost',
        lang: 'zh-CN'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>它是如何工作的？</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>我们做出的主要简化是将梯度提升称为神经网络。由于社会上对神经网络有明确的熟悉度，我们决定在解释什么是提升时不要过于 pedantic，因为没人在意。</i>
            <br/> <br/>
                所以，我们使用了亲爱的 Yandex Catboost。因为它运行速度快且易于学习。
            <br/> <br/>
            
            <h2>入门</h2>
            梯度提升是一种机器学习方法，允许你构建一个能够基于大量不同特征进行准确预测的预测模型。
            <br/> <br/>
            它的工作原理如下：首先，我们构建一个简单的模型，能够做出一些预测。然后，我们查看这个模型的错误之处，并添加另一个纠正第一个模型错误的模型。然后，我们查看新模型的错误，并构建另一个模型来纠正这些错误。
            <br/> <br/>
            我们持续进行这个过程，直到我们的模型足够准确。梯度提升的主要思想是我们添加的每个新模型都会考虑先前模型的错误，并提高模型的整体准确性。在我们的模型中，我们进行了 2000 个这样的阶段。
            <br/> <br/>
            一旦所有模型都构建完成，我们将它们合并在一起，得到最终的模型，该模型可以根据我们提供的数据进行准确的预测。
            <h2>泰坦尼克号示例</h2>
            有一张很好的插图，显示一个人在泰坦尼克号上是否会幸存。
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "泰坦尼克号示例"
        },
        {
            "type": "text-html",
            "html": `在这里，三棵树以不同的顺序获取有关乘客的数据，然后整体投票决定一个人是否会幸存。 <br/><br/>
            
            性别 - Gender <br/>
            pclass - 票的等级。有 3 个级别。 <br/>
            年龄 - Age <br/>
            sibsp - 是否有配偶在船上 <br/>
            parch - 船上的孩子数量 <br/><br/>
            
            在训练模型后，您可以询问哪些变量影响了结果。
            
            <h2>你可以用这个做什么？</h2>
            
            机器学习使您能够解决许多不同的任务。在我们的公司，我们提供预测销售和转化的服务。我们可以在您的网站上执行深度事件跟踪。我们将记录所有访客的行为并将这些数据发送给 CatBoost 梯度提升，该模型将预测您的网站上的转化并指出哪些事件影响了这种转化。通过这种方式，您可以优化页面和您的提供。在 B2B 销售场景中，您可以将购买的概率视为广告系统中的转化。`
        },

    ]
}

export default zh_CN;
