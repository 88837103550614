import React, { useEffect } from 'react';
import { Typography } from '@mui/material';


const text = {
  "ru": "Это реклама в поддержку проекта.",
  "en": "This is an advertisement to support the project.",
  "es": "Este es un anuncio para apoyar el proyecto.",
  "zh": "这是一个支持该项目的广告。",
  "ja": "これはプロジェクトをサポートする広告です。",
  "fr": "Ceci est une publicité pour soutenir le projet.",
  "de": "Dies ist eine Werbung zur Unterstützung des Projekts.",
  "hi": "यह परियोजना का समर्थन करने के लिए एक विज्ञापन है।",
  "pt": "Este é um anúncio para apoiar o projeto.",
  "ar": "هذا إعلان لدعم المشروع."
}




function AdComponent() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  var langauge = window.location.href.split('/')[3]

  return (
    <div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5105531778978436"
        crossOrigin="anonymous"
      ></script>
      <Typography style={{marginBottom: 20}}>{text[langauge]}</Typography>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-5105531778978436"
        data-ad-slot="7948182987"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}

export default AdComponent;



