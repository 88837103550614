import React from 'react'
import Modal from '../../Modals'
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row-reverse',
        justifyContent: 'center'
    },
    text: {
        width: 600,
    }
}))

export default function ModalButtonPost({props}) {
    const classes = useStyles();

    return <div className={classes.root}>
                <Modal
                    className={classes.text}
                    text={props.text} 
                    name={props.name} 
                    items={props.items}
                />
            </div>
}