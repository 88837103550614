import React from 'react';
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
      marginTop: 40,
      marginBottom: 40,
      padding: 20,
      // margin: 20,
      border: '1px solid rgba(39, 21, 92, 0.1)',
      borderRadius: 40,
    }
}))

export default function Container({children}) {
  const classes = useStyles();
  return(
    <div className={classes.root}>
      {children}
    </div>
  )
}