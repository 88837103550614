import React from 'react'
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    width: '100%',
    opacity: '0.85',
    borderRadius: 40,
  }
}))


export default function Img({props}) {
  const classes = useStyles();

  return <img 
            id='Img'
            src={props.href} 
            alt={props.alt}
            className={classes.root}
          />
}