import React from 'react';
import {makeStyles} from '@mui/styles';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../svg/logo.svg';
import SuperMenu from './superMenu'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // height: 80,
    width: '100%',
    alignContent: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 50,
    marginTop: 30
  },
  logo: {

  }
}))


const Header = ({text, lang, setLang}) => {
  const classes = useStyles();
  const menuData = [
    {
      text: 'English',
      url: 'en',
      languages: 'Languages'
    },
    {
      text: 'Español',
      url: 'es',
      languages: 'Idiomas'
    },
    {
      text: 'Русский',
      url: 'ru',
      languages: 'Языки'
    },
    {
      text: '中文',
      url: 'zh',
      languages: '语言'
    },
    {
      text: '日本語',
      url: 'ja',
      languages: '言語'
    },
    {
      text: 'Français',
      url: 'fr',
      languages: 'Langues'
    },
    {
      text: 'Deutsch',
      url: 'de',
      languages: 'Sprachen'
    },
    ,
    {
      text: 'हिन्दी',
      url: 'hi',
      languages: 'भाषाएँ '
    },
    {
      text: 'Português',
      url: 'pt',
      languages: 'Línguas'
    },
    {
      text: 'العربية',
      url: 'ar',
      languages: 'لغات'
    },
  ]


  return(
    <div className={classes.root}>
        <Link to={`/${lang}/`}>
          <div className={classes.logo}>
            <Logo className={classes.logo}/>
          </div>
        </Link>
        <SuperMenu text={text} menuData={menuData} langauge={lang} setLang={setLang}/>
    </div>
  )
}

export default Header
