import React from "react";
import {Helmet} from "react-helmet";


export default function SEO({props}) {
    const Description = props.description || 'We trained a neural network on data from a sociological study that examines life values. It was conducted with 140,000 people from 90 countries.'
    const Title = props.title || "Find out which country's resident you resemble."

    // console.log(props)

    const Alternate = () => {
        if (props.langList !== undefined) {
            return 
                <>
                    {
                        props.langList.map(lang => 
                                <link rel="alternate" hreflang={lang} href={"https://culturacompass.com/" + lang + props.url} key={lang} />  
                        )
                    }
                </>
        }
    }

    return (
        <Helmet >
            <title>{Title}</title>
            <meta name="description" content={Description} />

            <meta property="og:title" content={Title}/>
            <meta property="og:description" content={Description}/> 
            <meta property="og:image" content='/images/socialImg.png' />

            <meta property="twitter:title" content={Title}/>
            <meta property="twitter:description" content={Description}/> 
            <meta property="twitter:image" content='/images/socialImg.png' />
            {props.langList !== undefined ?
                        props.langList.map(lang => 
                                <link rel="alternate" hreflang={lang} href={"https://culturacompass.com/" + lang + props.url + '/'} key={lang} />  
                        )
            :
                <div/>
            }
            <link rel="canonical" href={"https://culturacompass.com/" + props.lang + props.url + '/'}/>

        </Helmet>
    )
}