const es = {
    seo: {
        title: '¿Cómo Funciona?',
        description: 'La principal simplificación que hicimos es llamar al impulso de gradiente una red neuronal. Dado que hay una clara familiaridad con las redes neuronales en la sociedad, decidimos no ser pedantes al explicar qué es el impulso, ya que a nadie le importa.',
        url: '/catboost',
        lang: 'es'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>¿Cómo Funciona?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>La principal simplificación que hicimos es llamar al impulso de gradiente una red neuronal. Dado que hay una clara familiaridad con las redes neuronales en la sociedad, decidimos no ser pedantes al explicar qué es el impulso, ya que a nadie le importa.</i>
            <br/> <br/>
                Así que usamos el querido Catboost de Yandex. Porque funciona rápido y es fácil de aprender.
            <br/> <br/>
            
            <h2>Empezando</h2>
            El impulso de gradiente es un método de aprendizaje automático que te permite construir un modelo predictivo capaz de hacer pronósticos precisos basados en una gran cantidad de características diferentes.
            <br/> <br/>
            Funciona así: primero, construimos un modelo simple que puede hacer algunas predicciones. Luego, observamos dónde este modelo comete errores y agregamos otro modelo que corrige los errores del primer modelo. Después de eso, observamos los errores del nuevo modelo y construimos otro modelo que corrige esos errores.
            <br/> <br/>
            Continuamos este proceso hasta que nuestro modelo sea lo suficientemente preciso. La idea principal del impulso de gradiente es que cada nuevo modelo que agregamos tiene en cuenta los errores de los modelos anteriores y mejora la precisión general del modelo. En nuestro modelo, pasamos por 2000 etapas de este tipo.
            <br/> <br/>
            Una vez que se construyen todos los modelos, los combinamos para obtener el modelo final, que puede hacer pronósticos precisos basados en los datos que le proporcionamos.
            <h2>Ejemplo del Titanic</h2>
            Hay una ilustración agradable que muestra si una persona sobrevivirá en el Titanic.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "ejemplo del titanic"
        },
        {
            "type": "text-html",
            "html": `Aquí, tres árboles toman datos sobre el pasajero en secuencias diferentes y luego el conjunto vota si la persona sobrevivirá o no. <br/><br/>
            
            Sexo - Género <br/>
            pclass - Clase de boleto. Había 3 niveles. <br/>
            Edad - Edad <br/>
            sibsp - ¿Había cónyuges a bordo? <br/>
            parch - Número de hijos a bordo <br/><br/>
            
            Después de entrenar el modelo, puedes preguntar qué variables influyen en el resultado.
            
            <h2>¿Qué Puedes Hacer con Esto?</h2>
            
            El aprendizaje automático te permite resolver muchas tareas diferentes. En nuestra empresa, ofrecemos un servicio para predecir ventas y conversiones. Podemos realizar un seguimiento profundo de eventos en tu sitio web. Registraremos todas las acciones de los visitantes y enviaremos estos datos al impulso de gradiente CatBoost, que predecirá conversiones en tu sitio web e indicará qué eventos influyeron en esta conversión. De esta manera, puedes optimizar páginas y tu oferta. En escenarios de ventas B2B, puedes tratar la probabilidad de compra como una conversión en sistemas publicitarios.`
        },

    ]
}

export default es;
