import ru from './ru'
import en from './en'
import ar from './ar'
import ja from './ja'
import de from './de'
import fr from './fr'
import es from './es'
import hi from './hi'
import pt from './pt'
import zh from './zh'


const CMS = {
    ru: ru,
    en: en,
    ja: ja,
    es: es,
    ar: ar,
    de: de,
    fr: fr,
    hi: hi,
    pt: pt,
    zh: zh
}

export default CMS;