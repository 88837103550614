const pt = {
    "data": [
        {
            "type": "text-html",
            "html": "<h1>Análise</h1>"
        },
        {
            "type": "text-html",
            "html": "Em seguida, gostaria de compartilhar conclusões interessantes desses dados. <br/><br/> Seu resultado foi analisado por uma rede neural que estudou 140.000 respostas de entrevistados do Estudo Mundial de Valores Sociais <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>World Values Survey</a>. A elegância e a diferença desse método em relação a outros é que a rede neural não tem consciência e está isenta de qualquer viés psicológico. Ela não foi à escola, não assistiu TikToks ou televisão. Ela pode reter muitas variáveis em sua mente, tornando suas conclusões potencialmente muito honestas. <br/><br/><i>Claro, as perguntas deste teste não podem revelar todas as características culturais dos países. No entanto, elas revelam os valores de vida fundamentais em nosso planeta em comum.</i><br/><br/> Usando métodos matemáticos específicos, podemos construir um mapa de países que mostra como os países se agrupam entre si. Neste caso, obtivemos 6 grandes agrupamentos."
        },
        {
            "type": "img",
            "href": "/images/country_table.png",
            "alt": "World Values Survey"
        },
        {
            "type": "text-html",
            "html": "Países ocidentais, Ásia, países pós-soviéticos, América Latina, países muçulmanos. Quanto mais próximos os pontos estão uns dos outros, mais semelhantes são as respostas desses países. As coordenadas dos eixos são abstratas, pois levam em conta todas as respostas de uma vez. Provavelmente, seus países no resultado estão dentro de um desses agrupamentos. O motivo pelo qual os países estão dispostos dessa forma é explicado logo abaixo. <br/><br/> Estamos apresentando a probabilidade de suas respostas serem de cada país. Tomamos os 5 principais países. Se o seu resultado não tiver um país claramente definido, isso significa que suas respostas são únicas. A probabilidade fortemente marcada deve ser superior a ~ 50%. <br/> No momento, estamos obtendo muitas respostas diferentes, e os resultados podem chegar a 96%."
        },
        {
            "type": "pannel"
        },
        {
            "type": "text-html",
            "html": "Você também pode clicar em um país nos resultados do teste e carregar as respostas médias desse país. Elas aparecerão ao lado das suas respostas para as perguntas. <br><br/><i>Observe que as opções de resposta são numeradas a partir de 1. Além disso, as pessoas nos países podem responder de maneiras diferentes às perguntas. Por exemplo, metade dos entrevistados pode escolher uma resposta, enquanto o outro grupo escolhe a opção oposta. A média pode não ser a maneira correta de estudar isso. Nossa rede neural leva essas situações em consideração, pois ela é baseada no método de florestas aleatórias.</i> <br/><br/>"
        },
        {
            "type": "text-html",
            "html": "<h2>Perguntas-chave</h2> Durante o treinamento, dividimos os dados em duas partes. Uma parte para teste e outra para treinamento. O algoritmo estudou a parte de treinamento e avaliou seu desempenho na parte de teste, que não participou do treinamento. Este método genial foi inventado por matemáticos no século passado e é a base da aprendizagem de máquina. Foram cerca de 2000 treinamentos até que fosse encontrada o modelo mais eficiente que descreve os dados. Se você estiver interessado em mais detalhes profissionais, você pode ir para <a href='/pt/ml/' target='_blank'>aqui</a>. E se você precisa de uma explicação básica, você pode ir para <a href='/pt/catboost/' target='_blank'>aqui</a>. <br/><br/><h3>Depois que o algoritmo aprendeu, perguntamos a ele - quais são as perguntas mais significativas?</h3><br/> Aqui estão as 15 principais perguntas:<ul><li>Justificação: Homossexualidade</li><li>Quão importante é Deus em sua vida</li><li>Casais homossexuais podem ser tão bons pais quanto qualquer outro casal</li><li>Dever das crianças cuidar dos pais doentes</li><li>Sistema político: Presença de um líder forte</li><li>Ter filhos - obrigação social</li><li>Justificação: Pena de morte</li><li>Atributos infantis importantes: Diligência</li><li>Justificação: Aborto</li><li>Vizinhos: Homossexuais</li><li>Justificação: Sexo casual</li><li>Justificação: Recusa de pagamento de passagem no transporte público</li><li>O trabalho deve ser prioridade, mesmo que signifique menos tempo livre</li><li>Sistema político: Decisões tomadas por especialistas</li><li>Participação em uma organização religiosa</li></ul><br/><h3>Pode-se dizer que essas perguntas permitem dividir as sociedades hoje. Através deste projeto, buscamos saber quem são as pessoas, como elas são diferentes e como podemos entendê-las melhor.</h3>"
        }
    ],
    "pannel": {
        "SubTitile": "As 5 principais respostas que o unem aos habitantes desses países",
        "TextButtom": "<i>Suas respostas para essas perguntas combinam com o seu grupo. <br/> Os pontos verdes mostram o peso desta pergunta e sua resposta. <br/> Sua resposta pode ser positiva ou negativa.</i>",
        "ShareButton": "Descubra com qual país você se parece.",
        "call": "Compartilhe com os amigos!"
    },
    "final": {
        "SubTitile": "Sua resposta é semelhante às respostas das pessoas dos seguintes países",
        "whyAnswer": "Por que essa resposta?",
        "ShareButton": ["Descubra com qual país você se parece. Eu sou semelhante a ", "% da população"],
        "call": "Compartilhe com os amigos!"
    }
}
export default pt;