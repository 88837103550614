const fr = {
    seo: {
        title: 'Détails Techniques',
        description: "La principale simplification que nous avons faite est de qualifier le boosting de gradient comme un réseau neuronal. Étant donné qu'il y a une familiarité claire avec les réseaux neuronaux dans la société, nous avons décidé de ne pas nous embêter à expliquer ce qu\'est le boosting, car personne ne s\'en soucie.",
        url: '/ml',
        lang: 'fr'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Détails Techniques</h1>"
        },
        {
            "type": "text-html",
            "html": `
            La principale simplification que nous avons faite est de qualifier le boosting de gradient comme un réseau neuronal. Étant donné qu'il y a une familiarité claire avec les réseaux neuronaux dans la société, nous avons décidé de ne pas nous embêter à expliquer ce qu'est le boosting, car personne ne s'en soucie.
            <br/> <br/>
                Nous avons donc utilisé le CatBoost de Yandex, préféré de tous. Parce qu'il fonctionne rapidement et est facile à entraîner.
            <br/> <br/>
                loss_function - MultiClass <br/>
                eval_metric - TotalF1 <br/>
                test_size - 40% <br/>
            <br/> <br/>
                Le score final Total F1 était de 64 pour cent sur l'ensemble de test. Veuillez noter que ceci est une classification multi-classe ! Nous prédisons non pas une variable booléenne, mais des classes, au nombre de 80. Nous avons décidé de supprimer 10 pays des données car ils avaient moins de 1000 répondants. La Turquie a beaucoup modifié ses questions, nous l'avons donc simplement retirée des données.
            <br/> <br/>
                Il était possible d'améliorer encore le score Total F1, mais cela aurait pris trop de temps. En principe, si nous alimentions toutes les questions, nous atteindrions une précision de 92 % immédiatement ! Certaines questions pourraient être des indications pour le modèle, mais je ne vois pas l'intérêt d'une amélioration ultérieure. De plus, rendre l'enquête trop longue n'était pas souhaitable. En tout cas, les personnes sont regroupées en fonction de leurs clusters basés sur les valeurs de vie.
            <br/> <br/>
            <h2>Matrice de Confusion</h2>
                Voici quelque chose d'intéressant. Nous pouvons voir quels pays le modèle confond le plus souvent. Mais vous pouvez aussi reformuler la question et demander quels pays se ressemblent le plus, puisque le modèle confond leurs citoyens.
            <br/> <br/>
                Voici des exemples de certains pays. Chaque tableau représente la filtration des données par pays. Et l'évaluation globale du modèle pour tous les pays de l'ensemble de test. Par exemple, le premier tableau représente les répondants de Russie dans l'ensemble de test et les réponses du modèle en fonction de leurs réponses.
            <br/> <br/>
            `
        },
        {
            "type": "img",
            "href": "/images/error_country.png",
            "alt": "exemple de pays erroné"
        },
    ]
}

export default fr;
