import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../svg/logo.svg';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: 40,
    padding: '40px 40px 40px 0px',
    // border: '1px solid rgba(39, 21, 92, 0.1)',
    borderRadius: '40px 40px 0px 0px',
    justifyContent: 'space-between',
    backgroundColor: '#1D1D1D',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    fontWeight: '700',
    fontSize: '50px',
    lineHeight: '50px',
    background: 'linear-gradient(180deg, #177DDC 0%, #1766DC 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
  subTitle: {
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: 10,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 40,
    alignContent: 'flex-start',
  },
  logoGroup: {
    marginBottom: 180,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
  },
  item: {
    // marginBottom: 10,
    // marginTop: 20
  },
  plot: {
    marginRight: 10,
  },
}));

const Footer = ({ text, lang }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} dir={lang !== 'ar' ? "ltl" : "rtl"}>
      <div className={classes.rightColumn}>
        <div className={classes.logoGroup}>
          <Link to={`/${lang}`}><div className={classes.logo}><Logo/></div></Link>
        </div>
        <div className={classes.subTitle}>
          <a href='https://evilunion.com/en/?utm_source=culture-compass' target='_blank'>designed and developed by evilUnion</a>
        </div>
      </div>
      <div className={classes.column}>
        <Typography className={classes.item} variant={'h4'}>
          {text.footer.projects.title}
        </Typography>
        <div className={classes.item}></div>
        {text.footer.projects.data.map((item) =>
          item.url.includes('https') ? (
            <a href={item.url} key={item.text} target="_blank" rel="noreferrer">
              <Button variant="outlined" color={'info'} className={classes.itemMenu}>
                {item.text}
              </Button>
            </a>
          ) : (
            <Link to={item.url} key={item.text}>
              <Button variant="outlined" color={'info'} className={classes.itemMenu}>
                {item.text}
              </Button>
            </Link>
          ),
        )}
      </div>
      <div className={classes.column}>
        <Typography className={classes.item} variant={'h4'}>
          {text.footer.us.title}
        </Typography>
        <div className={classes.item}></div>
        {text.footer.us.data.map((item) =>
          item.url.includes('https') ? (
            <a key={item.text} href={item.url} target="_blank" rel="noreferrer">
              <Button variant="outlined" color={'info'} className={classes.itemMenu}>
                {item.text}
              </Button>
            </a>
          ) : (
            <Link key={item.text} to={item.url}>
              <Button variant="outlined" color={'info'} className={classes.itemMenu}>
                {item.text}
              </Button>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default Footer;
