const ar = {
    seo: {
        title: 'كيف يعمل هذا؟',
        description: 'أكبر تبسيط قمنا به هو تسمية تعزيز التدرج باسم الشبكة العصبية. نظرًا لأن هناك توافقًا واضحًا مع الشبكات العصبية في المجتمع، قررنا عدم أن نكون مفصلين في شرح مفهوم التعزيز، لأنه لا يهم أحد.',
        url: '/catboost',
        lang: 'ar'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>كيف يعمل هذا؟</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>أكبر تبسيط قمنا به هو تسمية تعزيز التدرج باسم الشبكة العصبية. نظرًا لأن هناك توافقًا واضحًا مع الشبكات العصبية في المجتمع، قررنا عدم أن نكون مفصلين في شرح مفهوم التعزيز، لأنه لا يهم أحد.</i>
            <br/> <br/>
                لذا، استخدمنا Catboost المحبوب من Yandex. لأنه يعمل بسرعة وسهل التعلم.
            <br/> <br/>
            
            <h2>البدء</h2>
            التعزيز التدرجي هو طريقة لتعلم الآلة تتيح لك بناء نموذج توقع قادر على إجراء توقعات دقيقة استنادًا إلى مجموعة متنوعة من السمات المختلفة.
            <br/> <br/>
            إليك كيف يعمل ذلك: أولاً، نبني نموذجًا بسيطًا قادرًا على إجراء بعض التوقعات. ثم نراقب حيث يقوم هذا النموذج ب comete الأخطاء ونضيف نموذجًا آخر يصحح أخطاء النموذج الأول. بعد ذلك، ننظر إلى أخطاء النموذج الجديد ونبني نموذجًا آخر يصحح هذه الأخطاء.
            <br/> <br/>
            نستمر في هذه العملية حتى يكون نموذجنا دقيقًا بما فيه الكفاية. الفكرة الرئيسية للتعزيز التدرجي هي أن كل نموذج جديد نضيفه يأخذ في الاعتبار أخطاء النماذج السابقة ويحسن الدقة العامة للنموذج. في نموذجنا، أجرينا 2000 خطوة من هذا النوع.
            <br/> <br/>
            بمجرد بناء جميع النماذج، نجمعها للحصول على النموذج النهائي، الذي يمكنه إجراء توقعات دقيقة استنادًا إلى البيانات التي نقدمها له.
            <h2>مثال من تايتانيك</h2>
            هناك رسم توضيحي جميل يُظهر ما إذا كان شخص ما سيبقى على قيد الحياة على تايتانيك أم لا.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "مثال من تايتانيك"
        },
        {
            "type": "text-html",
            "html": `هنا، تلتقط ثلاثة أشجار البيانات حول الراكب بتتابع مختلف، ثم يصوت المجموعة بأكملها لتحديد ما إذا كان الشخص سيبقى على قيد الحياة أم لا. <br/><br/>
            
            الجنس - Gender <br/>
            pclass - فئة التذكرة. كان هناك 3 مستويات. <br/>
            العمر - Age <br/>
            sibsp - هل كان هناك أزواج على متن السفينة <br/>
            parch - عدد الأطفال على متن السفينة <br/><br/>
            
            بعد تدريب النموذج، يمكنك الاستفسار عن العوامل التي تؤثر على النتيجة.
            
            <h2>ما يمكنك القيام به باستخدام هذا؟</h2>
            
            تمكن التعلم الآلي من حل العديد من المهام المختلفة. في شركتنا، نقدم خدمة التنبؤ بالمبيعات والتحويلات. يمكننا إجراء تتبع عميق للأحداث على موقع الويب الخاص بك. سنقوم بتسجيل جميع أفعال الزوار وإرسال هذه البيانات إلى Gradient Boosting CatBoost، الذي سيتنبأ بالتحويلات على موقع الويب الخاص بك وسيوضح أي الأحداث تأثرت بهذا التحويل. بهذه الطريقة، يمكنك تحسين الصفحات وعرضك. في سيناريوهات المبيعات B2B، يمكنك معالجة احتمالية الشراء كتحويل في أنظمة الإعلانات.`
        },

    ]
}

export default ar;
