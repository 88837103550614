import * as React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup, styled} from '@mui/material';


const RadioWraper = styled(RadioGroup)({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: 500
})


export default function RadioGroupControll({ item, sendDataState, question, state }) {
  const [value, setValue] = React.useState(state[question.id]);

  const handleChange = (event) => {
    setValue(event.target.value);
    sendDataState(event.target.value, question.id)
  };
  
  return (
    <FormControl>
      <RadioWraper
        row
        value={value}
        onChange={handleChange}
      >
        {
            item.answers.map(answer =>                
                        <FormControlLabel
                            key={question.id + answer.id} 
                            control={<Radio/>} 
                            name={answer.id} 
                            value={answer.id}
                            
                            />              
            )
        }
      </RadioWraper>
    </FormControl>
  );
}