import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import {Helmet} from "react-helmet";
import LayOut from "../../layout";

const Title = styled('h1')({
    color: '#c2c2d1',
})


export default function Heatmap() {
    
    return(
        <LayOut>
            <Seo props={{title: '404 error', description: 'There is no such page ', lang:'en', url: '404', langList: undefined}}/>
            <h1 style={{color: 'white'}}>404 error</h1>
            <Title>There is no such page, but there is <a href="/en">one</a> </Title>
            <Helmet>
                <meta name='errorpage' content='true' />
                <meta name='errortype' content='404 - Not Found' />
                <meta name='prerender-status-code' content='404' />
            </Helmet>
        </LayOut>
    )
}