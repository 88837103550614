import React from "react";
import { styled } from '@mui/material';
import Seo from '../../lib/seo/index'
import LayOut from "../../layout";

const Title = styled('H1')({
    color: '#c2c2d1',
})

const WraperFrame = styled('div')({
    background: 'white',
    padding: 40,
    borderRadius: 40,
    "@media(max-width: 767px)": {
        padding: 5,
    }
})

const WraperText = styled('div')({
    color: '#c2c2d1',
    marginBottom: 40,
})


export default function Plot() {
    
    return(
        <LayOut>
            <Seo props={{title: 'Plot график стран World Values Survey', description: 'World Values Survey (Всемирный обзор ценностей) - это международный проект, созданный для исследования социальных и культурных изменений, происходящих в разных странах мира. Основная цель проекта - изучение ценностей, убеждений и поведения людей в различных культурных, политических и религиозных контекстах.', url: '/ru/plot/', lang: 'ru'}}/>
            <Title>Plot график стран World Values Survey</Title>
            <WraperText>
                    World Values Survey (Всемирный обзор ценностей) — это международный проект, созданный для исследования социальных и культурных изменений, происходящих в разных странах мира. Основная цель проекта - изучение ценностей, убеждений и поведения людей в различных культурных, политических и религиозных контекстах.
                <br/><br/>       
                    World Values Survey проводится регулярно с 1981 года, и на сегодняшний день в нем участвует более 100 стран мира. В рамках проекта проводятся опросы, на которых люди отвечают на вопросы о своих ценностях, убеждениях, поведении и отношении к различным социальным явлениям и проблемам.
                <br/><br/>
                    На этой странице можно построить Plot средних ответов на вопросы из <a href="/ru">Cultura Compass</a>. Внимание - ответы на вопросы пронамированны слева направо и начинаються с 1. И лучше параллельно смотреть на сам вопрос в тесте, перед тем как изучать его на графике. Вот <a href="https://worldvaluessurvey.org/WVSOnline.jsp">оригинальная страница </a>от организации на английском. 
                <br/><br/>
            </WraperText>
            {
                document.body.clientWidth < 600 ? 
                    <WraperText>
                        Рекомендуем перевернуть телефон и обновить страницу или смотреть на большом экране
                    </WraperText>
                :
                    <div></div>
            }

            <WraperFrame>
                <iframe 
                    title='plot WVS'
                    width={document.body.clientWidth > 600 ? 730 : document.body.clientWidth-50}
                    height={document.body.clientWidth > 600 ? 1400 : 1700}
                    frameBorder="0"
                    scrolling="no"
                    src="https://dash.evilunion.com/wvs-plot">
                </iframe>
            </WraperFrame>
        </LayOut>
    )
}