const pt =     {
    "lang": "pt",
    "buttons": {
        "next": "Próximo",
        "back": "Voltar",
        "send": "Obter Resposta"
    },
    "AlertSnack": "Se você girar seu telefone horizontalmente, será mais conveniente prosseguir. Existem várias opções de resposta à direita. Alguns navegadores podem não suportar a rotação do telefone.",
    error: {
        title: 'Algo deu errado:',
        text: `Vamos corrigir isso em breve, se for um erro de servidor <br/>
        Ou talvez você tenha perdido a conexão com a internet? 
        E pode haver uma atualização chegando`
    },
    "seo": {
        "title": "Descubra em qual país você se parece como residente.",
        "description": "Treinamos uma rede neural com dados de um estudo sociológico que examina os valores de vida. Foi conduzido com 140.000 pessoas de 90 países."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "Descubra em qual país você se parece como residente."
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>Treinamos uma rede neural com dados de um estudo sociológico <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>internacional</a> que examina os valores de vida. Foi conduzido com 140.000 pessoas de 90 países. Selecionamos algumas perguntas desse estudo e criamos este aplicativo. Após concluir o teste, você verá uma lista de países onde as pessoas responderam de forma semelhante a você e uma visão geral de nossa sociedade. </div>"
        }
        ],
    "data": [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "Abaixo está uma lista de qualidades que os pais podem transmitir aos seus filhos. Quais, se houver, você considera particularmente importantes? Por favor, selecione até cinco opções.",
            "question": [
                {"text": "Boas maneiras", "id": "A027"},
                {"text": "Independência", "id": "A029"},
                {"text": "Diligência", "id": "A030"},
                {"text": "Senso de responsabilidade", "id": "A032"},
                {"text": "Imaginação", "id": "A034"},
                {"text": "Tolerância e respeito pelos outros", "id": "A035"},
                {"text": "Economia, poupar dinheiro e coisas", "id": "A038"},
                {"text": "Determinação, perseverança", "id": "A039"},
                {"text": "Fé religiosa", "id": "A040"},
                {"text": "Não ser egoísta (altruísmo)", "id": "A041"},
                {"text": "Obediência", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "Importante"}]
        },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "Abaixo estão diferentes grupos de pessoas. <br/> Escolha aqueles que você não gostaria de ter como vizinhos.",
            "question": [
                {"text": "Pessoas de outra raça", "id": "A124_02"},
                {"text": "Bebedores pesados", "id": "A124_03"},
                {"text": "Imigrantes/trabalhadores estrangeiros", "id": "A124_06"},
                {"text": "Toxicômanos", "id": "A124_08"},
                {"text": "Homossexuais", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "Selecionar"}]
        },
        {
            "q": "3",
            "type": "multi",
            "globalQuestion": "Você concorda com as seguintes afirmações?",
            "question": [
              {
                "text": "Casais homossexuais podem ser tão bons pais quanto qualquer outro casal",
                "id": "D081"
              },
              {
                "text": "Ter filhos é uma responsabilidade para com a sociedade",
                "id": "D026_03"
              },
              {
                "text": "É dever de uma criança cuidar de pais doentes",
                "id": "D026_05"
              },
              {
                "text": "Pessoas que não trabalham tornam-se preguiçosas",
                "id": "C038"
              },
              {
                "text": "O trabalho é um dever para com a sociedade",
                "id": "C039"
              },
              {
                "text": "O trabalho deve vir em primeiro lugar, mesmo que signifique menos tempo livre",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "Totalmente de acordo" },
              { "id": 2, "text": "De acordo" },
              { "id": 3, "text": "Neutro" },
              { "id": 4, "text": "Discordo" },
              { "id": 5, "text": "Totalmente em desacordo" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "Quão importante são os seguintes valores para você?",
            "question": [
              { "text": "Família", "id": "A001" },
              { "text": "Amigos", "id": "A002" },
              { "text": "Tempo livre", "id": "A003" },
              { "text": "Política", "id": "A004" },
              { "text": "Trabalho", "id": "A005" },
              { "text": "Religião", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "Muito importante" },
              { "id": 2, "text": "Bastante importante" },
              { "id": 3, "text": "Não muito importante" },
              { "id": 4, "text": "Nada importante" }
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "Como você reagiria às seguintes afirmações? <br/> Concorda ou discorda delas?",
            "question": [
              {
                "text": "Os homens devem ter mais direitos no trabalho do que as mulheres",
                "id": "C001"
              },
              {
                "text": "Os empregadores devem preferir cidadãos do país em vez de imigrantes",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "Concordo"},
              {"id": 2, "text": "Discordo"},
              {"id": 3, "text": "Nenhum dos acima"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "Você concorda com as seguintes afirmações?",
            "question": [
              {
                "text": "Um dos principais objetivos na vida é fazer com que meus pais se orgulhem de mim",
                "id": "D054"
              },
              {
                "text": "Os homens se tornam melhores líderes políticos do que as mulheres",
                "id": "D059"
              },
              {
                "text": "A universidade é mais importante para meninos do que para meninas",
                "id": "D060"
              },
              {
                "text": "As crianças em idade pré-escolar sofrem quando suas mães trabalham",
                "id": "D061"
              },
              {
                "text": "Os homens são melhores em administrar negócios do que as mulheres",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "Concordo plenamente"},
              {"id": 2, "text": "Concordo"},
              {"id": 3, "text": "Discordo"},
              {"id": 4, "text": "Discordo totalmente"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "Escolha quais ações, na sua opinião, podem sempre ser justificadas, nunca podem ser justificadas, ou algo entre os dois, usando esta escala. <br/> 1 – Nunca justificável, 10 – Sempre justificável",
            "question": [
              {
                "text": "Exigir benefícios sociais aos quais você não tem direito",
                "id": "F114A"
              },
              {
                "text": "Evitar pagar pelo transporte público",
                "id": "F115"
              },
              {
                "text": "Fraudar impostos se você tiver a chance",
                "id": "F116"
              },
              {
                "text": "Aceitar suborno ao cumprir deveres oficiais",
                "id": "F117"
              },
              {
                "text": "Homossexualidade",
                "id": "F118"
              },
              {
                "text": "Prostituição",
                "id": "F119"
              },
              {
                "text": "Aborto",
                "id": "F120"
              },
              {
                "text": "Divórcio",
                "id": "F121"
              },
              {
                "text": "Eutanásia",
                "id": "F122"
              },
              {
                "text": "Suicídio",
                "id": "F123"
              },
              {
                "text": "Sexo casual",
                "id": "F132"
              },
              {
                "text": "Violência política",
                "id": "E290"
              },
              {
                "text": "Pena de morte",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "Qual a importância de Deus em sua vida?",
            "question": [
              {
                "text": "Por favor, use esta escala para indicar: 10 significa muito importante, e 1 significa nada importante.",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "Abaixo estão diferentes grupos de pessoas. Quanto você confia em cada grupo?",
            "question": [
              {"text": "Sua família", "id": "D001_B"},
              {"text": "Seu bairro", "id": "G007_18_B"},
              {"text": "Pessoas que você conhece pessoalmente", "id": "G007_33_B"},
              {"text": "Pessoas que você conhece pela primeira vez", "id": "G007_34_B"},
              {"text": "Pessoas de outras religiões", "id": "G007_35_B"},
              {"text": "Pessoas de outras nacionalidades", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "Confio completamente"},
              {"id": 2, "text": "Confio um pouco"},
              {"id": 3, "text": "Não confio muito"},
              {"id": 4, "text": "Não confio de jeito nenhum"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "Abaixo estão vários tipos de sistemas políticos. O que você acha de cada um deles como forma de governança?",
            "question": [
               {
                  "text": "Presença de um líder forte que não precisa lidar com o parlamento e as eleições",
                  "id": "E114"
               },
               {
                  "text": "Presença de especialistas, não do governo, tomando decisões de acordo com o que consideram melhor para o país",
                  "id": "E115"
               },
               {
                  "text": "Presença de um sistema político democrático",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "Muito bom"},
               {"id": 2, "text": "Bastante bom"},
               {"id": 3, "text": "Bastante ruim"},
               {"id": 4, "text": "Muito ruim"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "Para você, quão importante é viver em um país com governança democrática?",
            "question": [
               {
                  "text": "Nesta escala, onde 1 significa 'nada importante' e 10 significa 'absolutamente importante', qual posição você escolheria?",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "Abaixo estão várias ações tomadas pelo governo. Na sua opinião, o governo tem o direito de fazer isso?",
            "question": [
              {
                "text": "Manter as pessoas sob vigilância por vídeo em locais públicos",
                "id": "H009"
              },
              {
                "text": "Monitorar todos os e-mails eletrônicos e qualquer outra informação trocada na Internet",
                "id": "H010"
              },
              {
                "text": "Coletar informações sobre pessoas que vivem no país sem o conhecimento delas",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "Definitivamente deve ter o direito" },
              { "id": 2, "text": "Provavelmente deve ter o direito" },
              { "id": 3, "text": "Provavelmente não deve ter o direito" },
              { "id": 4, "text": "Definitivamente não deve ter o direito" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "Abaixo estão alguns objetivos que as pessoas escolhem para seu país nos próximos dez anos. Na sua opinião, qual objetivo é o mais importante?",
            "question": [
              { "text": "Qual objetivo, na sua opinião, é o mais importante?", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "Alto nível de crescimento econômico" },
              { "id": 2, "text": "Exército forte" },
              { "id": 3, "text": "Bons condições de trabalho e comunidade" },
              { "id": 4, "text": "Embelezar nossas cidades e vilas" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "Abaixo estão exemplos de organizações voluntárias. Selecione as organizações a que você pertence.",
            "question": [
              { "text": "Organizações religiosas", "id": "A065" },
              {
                "text": "Organizações dedicadas à educação, arte, música ou atividades culturais",
                "id": "A066"
              },
              { "text": "Sindicatos", "id": "A067" },
              {
                "text": "Organizações dedicadas à preservação do meio ambiente, ecologia e direitos dos animais",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "Não pertenço a nenhuma organização" },
              { "id": 1, "text": "Pertenço a organizações" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "Confiança nas pessoas",
            "question": [{"text": "Com qual afirmação você concorda?", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "A maioria das pessoas pode ser confiável"},
              {"id": 2, "text": "É preciso ter cuidado"},
              {"id": -1, "text": "Não sei"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "Aqui estão duas afirmações que as pessoas às vezes fazem sobre o meio ambiente e o crescimento econômico.",
            "question": [{"text": "Qual delas você acha mais importante?", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "Proteger o meio ambiente"},
              {"id": 2, "text": "Crescimento econômico e criação de empregos"},
              {"id": -1, "text": "Não sei"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "Concorrência - boa ou ruim?",
            "question": [{"text": "Avalie esta afirmação, onde 1 - Boa, 10 - Ruim", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "Boa"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "Ruim"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "Idade",
            "question": [{"text": "Qual é a sua idade?", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15-24 anos"},
              {"id": 2, "text": "25-34 anos"},
              {"id": 3, "text": "35-44 anos"},
              {"id": 4, "text": "45-54 anos"},
              {"id": 5, "text": "55-64 anos"},
              {"id": 6, "text": "65 anos ou mais"}
            ]
          }
    ]
}

export default pt;