const ar =     {
    "lang": "ar",
    "buttons": {
        "next": "التالي",
        "back": "العودة",
        "send": "الحصول على الإجابة"
    },
    "AlertSnack": "إذا قمت بتدوير هاتفك أفقيًا ، سيكون من الأفضل الاستمرار. هناك عدة خيارات إجابة على اليمين. قد لا تدعم بعض المتصفحات تدوير الهاتف.",
    error: {
        title: 'حدث خطأ ما:',
        text: `سنقوم بإصلاح ذلك قريبًا، إذا كان خطأ في الخادم <br/>
        أو ربما فقدت اتصال الإنترنت؟ 
        وقد يكون هناك تحديث قادم`
    },
    "seo": {
        "title": "اكتشف الدولة التي تشبهها كساكن.",
        "description": "لقد قمنا بتدريب شبكة عصبية باستخدام بيانات من دراسة سوسيولوجية تفحص القيم الحياتية. تم إجراء الدراسة مع 140،000 شخص من 90 دولة."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "اكتشف الدولة التي تشبهها كساكن."
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>لقد قمنا بتدريب شبكة عصبية باستخدام بيانات من دراسة سوسيولوجية <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>دولية</a> تفحص القيم الحياتية. تم إجراء الدراسة مع 140،000 شخص من 90 دولة. لقد أخذنا بعض الأسئلة من هناك وأنشأنا هذا التطبيق. بعد اجتياز الاختبار ، سترى قائمة بالدول التي أجاب فيها الناس بشكل مماثل لك ، بالإضافة إلى نظرة عامة على مجتمعنا. <br/><br/></div>"
        }
        ],
    "data": [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "أدناه قائمة بالصفات التي يمكن للآباء غرسها في أطفالهم. ما الصفات التي تعتبرها مهمة بشكل خاص؟ يرجى تحديد ما يصل إلى خمسة خيارات.",
            "question": [
                {"text": "حسن الأدب", "id": "A027"},
                {"text": "الاستقلالية", "id": "A029"},
                {"text": "الاجتهاد", "id": "A030"},
                {"text": "المسؤولية", "id": "A032"},
                {"text": "الخيال", "id": "A034"},
                {"text": "التسامح واحترام الآخرين", "id": "A035"},
                {"text": "التوفير والاقتصاد في المال والأشياء", "id": "A038"},
                {"text": "العزم والاستمرارية", "id": "A039"},
                {"text": "الايمان الديني", "id": "A040"},
                {"text": "عدم الانانية (العطاء)", "id": "A041"},
                {"text": "الطاعة", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "مهم"}]
        },
        {
            "q": "2",
            "type": "one",
            "globalQuestion": "أدناه مجموعات مختلفة من الناس. <br/> اختر أولئك الذين لا ترغب في أن تكون جيرانًا لديك.",
            "question": [
                {"text": "أشخاص من جنس آخر", "id": "A124_02"},
                {"text": "مدمنون على الكحول", "id": "A124_03"},
                {"text": "المهاجرون / العمال الأجانب", "id": "A124_06"},
                {"text": "المدمنون على المخدرات", "id": "A124_08"},
                {"text": "المثليين", "id": "A124_09"}
            ],
            "answers": [{"id": 1, "text": "اختيار"}]
        },
        {
            "q": "3",
            "type": "multi",
            "globalQuestion": "هل تتفق مع البيانات التالية؟",
            "question": [
              {
                "text": "قد تكون الأزواج المثليين مثل الأزواج الآخرين أولياء أمور جيدين",
                "id": "D081"
              },
              {
                "text": "إنّ إنجاب الأطفال هو واجب تجاه المجتمع",
                "id": "D026_03"
              },
              {
                "text": "من واجب الطفل رعاية الوالدين المرضى",
                "id": "D026_05"
              },
              {
                "text": "الأشخاص الذين لا يعملون يصبحون كسالى",
                "id": "C038"
              },
              {
                "text": "العمل واجب تجاه المجتمع",
                "id": "C039"
              },
              {
                "text": "يجب أن يكون العمل في المقام الأول، حتى إن كان ذلك يعني أقل وقت فراغ",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "أوافق تماماً" },
              { "id": 2, "text": "أوافق" },
              { "id": 3, "text": "محايد" },
              { "id": 4, "text": "لا أوافق" },
              { "id": 5, "text": "لا أوافق على الإطلاق" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "ما مدى أهمية القيم التالية بالنسبة لك؟",
            "question": [
              { "text": "الأسرة", "id": "A001" },
              { "text": "الأصدقاء", "id": "A002" },
              { "text": "الوقت الحر", "id": "A003" },
              { "text": "السياسة", "id": "A004" },
              { "text": "العمل", "id": "A005" },
              { "text": "الدين", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "هامة جداً" },
              { "id": 2, "text": "مهمة إلى حد ما" },
              { "id": 3, "text": "غير مهمة كثيراً" },
              { "id": 4, "text": "غير مهمة على الإطلاق" }
            ]
          },
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "كيف ستتفاعل مع البيانات التالية؟ <br/> هل توافق أو تختلف معها؟",
            "question": [
              {
                "text": "يجب أن يحصل الرجال على مزيد من الحقوق في العمل مقارنة بالنساء",
                "id": "C001"
              },
              {
                "text": "يجب أن يفضل أصحاب العمل المواطنين من البلاد بدلاً من المهاجرين",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "أوافق"},
              {"id": 2, "text": "لا أوافق"},
              {"id": 3, "text": "لا يوجد أي من الخيارات السابقة"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "هل توافق على البيانات التالية؟",
            "question": [
              {
                "text": "واحد من الأهداف الرئيسية في الحياة هو أن أجعل والدي فخورًا بي",
                "id": "D054"
              },
              {
                "text": "الرجال يصبحون قادة سياسيين أفضل من النساء",
                "id": "D059"
              },
              {
                "text": "الجامعة أكثر أهمية للأولاد من البنات",
                "id": "D060"
              },
              {
                "text": "الأطفال الصغار يعانون عندما تعمل أمهاتهم",
                "id": "D061"
              },
              {
                "text": "الرجال أفضل في إدارة الأعمال من النساء",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "أوافق تمامًا"},
              {"id": 2, "text": "أوافق"},
              {"id": 3, "text": "لا أوافق"},
              {"id": 4, "text": "لا أوافق على الإطلاق"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "اختر الأفعال التي يمكن تبريرها دائمًا من وجهة نظرك، لا يمكن تبريرها أبدًا، أو شيء ما بين الاثنين، باستخدام هذا المقياس. <br/> 1 - لا يمكن تبريره أبدًا، 10 - يمكن تبريره دائمًا",
            "question": [
              {
                "text": "المطالبة بالمزايا الاجتماعية التي ليس لك الحق فيها",
                "id": "F114A"
              },
              {
                "text": "تجنب دفع تكاليف وسائل النقل العام",
                "id": "F115"
              },
              {
                "text": "الغش في الضرائب إذا كان لديك الفرصة لذلك",
                "id": "F116"
              },
              {
                "text": "قبول رشوة أثناء أداء الواجبات الرسمية",
                "id": "F117"
              },
              {
                "text": "المثلية الجنسية",
                "id": "F118"
              },
              {
                "text": "الدعارة",
                "id": "F119"
              },
              {
                "text": "الإجهاض",
                "id": "F120"
              },
              {
                "text": "الطلاق",
                "id": "F121"
              },
              {
                "text": "الإيثانازيا",
                "id": "F122"
              },
              {
                "text": "الانتحار",
                "id": "F123"
              },
              {
                "text": "العلاقات الجنسية العابرة",
                "id": "F132"
              },
              {
                "text": "العنف السياسي",
                "id": "E290"
              },
              {
                "text": "عقوبة الإعدام",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "كم هو مهم الله في حياتك؟",
            "question": [
              {
                "text": "يرجى استخدام هذا المقياس للإشارة: يعني 10 مهم جداً ، ويعني 1 ليس مهمًا على الإطلاق.",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "فيما يلي مجموعات مختلفة من الناس. كم تثق بكل مجموعة؟",
            "question": [
              {"text": "عائلتك", "id": "D001_B"},
              {"text": "حيك", "id": "G007_18_B"},
              {"text": "الأشخاص الذين تعرفهم شخصيًا", "id": "G007_33_B"},
              {"text": "الأشخاص الذين تلتقي بهم للمرة الأولى", "id": "G007_34_B"},
              {"text": "أشخاص من ديانات أخرى", "id": "G007_35_B"},
              {"text": "أشخاص من جنسيات أخرى", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "أثق بشكل كامل"},
              {"id": 2, "text": "أثق قليلاً"},
              {"id": 3, "text": "لا أثق كثيراً"},
              {"id": 4, "text": "لا أثق على الإطلاق"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "فيما يلي أنواع مختلفة من الأنظمة السياسية مذكورة. ما هو رأيك في كل منها كوسيلة للحكم؟",
            "question": [
               {
                  "text": "وجود قائد قوي لا يحتاج إلى التعامل مع البرلمان والانتخابات",
                  "id": "E114"
               },
               {
                  "text": "وجود خبراء، وليس الحكومة، يتخذون القرارات وفقًا لما يرونه الأفضل للبلاد",
                  "id": "E115"
               },
               {
                  "text": "وجود نظام سياسي ديمقراطي",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "جيد جدًا"},
               {"id": 2, "text": "جيد إلى حد ما"},
               {"id": 3, "text": "سيء إلى حد ما"},
               {"id": 4, "text": "سيء جدًا"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "مدى أهمية العيش في بلد يمتلك نظام حكم ديمقراطي بالنسبة لك؟",
            "question": [
               {
                  "text": "على هذا المقياس، حيث يعني 1 'غير مهم على الإطلاق' و10 يعني 'مهم جدًا'، ما الموقف الذي ستختاره؟",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "أدناه عدة إجراءات يتخذها الحكومة. في رأيك، هل لدى الحكومة الحق في القيام بذلك؟",
            "question": [
              {
                "text": "مراقبة الناس تحت المراقبة بالفيديو في الأماكن العامة",
                "id": "H009"
              },
              {
                "text": "مراقبة كل رسائل البريد الإلكتروني وأي معلومات أخرى يتم تبادلها على الإنترنت",
                "id": "H010"
              },
              {
                "text": "جمع معلومات عن الأشخاص الذين يعيشون في البلاد بدون علمهم",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "يجب أن يكون لديه الحق بالتأكيد" },
              { "id": 2, "text": "قد يكون لديه الحق ربما" },
              { "id": 3, "text": "قد يكون ليس لديه الحق ربما" },
              { "id": 4, "text": "يجب أن لا يكون لديه الحق بالتأكيد" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "أدناه بعض الأهداف التي يختارها الناس لبلدهم في العشر سنوات القادمة. في رأيك، أي هدف هو الأهم؟",
            "question": [
              { "text": "أي هدف، في رأيك، هو الأهم؟", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "مستوى عالٍ من النمو الاقتصادي" },
              { "id": 2, "text": "جيش قوي" },
              { "id": 3, "text": "ظروف جيدة للعمل والمجتمع" },
              { "id": 4, "text": "تجميل مدننا وقرانا" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "أدناه بعض أمثلة المنظمات التطوعية. اختر المنظمات التي تنتمي إليها.",
            "question": [
              { "text": "المنظمات الدينية", "id": "A065" },
              {
                "text": "المنظمات المكرسة للتعليم والفنون والموسيقى أو الأنشطة الثقافية",
                "id": "A066"
              },
              { "text": "نقابات العمل", "id": "A067" },
              {
                "text": "المنظمات المكرسة لصون البيئة وعلم البيئة وحقوق الحيوانات",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "أنا لست عضوًا في أي منظمة" },
              { "id": 1, "text": "أنا عضو في بعض المنظمات" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "الثقة بالناس",
            "question": [{"text": "مع أي عبارة تتفق؟", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "يمكن الوثوق بمعظم الناس"},
              {"id": 2, "text": "يجب أن تكون حذراً"},
              {"id": -1, "text": "لا أعلم"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "إليك عبارتان يقوم الناس بقولهما أحيانًا حول البيئة والنمو الاقتصادي.",
            "question": [{"text": "أي منهما تعتبره أكثر أهمية؟", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "حماية البيئة"},
              {"id": 2, "text": "النمو الاقتصادي وخلق فرص عمل"},
              {"id": -1, "text": "لا أعلم"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "المنافسة - جيدة أم سيئة؟",
            "question": [{"text": "قيم هذا البيان، حيث 1 - جيد، 10 - سيئ", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "جيدة"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "سيئة"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "العمر",
            "question": [{"text": "كم عمرك؟", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15-24 سنة"},
              {"id": 2, "text": "25-34 سنة"},
              {"id": 3, "text": "35-44 سنة"},
              {"id": 4, "text": "45-54 سنة"},
              {"id": 5, "text": "55-64 سنة"},
              {"id": 6, "text": "أكثر من 65 سنة"}
            ]
          }
    ]
}

export default ar;