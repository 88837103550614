import ru from './lang/final/ru'
import en from './lang/final/en'
import es from './lang/final/es'
import de from './lang/final/de'
import fr from './lang/final/fr'
import hi from './lang/final/hi'
import ja from './lang/final/ja'
import ar from './lang/final/ar'
import pt from './lang/final/pt'
import zh from './lang/final/zh'

const CMS = {
    ru: ru,
    en: en,
    zh: zh,
    pt: pt,
    ja: ja,
    ar: ar,
    hi: hi,
    fr: fr,
    de: de,
    es: es
}


export default CMS;