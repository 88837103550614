import ru from './ru';
import ar from './ar';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import hi from './hi';
import ja from './ja';
import pt from './pt';
import zh from './zh';


const CMS = {
    ru: ru,
    ar: ar,
    de: de,
    en: en,
    es: es,
    fr: fr,
    hi: hi,
    ja: ja,
    pt:pt,
    zh: zh
}

export default CMS;