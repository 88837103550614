import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import createReducers from './reducers';
import logger from 'redux-logger'
export let history;
export default (urls = ['/']) => {
  // Create a history depending on the environment
  history = createBrowserHistory({
          initialEntries: window.location.pathname === "/" ? ["/", ] : ["/", window.location.pathname],
          initialIndex: 1
  });
  const enhancers = [];
  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const middleware = [thunk, routerMiddleware(history)];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger)
  }
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );
  // Do we have preloaded state available? Great, save it.
  const initialState = {};
  //usePreviousLocation(history);
  // Create the store
  const store = createStore(
    createReducers(history),
    initialState,
    composedEnhancers
  );
  return {
    store,
    history
  };
};
