import React from 'react';
import {styled, Typography, Slide} from '@mui/material';
import Form from './form'
import Questions from './q'
import Progress from '../feedback/progress'
import Error from '../feedback/error'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import * as actions from '../../actions/vws';
import Final from './final'
import AllCountry from './all_country_answers.json'
import Seo from '../lib/seo/index'
import AlertSnack from '../feedback/allert'
import { useHistory } from "react-router-dom";
import LayOut from '../../components/layout/index'


const Wraper = styled('div')({
    // maxWidth: 800
})

const Title = styled('div')({
    maxWidth: 800,
    marginBottom: 100,
})

const SubTitle = styled('div')({
    maxWidth: 600,
    marginTop: 10,
    lineHeight: '140%',
    color: '#c2c2d1',
})

const TestWrapper = styled('div')({
    paddingTop: 20
})

const AnswerCountry = styled('div')({
    marginTop: 40,
    color: '#6e6e73',
})


// const test_data = {"A001": 4, "A002": 1, "A003": 2, "A004": 1, "A005": 2, "A006": 4, "A027": 0, "A029": 1, "A030": 1, "A032": 2, "A034": 0, "A035": 0, "A038": 1, "A039": 1, "A040": 0, "A041": 1, "A042": 0, "A065": 0, "A066": 0, "A067": 1, "A071": 0, "A165": 2, "B008": 1, "C001": 2, "C002": 2, "C038": 4, "C039": 3, "C041": 4, "D054": 4, "D059": 4, "D060": 4, "D061": 4, "D078": 4, "D081": 1, "E001": 3, "E039": 10, "E114": 3, "E115": 3, "E117": 2, "E235": 8, "E290": 6, "F063": 1, "F115": 3, "F116": 3, "F117": 1, "F118": 10, "F119": 1, "F120": 10, "F121": 10, "F122": 10, "F123": 10, "F132": 1, "H009": 1, "H010": 2, "H011": 2, "F114A": 10, "X003R": 1, "D001_B": 4, "A124_02": 0, "A124_03": 1, "A124_06": 0, "A124_08": 1, "A124_09": 0, "D026_03": 5, "D026_05": 5, "F144_02": 6, "G007_18_B": 3, "G007_33_B": 2, "G007_34_B": 3, "G007_35_B": 3, "G007_36_B": 3}
  

function WVS({postWVS, wait, data, error, match: {params: {lang}}}) {
    const [state, setState] = React.useState({});
    const [countryAnswer, setCountryAnswer] = React.useState(null);
    const [progress, setProgress] = React.useState(0);
    const [mobileStatus, setMobileStatus] = React.useState(false);

    const langList = ['ru', 'en', 'es', 'zh', 'ja', 'fr', 'de', 'hi', 'pt', 'ar']
    const history = useHistory()
    if ( !langList.includes(window.location.href.split('/')[3] )) {
        history.push('en/404')
    }

    const Q = Questions.filter(item => item.lang === lang)[0]

    const sendDataState = (answer_id, question_id) => {
        setState({
            ...state,
            [question_id]: parseInt(answer_id)
        })
    }

    const PrepareData = () => {
        let updateAnswer = {}
        Q.data.forEach(block => {
            if (block.type === 'ads') {
                return;
            }
            if (block.type === 'adsGoogle') {
                return;
            }
            if (block.type !== 'oness') {
                block.question.forEach(question => {
                    if (state[question.id] === undefined) {
                        updateAnswer[question.id] = 0
                    }
                })
            }
        });
        try {
            postWVS(
                {...state,...updateAnswer}, window.localStorage.getItem('urlParams'))
        } catch (e) {
            postWVS(
                {...state,...updateAnswer}, 'None')
        }
    }

    function handleScroll() {
        if (progress === 2) {
            setMobileStatus(true)
        }
      }   
    window.addEventListener('scroll', handleScroll);

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);    
        const params = {};
        for (const [key, value] of urlParams.entries()) {
          params[key] = value;
        }     
        localStorage.setItem('urlParams', JSON.stringify(params));
      }, []);


    document.documentElement.lang = lang;
    return (
        <LayOut>
        <Wraper>
            <Title>
                <Seo props={{title: Q.seo.title, description: Q.seo.description, lang: lang, url: '', langList: langList}} />
                
                    <Typography 
                        variant="h1" 
                        dir={lang !== 'ar' ? "ltl" : "rtl"}
                        style={{lineHeight: '100%'}}>
                            {Q.begin[0].html}
                    </Typography>

                <br/>
                <SubTitle 
                    variant="h5" 
                    style={{lineHeight: '140%'}} 
                    dangerouslySetInnerHTML={{__html: Q.begin[1].html}} 
                    dir={lang !== 'ar' ? "ltl" : "rtl"}
                />
            </Title>

            <section id='test'></section>
            {
                countryAnswer === null?
                    <div></div>
                        :
                    <AnswerCountry>Country average responses loaded: {countryAnswer}</AnswerCountry>
            }
            <TestWrapper>
                <section id='card'/>
                <Form 
                    item={Q.data[progress]}
                    progress={progress}
                    setProgress={setProgress}
                    sendDataState={sendDataState} 
                    state={state} 
                    answers={AllCountry[countryAnswer]}
                    buttons={Q.buttons}
                    lenQ={Q.data.length}
                    PrepareData={PrepareData}
                    lang={lang}
                />
                
                <div
                            id='get_result' 
                            variant='special'
                            dir={lang !== 'ar' ? "ltl" : "rtl"}
                            onClick={() => PrepareData()}
                        >
                            
                </div>


                {
                    wait === true ? <Progress/> : <div/>
                }

                <section id='answer'/>
                {
                    data['result'] !== false & data['result'] !==  undefined ?
           
                      <Final 
                          result={JSON.parse(data['result'])} 
                          setCountryAnswer={setCountryAnswer}
                          explain={data['explain']}
                          lang={lang}
                      />

                    :
                    <div/>
                }

                {
                    data['result'] !== false ? 
                    <div/>
                    :
                    <div style={{height: 
                        document.body.clientWidth < 600 
                    ? 
                        50 
                    : 
                        window.innerHeight < 500 ? 0 : 340}}>

                    </div>
                }
                
                {
                    error !== false ? <Error data={error} lang={lang} text={Q.error}/> : <div/>
                }

                {
                    document.body.clientWidth < 600 && mobileStatus ? 
                        <AlertSnack text={Q.AlertSnack} lang={lang}/>
                    :
                        <div/>
                }

            </TestWrapper>   
        </Wraper>
        </LayOut>
    )
}


const mapStateToProps = state => state.wvs;
const mapDispatchToProps = dispatch => bindActionCreators({
    ...actions,
    push,
}, dispatch);

export default connect(
    mapStateToProps, mapDispatchToProps,
)(WVS)