const de = {
    seo: {
        title: 'Wie funktioniert es?',
        description: 'Die Hauptvereinfachung, die wir vorgenommen haben, besteht darin, das Gradient Boosting als neuronales Netzwerk zu bezeichnen. Da in der Gesellschaft eine klare Vertrautheit mit neuronalen Netzwerken besteht, haben wir uns entschlossen, nicht pingelig zu sein und zu erklären, was das Boosting ist, da es niemanden interessiert.',
        url: '/catboost',
        lang: 'de'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Wie funktioniert es?</h1>"
        },
        {
            "type": "text-html",
            "html": `
            <i>Die Hauptvereinfachung, die wir vorgenommen haben, besteht darin, das Gradient Boosting als neuronales Netzwerk zu bezeichnen. Da in der Gesellschaft eine klare Vertrautheit mit neuronalen Netzwerken besteht, haben wir uns entschlossen, nicht pingelig zu sein und zu erklären, was das Boosting ist, da es niemanden interessiert.</i>
            <br/> <br/>
                Also haben wir den beliebten Catboost von Yandex verwendet. Weil er schnell arbeitet und leicht zu erlernen ist.
            <br/> <br/>
            
            <h2>Beginnen</h2>
            Das Gradient Boosting ist eine Methode des maschinellen Lernens, die es ermöglicht, ein Vorhersagemodell zu erstellen, das basierend auf einer Vielzahl von verschiedenen Merkmalen genaue Vorhersagen treffen kann.
            <br/> <br/>
            Es funktioniert folgendermaßen: Zuerst erstellen wir ein einfaches Modell, das einige Vorhersagen treffen kann. Dann schauen wir, wo dieses Modell Fehler macht, und fügen ein weiteres Modell hinzu, das die Fehler des ersten Modells korrigiert. Danach betrachten wir die Fehler des neuen Modells und erstellen noch ein weiteres Modell, das diese Fehler korrigiert.
            <br/> <br/>
            Wir setzen diesen Prozess fort, bis unser Modell ausreichend genau ist. Die Hauptidee des Gradient Boosting besteht darin, dass jedes neue Modell, das wir hinzufügen, die Fehler der vorherigen Modelle berücksichtigt und die Gesamtgenauigkeit des Modells verbessert. In unserem Modell haben wir 2000 solcher Schritte durchgeführt.
            <br/> <br/>
            Wenn alle Modelle erstellt sind, kombinieren wir sie, um das endgültige Modell zu erhalten, das genaue Vorhersagen anhand der von uns bereitgestellten Daten treffen kann.
            <h2>Beispiel Titanic</h2>
            Es gibt eine schöne Abbildung, die zeigt, ob eine Person auf der Titanic überleben wird.
            `
        },
        {
            "type": "img",
            "href": "/images/titanic.png",
            "alt": "Beispiel Titanic"
        },
        {
            "type": "text-html",
            "html": `Hier nehmen drei Bäume Daten über den Passagier in unterschiedlicher Reihenfolge auf und dann stimmt das Gesamtensemble darüber ab, ob die Person überleben wird oder nicht. <br/><br/>
            
            Geschlecht - Gender <br/>
            pclass - Ticketklasse. Es gab 3 Ebenen. <br/>
            Alter - Age <br/>
            sibsp - Gab es Ehepartner an Bord <br/>
            parch - Anzahl der Kinder an Bord <br/><br/>
            
            Nach dem Trainieren des Modells können Sie fragen, welche Variablen das Ergebnis beeinflussen.
            
            <h2>Was können Sie damit machen?</h2>
            
            Maschinelles Lernen ermöglicht es, viele verschiedene Aufgaben zu lösen. In unserem Unternehmen bieten wir einen Service zur Vorhersage von Verkäufen und Konversionen an. Wir können eine tiefe Ereignisverfolgung auf Ihrer Website durchführen. Wir werden alle Aktionen der Besucher protokollieren und diese Daten an den Gradient Boosting CatBoost senden, der Konversionen auf Ihrer Website vorhersagen wird und anzeigt, welche Ereignisse diese Konversion beeinflusst haben. Auf diese Weise können Sie Seiten und Ihr Angebot optimieren. In B2B-Verkaufsszenarien können Sie die Wahrscheinlichkeit eines Kaufs als Konversion in Werbesystemen behandeln.`
        },

    ]
}

export default de;
