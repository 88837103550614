import React from "react"
import { 
    FacebookShareButton, 
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
    VKShareButton,
    VKIcon,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share';
import { styled } from '@mui/material';


const WraperRoot = styled('div')({
    // marginBottom: 10,
    // width: 580,
    display: 'flex',
    "@media(max-width: 767px)": {
        // marginBottom: 10
    }
})

const WraperIcon = styled('div')({
    marginRight: 10
})

const Root = styled('div')({
    marginTop: 20,
})


export default function shrareButton({shareUrl, title, call, lang}) {

    return (
        <Root>
        <i style={{color: '#c2c2d1'}} dir={lang !== 'ar' ? "ltl" : "rtl"}>{call}</i>
        <WraperRoot id='share-button'>
            <WraperIcon>
                <FacebookShareButton
                    url={shareUrl}
                    quote={'Dummy text!'}
                    hashtag="#muo"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </WraperIcon>

            <WraperIcon id='share-button'>
                <TelegramShareButton
                    url={shareUrl}
                    title={title}
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
            </WraperIcon>

            <WraperIcon id='share-button'>
                <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </WraperIcon>

            <WraperIcon id='share-button'>
                <LinkedinShareButton url={shareUrl}>
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </WraperIcon>

            <WraperIcon id='share-button'>
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </WraperIcon>

            <WraperIcon id='share-button'>
                <VKShareButton
                    url={shareUrl}
                    image={'/images/country_table.png'}
                >
                    <VKIcon size={32} round />
                </VKShareButton>
            </WraperIcon>
        </WraperRoot>
        </Root>
    )
}


