import ru from './lang/questions/ru'
import en from './lang/questions/en'
import es from './lang/questions/es'
import zh from './lang/questions/zh'
import ja from './lang/questions/ja'
import fr from './lang/questions/fr'
import de from './lang/questions/de'
import hi from './lang/questions/hi'
import pt from './lang/questions/pt'
import ar from './lang/questions/ar'

const Q = [
    ru,
    en,
    es,
    zh,
    ja,
    fr,
    de,
    hi,
    pt,
    ar
]

export default Q;