const ru = {
    seo: {
        title: 'Технические подробности', 
        description: 'Главное упрощение которые мы сделали - это называние градиентного бустинга нейронной сеткой. Так как в обществе однозначно сложилось ознакомленность в сторону нейронных сетей, решили не быть занудами с пояснением что такое бустинг так как всем пофиг. ', 
        url: '/ml',
        lang: 'ru'
    },
    data: [
        {
            "type": "text-html",
            "html": "<h1>Технические подробности</h1>"
        },
        {
            "type": "text-html",
            "html": `
            Главное упрощение которые мы сделали — это называние градиентного бустинга нейронной сеткой. Так как в обществе однозначно сложилось ознакомленность в сторону термина нейронных сетей, решили не быть занудами с пояснением что такое бустинг так как всем пофиг. 
            <br/> <br/>
                Значит мы использовали любимый всеми CatBoost от Яндекса. Потому что он быстро работает и прост в обучении. 
            <br/> <br/>
                loss_function - MultiClass <br/>
                eval_metric - TotalF1 <br/>
                test_size - 40% <br/>
            <br/> <br/>
                Финальный Total F1 был равен 64 процентам на тестовой выборке. Обращаю внимание, что это мултикласс! Мы предсказываем не булеву переменную, a классы, которых было целых 80 штук. 10 стран из данных было решено удалить, так как там было меньше 1000 респондентов. Турция очень изменила свои вопросы, поэтому ee просто удалили из данных. 
            <br/> <br/>
                Можно было и дальше растить Total F1, но на это было уже жалко времени. В принципе если скормить все вопросы, то выходила точность 92% c первого раза! Возможно часть вопросов были спойлерами для модели, но смысла в наращивании дальше я не вижу. Так же делать слишком длинную анкету не хотелось. В любом случае человек попадает в свой кластер по жизненным ценностям. 
            <br/> <br/>
            <h2>Матрица ошибок</h2>
                Тут интересная штука выходит, можно посмотреть с какими странами модель ошибаеться чаще всего. Но тут же можно переформулировать вопрос и сказать какие странны похожи дргу на друга, раз модель путает их граждан. 
            <br/> <br/>
                Вот примеры некоторых стран. Каждая таблица это фильтрация таблицы по стране. И суммарная оценка модели по всем странам из тестовой выборки. Например в первой таблице это респонденты из России из тестовой выборки и ответы модели по их ответам. 
            <br/> <br/>
            `
        },
        {
            "type": "img",
            "href": "/images/error_country.png",
            "alt": "error country example"
        },
    ]
}

export default ru;



