const es =     {
    "lang": "es",
    "buttons": {
      "next": "Siguiente",
      "back": "Atrás",
      "send": "Enviar"
    },
    AlertSnack: 'Si giras tu teléfono horizontalmente, será más conveniente para continuar. Hay varias opciones de respuesta a la derecha. Algunos navegadores pueden no ser compatibles con la rotación del teléfono.',
    error: {
        title: 'Algo salió mal:',
        text: `Lo resolveremos pronto, si es un error del servidor <br/>
        ¿O tal vez perdiste la conexión a internet? 
        Y podría haber una actualización en camino`
    },
    "seo": {
        "title": "Descubre a qué país te pareces como residente.",
        "description": "Entrenamos una red neuronal con datos de un estudio sociológico que examina los valores de vida. Se realizó con 140,000 personas de 90 países."
        },
        "begin": [
        {
            "type": "text-html",
            "html": "Descubre a qué país te pareces como residente."
        },
        {
            "type": "text-html",
            "html": "<div style='lineHeight: 140%; font-size: 1.0625rem; color: #c2c2d1; font-family: Ubuntu-Light;'>Entrenamos una red neuronal con datos de un estudio sociológico <a href='https://worldvaluessurvey.org/wvs.jsp' target='_blank'>internacional</a> que examina los valores de vida. Se realizó con 140,000 personas de 90 países. Tomamos algunas preguntas de allí y creamos esta aplicación. Después de completar la prueba, verás una lista de países donde las personas respondieron de manera similar a ti y una visión general de nuestra sociedad. </div>"
        }
        ],
    data: [
        {
            "q": "1",
            "type": "one",
            "globalQuestion": "A continuación se muestra una lista de cualidades que los padres pueden inculcar en sus hijos. ¿Cuáles consideras especialmente importantes, si las hay? Por favor, selecciona hasta cinco opciones.",
            "question": [
              {"text": "Buenos modales", "id": "A027"},
              {"text": "Independencia", "id": "A029"},
              {"text": "Diligencia", "id": "A030"},
              {"text": "Sentido de responsabilidad", "id": "A032"},
              {"text": "Imaginación", "id": "A034"},
              {"text": "Tolerancia y respeto hacia los demás", "id": "A035"},
              {"text": "Frugalidad, ahorrar dinero y cosas", "id": "A038"},
              {"text": "Determinación, perseverancia", "id": "A039"},
              {"text": "Fe religiosa", "id": "A040"},
              {"text": "No ser egoísta (desinterés)", "id": "A041"},
              {"text": "Obediencia", "id": "A042"}
            ],
            "answers": [{"id": 1, "text": "Importante"}]
          },
          {
              "q": "2",
              "type": "one",
              "globalQuestion": "A continuación se presentan diferentes grupos de personas. <br/>Selecciona aquellos que no te gustaría tener como vecinos.",
              "question": [
                  {"text": "Personas de otra raza", "id": "A124_02"},
                  {"text": "Beben mucho alcohol", "id": "A124_03"},
                  {"text": "Inmigrantes/trabajadores extranjeros", "id": "A124_06"},
                  {"text": "Drogadictos", "id": "A124_08"},
                  {"text": "Homosexuales", "id": "A124_09"}
              ],
              "answers": [{"id": 1, "text": "Seleccionar"}]
          },
          {
            "q": "3",
            "type": "multi",
            "globalQuestion": "¿Estás de acuerdo con las siguientes afirmaciones?",
            "question": [
              {
                "text": "Las parejas homosexuales pueden ser tan buenos padres como cualquier otra pareja",
                "id": "D081"
              },
              {
                "text": "Tener hijos es una responsabilidad hacia la sociedad",
                "id": "D026_03"
              },
              {
                "text": "Es deber de un hijo cuidar de padres enfermos",
                "id": "D026_05"
              },
              {
                "text": "Las personas que no trabajan se vuelven perezosas",
                "id": "C038"
              },
              {
                "text": "El trabajo es un deber hacia la sociedad",
                "id": "C039"
              },
              {
                "text": "El trabajo debe ser lo primero, aunque signifique menos tiempo libre",
                "id": "C041"
              }
            ],
            "answers": [
              { "id": 1, "text": "Totalmente de acuerdo" },
              { "id": 2, "text": "De acuerdo" },
              { "id": 3, "text": "Neutral" },
              { "id": 4, "text": "En desacuerdo" },
              { "id": 5, "text": "Totalmente en desacuerdo" }
            ]
          },
          {
            "q": "4",
            "type": "multi",
            "globalQuestion": "¿Qué tan importantes son los siguientes valores para ti?",
            "question": [
              { "text": "Familia", "id": "A001" },
              { "text": "Amigos", "id": "A002" },
              { "text": "Tiempo libre", "id": "A003" },
              { "text": "Política", "id": "A004" },
              { "text": "Trabajo", "id": "A005" },
              { "text": "Religión", "id": "A006" }
            ],
            "answers": [
              { "id": 1, "text": "Muy importante" },
              { "id": 2, "text": "Bastante importante" },
              { "id": 3, "text": "No muy importante" },
              { "id": 4, "text": "Nada importante" }
            ]
          },     
          {
            "q": "5",
            "type": "multi",
            "globalQuestion": "¿Cómo responderías a las siguientes afirmaciones? <br/>¿Estás de acuerdo o en desacuerdo con ellas?",
            "question": [
              {
                "text": "Los hombres deberían tener más derechos en el trabajo que las mujeres",
                "id": "C001"
              },
              {
                "text": "Los empleadores deberían preferir a los ciudadanos del país en lugar de los inmigrantes",
                "id": "C002"
              }
            ],
            "answers": [
              {"id": 1, "text": "De acuerdo"},
              {"id": 2, "text": "En desacuerdo"},
              {"id": 3, "text": "Ninguna de las anteriores"}
            ]
          },
          {
            "q": "6",
            "type": "multi",
            "globalQuestion": "¿Estás de acuerdo con las siguientes afirmaciones?",
            "question": [
              {
                "text": "Uno de los principales objetivos en la vida es hacer que los padres se sientan orgullosos de mí",
                "id": "D054"
              },
              {
                "text": "Los hombres son mejores líderes políticos que las mujeres",
                "id": "D059"
              },
              {
                "text": "La universidad es más importante para los niños que para las niñas",
                "id": "D060"
              },
              {
                "text": "Los niños en edad preescolar sufren cuando sus madres trabajan",
                "id": "D061"
              },
              {
                "text": "Los hombres son mejores administradores de empresas que las mujeres",
                "id": "D078"
              }
            ],
            "answers": [
              {"id": 1, "text": "Totalmente de acuerdo"},
              {"id": 2, "text": "De acuerdo"},
              {"id": 3, "text": "En desacuerdo"},
              {"id": 4, "text": "Totalmente en desacuerdo"}
            ]
          },
          {
            "q": "7",
            "type": "multi",
            "globalQuestion": "Selecciona qué acciones, en tu opinión, siempre pueden estar justificadas, nunca pueden estar justificadas o algo intermedio usando esta escala. <br/> 1 – Nunca justificado, 10 – Siempre justificado",
            "question": [
              {
                "text": "Exigir beneficios estatales a los que no tienes derecho",
                "id": "F114A"
              },
              {
                "text": "Evadir el pago del transporte público",
                "id": "F115"
              },
              {
                "text": "Fraude fiscal si tienes la oportunidad",
                "id": "F116"
              },
              {
                "text": "Aceptar sobornos mientras desempeñas funciones oficiales",
                "id": "F117"
              },
              {
                "text": "Homosexualidad",
                "id": "F118"
              },
              {
                "text": "Prostitución",
                "id": "F119"
              },
              {
                "text": "Aborto",
                "id": "F120"
              },
              {
                "text": "Divorcio",
                "id": "F121"
              },
              {
                "text": "Eutanasia",
                "id": "F122"
              },
              {
                "text": "Suicidio",
                "id": "F123"
              },
              {
                "text": "Relaciones sexuales casuales",
                "id": "F132"
              },
              {
                "text": "Violencia política",
                "id": "E290"
              },
              {
                "text": "Pena de muerte",
                "id": "F144_02"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "8",
            "type": "multi",
            "globalQuestion": "¿Qué tan importante es Dios en tu vida?",
            "question": [
              {
                "text": "Utiliza esta escala para indicar: 10 significa muy importante, y 1 significa nada importante.",
                "id": "F063"
              }
            ],
            "answers": [
              {"id": 1, "text": "1"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "10"}
            ]
          },
          {
            "q": "9",
            "type": "multi",
            "globalQuestion": "A continuación se presentan diferentes grupos de personas. ¿Cuánto confías en cada grupo?",
            "question": [
              {"text": "Tu familia", "id": "D001_B"},
              {"text": "Tu barrio", "id": "G007_18_B"},
              {"text": "Personas que conoces personalmente", "id": "G007_33_B"},
              {"text": "Personas que conoces por primera vez", "id": "G007_34_B"},
              {"text": "Personas de otras religiones", "id": "G007_35_B"},
              {"text": "Personas de otras nacionalidades", "id": "G007_36_B"}
            ],
            "answers": [
              {"id": 1, "text": "Confío completamente"},
              {"id": 2, "text": "Confío un poco"},
              {"id": 3, "text": "No confío mucho"},
              {"id": 4, "text": "No confío en absoluto"}
            ]
          },
          {
            "q": "10",
            "type": "multi",
            "globalQuestion": "A continuación se describen varios tipos de sistemas políticos. ¿Qué opinas de cada uno de ellos como forma de gobierno?",
            "question": [
               {
                  "text": "Presencia de un líder fuerte que no tenga que lidiar con el parlamento y las elecciones",
                  "id": "E114"
               },
               {
                  "text": "Presencia de expertos, no del gobierno, tomando decisiones de acuerdo con lo que consideran mejor para el país",
                  "id": "E115"
               },
               {
                  "text": "Presencia de un sistema político democrático",
                  "id": "E117"
               }
            ],
            "answers": [
               {"id": 1, "text": "Muy bueno"},
               {"id": 2, "text": "Bastante bueno"},
               {"id": 3, "text": "Bastante malo"},
               {"id": 4, "text": "Muy malo"}
            ]
         },
         {
            "q": "11",
            "type": "multi",
            "globalQuestion": "¿Qué tan importante es para ti vivir en un país con un gobierno democrático?",
            "question": [
               {
                  "text": "En esta escala, donde 1 significa 'nada importante' y 10 significa 'absolutamente importante', ¿qué posición elegirías?",
                  "id": "E235"
               }
            ],
            "answers": [
               {"id": 1, "text": "1"},
               {"id": 2, "text": "2"},
               {"id": 3, "text": "3"},
               {"id": 4, "text": "4"},
               {"id": 5, "text": "5"},
               {"id": 6, "text": "6"},
               {"id": 7, "text": "7"},
               {"id": 8, "text": "8"},
               {"id": 9, "text": "9"},
               {"id": 10, "text": "10"}
            ]
         },
         {
            "q": "12",
            "type": "multi",
            "globalQuestion": "A continuación se presentan varias acciones tomadas por el gobierno. En su opinión, ¿tiene el gobierno derecho a hacer esto?",
            "question": [
              {
                "text": "Mantener a las personas bajo vigilancia por video en lugares públicos",
                "id": "H009"
              },
              {
                "text": "Monitorizar todos los correos electrónicos y cualquier otra información intercambiada en Internet",
                "id": "H010"
              },
              {
                "text": "Recopilar información sobre las personas que viven en el país sin su conocimiento",
                "id": "H011"
              }
            ],
            "answers": [
              { "id": 1, "text": "Definitivamente debería tener el derecho" },
              { "id": 2, "text": "Probablemente debería tener el derecho" },
              { "id": 3, "text": "Probablemente no debería tener el derecho" },
              { "id": 4, "text": "Definitivamente no debería tener el derecho" }
            ]
          },
          {
            "q": "14",
            "type": "multi",
            "globalQuestion": "A continuación se presentan algunas metas que la gente elige para su país en los próximos diez años. En su opinión, ¿cuál meta es la más importante?",
            "question": [
              { "text": "¿Cuál meta es la más importante, en su opinión?", "id": "E001" }
            ],
            "answers": [
              { "id": 1, "text": "Alto nivel de crecimiento económico" },
              { "id": 2, "text": "Fuerza militar sólida" },
              { "id": 3, "text": "Buenas condiciones laborales y comunitarias" },
              { "id": 4, "text": "Embellecer nuestras ciudades y pueblos" }
            ]
          },
          {
            "q": "15",
            "type": "multi",
            "globalQuestion": "A continuación se presentan ejemplos de organizaciones de voluntarios. Seleccione las organizaciones a las que pertenece.",
            "question": [
              { "text": "Organizaciones religiosas", "id": "A065" },
              {
                "text": "Organizaciones dedicadas a la educación, arte, música o actividades culturales",
                "id": "A066"
              },
              { "text": "Sindicatos laborales", "id": "A067" },
              {
                "text": "Organizaciones dedicadas a la preservación del medio ambiente, ecología y derechos de los animales",
                "id": "A071"
              }
            ],
            "answers": [
              { "id": 0, "text": "No pertenezco a ninguna" },
              { "id": 1, "text": "Pertenezco" }
            ]
          },
          {
            "q": "16",
            "type": "multi",
            "globalQuestion": "Confianza en las personas",
            "question": [{"text": "¿Con cuál afirmación estás de acuerdo?", "id": "A165"}],
            "answers": [
              {"id": 1, "text": "Se puede confiar en la mayoría de las personas"},
              {"id": 2, "text": "Se debe ser cauteloso"},
              {"id": -1, "text": "No lo sé"}
            ]
          },
          {
            "q": "17",
            "type": "multi",
            "globalQuestion": "Aquí hay dos afirmaciones que las personas hacen a veces sobre el medio ambiente y el crecimiento económico.",
            "question": [{"text": "¿Cuál crees que es más importante?", "id": "B008"}],
            "answers": [
              {"id": 1, "text": "Proteger el medio ambiente"},
              {"id": 2, "text": "Crecimiento económico y creación de empleo"},
              {"id": -1, "text": "No lo sé"}
            ]
          },
          {
            "q": "18",
            "type": "multi",
            "globalQuestion": "¿La competencia es buena o mala?",
            "question": [{"text": "Evalúa esta afirmación, donde 1 - Buena, 10 - Mala", "id": "E039"}],
            "answers": [
              {"id": 1, "text": "Buena"},
              {"id": 2, "text": "2"},
              {"id": 3, "text": "3"},
              {"id": 4, "text": "4"},
              {"id": 5, "text": "5"},
              {"id": 6, "text": "6"},
              {"id": 7, "text": "7"},
              {"id": 8, "text": "8"},
              {"id": 9, "text": "9"},
              {"id": 10, "text": "Mala"}
            ]
          },
          {
            "q": "19",
            "type": "multi",
            "globalQuestion": "Edad",
            "question": [{"text": "¿Cuál es tu edad?", "id": "X003R"}],
            "answers": [
              {"id": 1, "text": "15–24"},
              {"id": 2, "text": "25–34"},
              {"id": 3, "text": "35–44"},
              {"id": 4, "text": "45–54"},
              {"id": 5, "text": "55–64"},
              {"id": 6, "text": "65 y más"}
            ]
          }                             
    ]
}



export default es;