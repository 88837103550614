import React from "react";
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row-reverse',
        justifyContent: 'center'
    },
    text: {
        width: 600,
        color: '#c2c2d1',
        lineHeight: '150%',
        "@media(max-width: 767px)": {
            width: '100%'
        },
        '& h1,h2': {
            color: '#e3e3ed',
            lineHeight: '150%',
        }
    }
}))

export default function Text({props, lang}) {
    const classes = useStyles();

    return <div
                className={classes.root}>
                <div
                    dir={lang !== 'ar' ? "ltl" : "rtl"}
                    className={classes.text}
                    dangerouslySetInnerHTML={{__html: props.html}}/>
            </div>
}